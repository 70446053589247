import { ButtonLooks, FontIcons, Placements, StandardAlert, StandardButton, StandardPopout, StandardTooltip } from '@brandfolder/react';
import { Plural, Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import React, { Fragment, FunctionComponent, MouseEventHandler, ReactNode, useEffect, useState } from 'react';

import { fetchJson, useFetch } from '@api/ApiHelper';
import { InsightsEventActions, InsightsEventTypes } from '@api/insights/v1/events';
import { ShareManifestDownloadResponse } from '@api/v3/share_manifests';
import { ShareManfiestResourcePath, ShareManifestClient } from '@api/v4/share_manifests/ShareManifestTypes';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { PrimaryButton, TertiaryButton, TertiaryIconButton } from '@components/library/button';
import { ActionsDropdown, ActionsOpenDirections } from '@components/library/dropdown';
import { ActionDropdownItem } from '@components/library/dropdown_item';
import BulkShareModal from '@components/show_page/bulk_actions/BulkShareModal';
import { getFilteredArray } from '@helpers/arrays';
import { userCreatedShareLink } from '@helpers/share-links';
import { getStandardPopoutLabels } from '@translations';

import classes from './styles/heading-with-actions.module.scss';

export interface HeadingWithActionsProps {
  customLogoUrl?: string | null;
  editable: boolean;
  hasAssets: boolean;
  hasMixedViewOnlyAssets: boolean;
  hasOnlyViewOnlyAssets: boolean;
  hasViewOnlyAssets: boolean;
  isAttachmentGroup: boolean;
  isPreview: boolean;
  name: string;
  readable: boolean;
  resourcePath: string;
  resourcePaths: ShareManfiestResourcePath[] | null;
  selectedAssetKeys: string[];
  selectedViewOnlyAssetKeys: string[];
  shareManifest: ShareManifestClient;
  shareUrl: string;
  galleryView?: boolean;
  subject: string;
  totalAssetFriendly: string;
  totalAssets: number;
  viewOnly: boolean;
}

export const HeadingWithActions: FunctionComponent<HeadingWithActionsProps> = (props) => {
  const {
    customLogoUrl = null,
    editable,
    hasAssets,
    hasMixedViewOnlyAssets,
    hasOnlyViewOnlyAssets,
    hasViewOnlyAssets,
    isAttachmentGroup,
    isPreview,
    name,
    readable,
    resourcePath,
    resourcePaths,
    selectedAssetKeys,
    selectedViewOnlyAssetKeys,
    shareUrl,
    shareManifest,
    galleryView = false,
    subject,
    totalAssetFriendly,
    totalAssets,
    viewOnly
  } = props;

  const { digest, key, shareableType, userKey } = shareManifest;

  const [openEditModal, setOpenEditModal] = useState(new URLSearchParams(window.location.search).get('show_edit_share_mode') === 'true');
  const [showDownloadPopout, setShowDownloadPopout] = useState(false);

  const getAssetKeysToDownload = (): string[] => {
    if (hasViewOnlyAssets) {
      return getFilteredArray(Array.from(selectedAssetKeys), Array.from(selectedViewOnlyAssetKeys));
    } else {
      return Array.from(selectedAssetKeys);
    }
  };

  const postShareManifestDownload = useFetch<ShareManifestDownloadResponse>({
    body: {
      data: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        asset_keys: getAssetKeysToDownload()
      },
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v3/share_manifests/${digest}/assets/download`
  });

  const handleDownload = (): void => {
    postShareManifestDownload.fetch();
  };

  const postAssetDownloadEvent = (assetKey: string): Promise<null> => (
    fetchJson({
      body: {
        data: {
          attributes: {
            data: {
              action_name: InsightsEventActions.DOWNLOADED, // eslint-disable-line @typescript-eslint/naming-convention
              resource_key: assetKey, // eslint-disable-line @typescript-eslint/naming-convention
              resource_type: InsightsEventTypes.ASSET, // eslint-disable-line @typescript-eslint/naming-convention
              source: {
                resource_key: BFG.resource.key, // eslint-disable-line @typescript-eslint/naming-convention
                resource_type: BFG.resource.type, // eslint-disable-line @typescript-eslint/naming-convention
                share_manifest_key: key // eslint-disable-line @typescript-eslint/naming-convention
              }
            }
          }
        }
      },
      method: 'POST',
      url: `${BFG.INSIGHTS_API_URL}/api/v1/events`
    })
  );

  const downloadFile = (href: string): void => {
    const element = document.createElement('a');
    element.setAttribute('download', 'true');
    element.setAttribute('href', href);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleInsights = (): void => {
    // only create insights downloaded events for non-creators
    if (!userCreatedShareLink(BFG.currentUser?.user_id, userKey) && !BFG.currentUser?.su) {
      // get asset keys through elements on page because we only download the assets the user has
      // access to (for example: no unapproved assets are shown but would be listed in selectedAssetKeys)
      const allAssets = document.getElementsByClassName('asset-card');
      for (let i = 0; i < allAssets.length; i++) { // eslint-disable-line no-plusplus
        const asset = allAssets[i];
        postAssetDownloadEvent(asset.id);
      }
    }
  };

  useEffect(() => {
    if (postShareManifestDownload.error) {
      Notify.create({
        title: t`There was an error downloading your assets. Please try again.`,
        type: 'error'
      });
    }
  }, [postShareManifestDownload.error]);

  useEffect(() => {
    if (postShareManifestDownload.response) {
      handleInsights();
      downloadFile(postShareManifestDownload.response.data.redirect_url);
    }
  }, [postShareManifestDownload.response]);

  const handlePrint = (): void => {
    window.print();
  };

  const renderHeading = (): ReactNode => {
    const hasResourcePaths = resourcePaths && resourcePaths.length > 1;
    const listOfResources = resourcePaths && (
      <span data-cx-customization="share-manifest-resource-link-list">
        {resourcePaths.map((rp, index) => (
          <Fragment key={rp.name}>
            <a href={rp.path}>{rp.name}</a>{index !== resourcePaths.length - 1 ? ', ' : ''}
          </Fragment>
        ))}
      </span>
    );
    const shareLinkName = <span data-cx-customization="share-manifest-name">{name}</span>;

    let fullShareLinkName: ReactNode = shareLinkName;
    if (hasResourcePaths) {
      fullShareLinkName = (
        <Trans>
          {shareLinkName}{' '}
          <span data-cx-customization="share-manifest-title-connecting-word">from</span>{' '}
          {listOfResources}
        </Trans>
      );
    } else if (!hasResourcePaths && readable) {
      // renaming variable for context for translators
      const resource = subject;
      fullShareLinkName = (
        <Trans>
          {shareLinkName}{' '}
          <span data-cx-customization="share-manifest-title-connecting-word">from</span>{' '}
          <a data-cx-customization="share-manifest-resource-link" href={resourcePath}>{resource}</a>
        </Trans>
      );
    }

    const viewOnlyHeading = (
      <Trans>
        {fullShareLinkName}{' '}
        <span
          className={classes.viewOnly}
          data-cx-customization="share-manifest-view-only"
          id="share-manifest-view-only"
        >
          (View only)
        </span>
      </Trans>
    );

    return (
      <span data-cx-customization="share-manifest-full-title">
        {viewOnly ? viewOnlyHeading : fullShareLinkName}
      </span>
    );
  };

  const handleDownloadZip = (): void => {
    setShowDownloadPopout(false);

    if (BFG.downloadAlertEnabled) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        downloadCallback: handleDownload
      });
    } else {
      handleDownload();
    }
  };

  const showDownloadAll = (): boolean => {
    if (hasOnlyViewOnlyAssets) return false;

    return !isAttachmentGroup && !viewOnly && !BFG.disableCollectionAdminDownload;
  };

  const renderDownloadButtonWithTooltip = (
    buttonText: string,
    onClickDownload: MouseEventHandler<HTMLButtonElement>
  ): ReactNode => {
    const hasOver1000TotalAssets = totalAssets > 1000;

    const button = (
      <PrimaryButton
        disabled={postShareManifestDownload.loading || hasOver1000TotalAssets}
        icon="bff-download"
        id="share-manifest-download"
        onClick={onClickDownload}
      >
        {buttonText}
      </PrimaryButton>
    );

    return hasOver1000TotalAssets ? (
      <StandardTooltip
        id="share-manifest-download-tooltip"
        tooltip={t`Bulk download is limited to 1,000 assets or fewer.`}
      >
        {button}
      </StandardTooltip>
    ) : button;
  };

  const renderDownloadAll = (): ReactNode => {
    if (!showDownloadAll()) return null;

    const downloadAllZipText = hasMixedViewOnlyAssets ? t`Download` : t`Download All (${totalAssetFriendly} - ZIP)`;
    const downloadAllText = postShareManifestDownload.loading ? t`Downloading...` : downloadAllZipText;

    if (hasMixedViewOnlyAssets) {
      const id = 'download-all-popout';

      return (
        <StandardPopout
          className={classes.popout}
          content={<>
            <StandardAlert icon={FontIcons.Show}>
              <Trans>One or more assets are view only and will not be included in the download.</Trans>
            </StandardAlert>
            <StandardButton
              fullWidth
              look={ButtonLooks.Default}
              onClick={(): void => handleDownloadZip()}
              startIcon={FontIcons.Download}
            >
              <Trans>Download as ZIP</Trans>
            </StandardButton>
          </>}
          id={id}
          labels={getStandardPopoutLabels()}
          open={showDownloadPopout}
          placement={Placements.BottomEnd}
          setOpen={(): void => setShowDownloadPopout(false)}
        >
          {renderDownloadButtonWithTooltip(
            downloadAllText,
            () => setShowDownloadPopout(!showDownloadPopout)
          )}
        </StandardPopout>
      );
    }

    return galleryView ?
      (
        <TertiaryButton
          disabled={postShareManifestDownload.loading}
          icon="bff-download"
          id="share-manifest-download"
          onClick={(): void => handleDownloadZip()}
        >
          {downloadAllText}
        </TertiaryButton>
      ) : renderDownloadButtonWithTooltip(downloadAllText, handleDownloadZip);
  };

  return (
    <>
      <section className={classNames(classes.section, {
        [classes.galleryViewSection]: galleryView,
      })}>
        {!galleryView && <h1 className={classes.h1} id="share-manifest-heading">{renderHeading()}</h1>}
        {galleryView && customLogoUrl &&
          <div className={classes.customLogoContainer}>
            <div className={classes.customLogoImgContainer}>
              <FilestackPreviewImage
                alt="Header"
                className={classNames(classes.customLogoImage, { [classes.customLogoImagePreview]: isPreview })}
                data-private
                src={customLogoUrl}
              />
            </div>
          </div>
        }
        <div className={classes.actions} id="share-manifest-actions">
          {shareableType === 'AssetGroup' && (
            <p
              className={classes.assetCount}
              id="share-manifest-asset-count"
            >
              <Plural
                one="# Asset"
                other="# Assets"
                value={totalAssets}
              />
            </p>
          )}
          {!isPreview && (
            <>
              <ActionsDropdown
                anchorElement={(
                  <TertiaryButton
                    icon="bff-ellipsis"
                    id="share-manifest-more-options"
                  >
                    <Trans>More Options</Trans>
                  </TertiaryButton>
                )}
                className={classes.moreOptions}
                id="share-manifest-more-options"
                openDirection={ActionsOpenDirections.DownLeft}
                openOnClick
                openOnHover={false}
              >
                {editable && (
                  <ActionDropdownItem
                    icon="bff-edit"
                    name="edit"
                    onChoose={(): void => setOpenEditModal(true)}
                  >
                    <Trans>Edit Share Link</Trans>
                  </ActionDropdownItem>
                )}
                <ActionDropdownItem
                  icon="bff-link"
                  name="copy"
                >
                  <ClipboardCopy
                    showFeedbackAsTooltip
                    textToCopy={shareUrl}
                  >
                    <Trans>Copy Share Link URL</Trans>
                  </ClipboardCopy>
                </ActionDropdownItem>
              </ActionsDropdown>
              {hasAssets && !isAttachmentGroup && !viewOnly && BFG.hasFeature('contact_sheets') && (
                <TertiaryIconButton
                  aria-label={t`Print Contact Sheet`}
                  className={classes.print}
                  icon="bff-print"
                  id="share-manifest-print-contact-sheet"
                  onClick={(): void => handlePrint()}
                  title={t`Print Contact Sheet`}
                />
              )}
              {renderDownloadAll()}
            </>
          )}
        </div>
      </section>
      {editable && openEditModal && (
        <BulkShareModal
          closeModal={(): void => setOpenEditModal(false)}
          selectedAssetKeys={new Set(selectedAssetKeys)}
        />
      )}
    </>
  );
};
