/* eslint-disable @typescript-eslint/naming-convention */
import { FormikErrors, FormikTouched } from 'formik';
import { array, boolean, mixed, object, SchemaOf, string } from 'yup';

import { Fields, SelectorTypes } from '@components/bulk_management/automation/AutomationEnums';
import {
  Action,
  FormState,
  KeyLabelPair,
  WatermarkActionOptions
} from '@components/bulk_management/automation/AutomationTypes';
import { WatermarkPositionsEnum } from '@components/shared/watermarks/WatermarkPositionTypes';

export const ValidationMessages: Record<string, string> = {
  ActionRequired: bfTranslate('Action is required'),
  ActionableIdRequired: bfTranslate('ID is required'),
  ActionableKeysRequired: bfTranslate('Action key is required'),
  ActionableTypeInvalid: bfTranslate('Action is not valid'),
  ActionableTypeRequired: bfTranslate('Action is required'),
  CollectionRequired: bfTranslate('Collection is required'),
  CustomFieldKeyRequired: bfTranslate('Custom field key is required'),
  CustomFieldValueRequired: bfTranslate('Custom field value is required'),
  KeyRequired: bfTranslate('Key is required'),
  LabelRequired: bfTranslate('Label is required'),
  NameRequired: bfTranslate('Name is required'),
  SectionRequired: bfTranslate('Section is required'),
  TagRequired: bfTranslate('Tag is required'),
  TriggerInvalid: bfTranslate('Trigger is not valid'),
  TriggerRequired: bfTranslate('Trigger is required'),
  WatermarkFilenameRequired: bfTranslate('Filename is required'),
  WatermarkPositionInvalid: bfTranslate('Position is not valid'),
  WatermarkPositionRequired: bfTranslate('Position is required'),
  WatermarkRequired: bfTranslate('Watermark is required'),
  WatermarkUrlRequired: bfTranslate('URL is required')
};

export const keyLabelPairSchema: SchemaOf<KeyLabelPair> = object().shape({
  key: string()
    .required(ValidationMessages.KeyRequired),
  label: string()
    .required(ValidationMessages.LabelRequired)
});

export const customFieldKeyLabelPairSchema: SchemaOf<KeyLabelPair> = object().shape({
  key: string()
    .required(ValidationMessages.CustomFieldKeyRequired),
  // NOTE: label is intentionally using ValidationMessages.CustomFieldKeyRequired
  label: string()
    .required(ValidationMessages.CustomFieldKeyRequired)
});

export const actionOptionsSchema: SchemaOf<WatermarkActionOptions> = object().shape({
  include_autogenerated: boolean()
    .nullable(),
  watermark_filename: string(),
  watermark_gravity: mixed()
    .oneOf([
      WatermarkPositionsEnum.NorthEastGravity,
      WatermarkPositionsEnum.NorthWestGravity,
      WatermarkPositionsEnum.SouthEastGravity,
      WatermarkPositionsEnum.SouthWestGravity
    ], ValidationMessages.WatermarkPositionInvalid),
  watermark_url: string()
});

const customFieldValueSchema = object()
  .nullable();

export const actionSchema: SchemaOf<Action> = object().shape({
  actionableId: string()
    .required(ValidationMessages.ActionableIdRequired),
  actionableKeys: array()
    .of(keyLabelPairSchema)
    .nullable()
    .when(Fields.ActionableType, {
      is: SelectorTypes.Collection,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.CollectionRequired)
        .required(ValidationMessages.CollectionRequired)
    })
    .when(Fields.ActionableType, {
      is: SelectorTypes.CustomField,
      then: array()
        .of(customFieldKeyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.CustomFieldKeyRequired)
        .required(ValidationMessages.CustomFieldKeyRequired)
    })
    .when(Fields.ActionableType, {
      is: SelectorTypes.Label,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.LabelRequired)
        .required(ValidationMessages.LabelRequired)
    })
    .when(Fields.ActionableType, {
      is: SelectorTypes.Section,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.SectionRequired)
        .required(ValidationMessages.SectionRequired)
    })
    .when(Fields.ActionableType, {
      is: SelectorTypes.Tag,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.TagRequired)
        .required(ValidationMessages.TagRequired)
    }),
  actionableType: mixed()
    .oneOf([
      null,
      SelectorTypes.Collection,
      SelectorTypes.CustomField,
      SelectorTypes.Label,
      SelectorTypes.Section,
      SelectorTypes.Tag,
      SelectorTypes.Watermark
    ], ValidationMessages.ActionableTypeInvalid)
    .nullable()
    .required(ValidationMessages.ActionableTypeRequired),
  actionableOptions: actionOptionsSchema
    .nullable()
    .when(Fields.ActionableType, {
      is: SelectorTypes.Watermark,
      then: actionOptionsSchema
        .nullable()
        .required(ValidationMessages.WatermarkRequired)
    }),
  actionableValues: customFieldValueSchema
    .nullable()
    .when(Fields.ActionableType, {
      is: SelectorTypes.CustomField,
      then: customFieldValueSchema
        .nullable()
        .required(ValidationMessages.CustomFieldValueRequired)
        .test('atleast-one-value', ValidationMessages.CustomFieldValueRequired, (customFieldValues) => {
          if (customFieldValues) {
            const values = Object.values(customFieldValues as Record<string, string[]>);
            return values.length > 0 && values[0] && values[0].length > 0;
          }
          return false;
        })
    })
});

export const validationSchema: SchemaOf<FormState> = object().shape({
  actions: array()
    .of(actionSchema)
    .nullable()
    .required(ValidationMessages.ActionRequired),
  name: string()
    .required(ValidationMessages.NameRequired),
  triggerKeys: array()
    .of(keyLabelPairSchema)
    .nullable()
    .required(ValidationMessages.TriggerRequired)
    .when(Fields.TriggerType, {
      is: SelectorTypes.CustomField,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.CustomFieldKeyRequired)
        .required(ValidationMessages.CustomFieldKeyRequired)
    })
    .when(Fields.TriggerType, {
      is: SelectorTypes.Label,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.LabelRequired)
        .required(ValidationMessages.LabelRequired)
    })
    .when(Fields.TriggerType, {
      is: SelectorTypes.Section,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.SectionRequired)
        .required(ValidationMessages.SectionRequired)
    })
    .when(Fields.TriggerType, {
      is: SelectorTypes.Tag,
      then: array()
        .of(keyLabelPairSchema)
        .nullable()
        .min(1, ValidationMessages.TagRequired)
        .required(ValidationMessages.TagRequired)
    }),
  triggerType: mixed()
    .oneOf([
      null,
      SelectorTypes.CustomField,
      SelectorTypes.Label,
      SelectorTypes.Section,
      SelectorTypes.Tag
    ], ValidationMessages.TriggerInvalid)
    .nullable()
    .required(ValidationMessages.TriggerRequired),
  values: customFieldValueSchema
    .nullable()
    .when(Fields.TriggerType, {
      is: SelectorTypes.CustomField,
      then: customFieldValueSchema
        .nullable()
        .required(ValidationMessages.CustomFieldValueRequired)
        .test('atleast-one-value', ValidationMessages.CustomFieldValueRequired, (customFieldValues) => {
          if (customFieldValues) {
            const values = Object.values(customFieldValues as Record<string, string[]>);
            return values.length > 0 && values[0] && values[0].length > 0;
          }
          return false;
        })
    })
});

interface FormStateErrorOptions {
  errors: FormikErrors<FormState>;
  fieldType: SelectorTypes;
  actionableId?: string;
  actionIndex?: number;
  fieldIndex?: number;
  rowType?: boolean;
  isSectionDisabled?: boolean;
}

export const getFormStateError = (options: FormStateErrorOptions): string | undefined => {
  const { actionIndex, actionableId, errors, fieldIndex, fieldType, isSectionDisabled, rowType } = options;

  let error: string | undefined;

  if (Object.keys(errors).length === 0) return error;

  if (rowType) {
    if (actionableId) {
      if (!isSectionDisabled) {
        error = errors.actions && errors.actions[actionIndex] && errors.actions[actionIndex][Fields.ActionableType];
      } // else remain undefined
    } else {
      error = errors[Fields.TriggerType];
    }
  } else {
    let field = Fields.TriggerKeys;

    if (actionableId && !isSectionDisabled) {
      field = Fields.ActionableKeys;

      if (fieldType === SelectorTypes.Watermark) {
        field = Fields.ActionableOptions;
      }

      error = errors.actions && errors.actions[actionIndex] && errors.actions[actionIndex][field];

      if (Array.isArray(error) && fieldIndex !== undefined && fieldIndex !== null) {
        const errorArray = (((error as unknown) as KeyLabelPair[]));
        error = errorArray[fieldIndex] && errorArray[fieldIndex].key;
      }
    } else {
      error = (errors[field] as unknown) as string;
    }
  }

  return error;
};

interface FormStateErrorTouched {
  touched: FormikTouched<FormState>;
  fieldType: SelectorTypes;
  actionableId?: string;
  actionIndex?: number;
  fieldIndex?: number;
  rowType?: boolean;
  isSectionDisabled?: boolean;
}

export const getFormStateTouched = (options: FormStateErrorTouched): boolean => {
  const { actionIndex, actionableId, fieldIndex, fieldType, isSectionDisabled, rowType, touched } = options;

  let isTouched = false;

  if (Object.keys(touched).length === 0) return isTouched;

  if (rowType) {
    if (actionableId) {
      if (!isSectionDisabled) {
        isTouched = touched.actions && touched.actions[actionIndex] ? !!touched.actions[actionIndex][Fields.ActionableType] : false;
      } // else remain false
    } else {
      isTouched = !!touched[Fields.TriggerType];
    }
  } else {
    let field = Fields.TriggerKeys;

    if (actionableId && !isSectionDisabled) {
      field = Fields.ActionableKeys;

      if (fieldType === SelectorTypes.Watermark) {
        field = Fields.ActionableOptions;
      }

      if ((touched.actions as unknown) as boolean === true) {
        isTouched = true;
      } else {
        const touchedArrayOrOptions = touched.actions && touched.actions[actionIndex] && touched.actions[actionIndex][field];

        if (Array.isArray(touchedArrayOrOptions) && fieldIndex !== undefined && fieldIndex !== null) {
          const touchedArray = (((touchedArrayOrOptions as unknown) as KeyLabelPair[]));
          isTouched = touchedArray[fieldIndex] ? !!touchedArray[fieldIndex].key : false;
        } else {
          isTouched = !!touchedArrayOrOptions;
        }
      }
    } else {
      isTouched = !!touched[field];
    }
  }

  return isTouched;
};

interface CustomFieldValuesErrorOptions {
  errors: FormikErrors<FormState>;
  fieldType: SelectorTypes;
  actionableId?: string;
  actionIndex?: number;
  isSectionDisabled?: boolean;
}

export const getCustomFieldValuesError = (options: CustomFieldValuesErrorOptions): string | undefined => {
  const { actionableId, actionIndex, errors, fieldType, isSectionDisabled } = options;

  let error: string | undefined;

  if (fieldType !== SelectorTypes.CustomField) return error;

  if (actionableId && !isSectionDisabled) {
    error = errors.actions && errors.actions[actionIndex] && errors.actions[actionIndex][Fields.ActionableValues];
  } else if (!actionableId) {
    error = (errors.values as unknown) as string;
  }

  return error;
};

interface CustomFieldValuesTouchedOptions {
  fieldType: SelectorTypes;
  touched: FormikTouched<FormState>;
  actionableId?: string;
  actionIndex?: number;
  isSectionDisabled?: boolean;
}

export const getCustomFieldValuesTouched = (options: CustomFieldValuesTouchedOptions): boolean => {
  const { actionableId, actionIndex, fieldType, isSectionDisabled, touched } = options;

  let isTouched = false;

  if (fieldType !== SelectorTypes.CustomField) return isTouched;

  if (actionableId && !isSectionDisabled) {
    if ((touched.actions as unknown) as boolean === true) {
      isTouched = true;
    } else {
      isTouched = touched.actions && touched.actions[actionIndex] ? !!touched.actions[actionIndex][Fields.ActionableValues] : false;
    }
  } else if (!actionableId) {
    isTouched = !!touched.values;
  }

  return isTouched;
};
