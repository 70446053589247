import { ButtonLooks, FetchState, FontIcons, SearchTextfield, StandardButton } from '@brandfolder/react';
import { t, plural, Trans } from '@lingui/macro';
import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { ShareManifestIndexResponse, ShareManifestServer } from '@api/v4/share_manifests';

// eslint-disable-next-line max-len
import { SharelinksDeleteConfirmationDialog } from '@components/bulk_management/share_links/ShareLinksDeleteConfirmationDialog';
import {
  Order,
  ParamsAction,
  SortBy,
} from '@components/bulk_management/share_links/ShareLinksManagementContainer';
import { ShareLinksSortCombobox } from '@components/bulk_management/share_links/ShareLinksSortComobobox';
import { ShareLinksTable } from '@components/bulk_management/share_links/ShareLinksTable';
import '@components/bulk_management/share_links/styles/ShareLinksManagement.scss';
import { isGettyClient } from '@helpers/getty-strings';

export interface ShareLinksManagementProps {
  dispatch: Dispatch<ParamsAction>;
  setSearch: SetStateDispatch<string>;
  setSelectedShares: SetStateDispatch<string[]>
  selectedShares: string[];
  shares: ApiResponseObject<ShareManifestServer>[];
  sharesFetch: FetchState<ShareManifestIndexResponse>;
  shiftHeld: boolean;
}

export const ShareLinksManagement: FunctionComponent<ShareLinksManagementProps> = ({
  dispatch,
  setSearch,
  setSelectedShares,
  selectedShares,
  shares,
  sharesFetch,
  shiftHeld }) => {
  const [deleteSelectedSharesConfirmationOpen, setDeleteSelectedSharesConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateSearch = (event: InputChangeEvent): void => {
    setSearch(event.target.value);
  };

  const updateSelectedShares = (id: string): void => {
    setSelectedShares((prevState) => {
      if (!prevState.includes(id)) {
        prevState.push(id);
      } else {
        prevState.splice(prevState.indexOf(id), 1);
      }
      return [...prevState];
    });
  };

  const updateMultipleSelectedShares = (ids: string[]): void => {
    const allIds = selectedShares.concat(ids);
    setSelectedShares([...allIds]);
  };

  const updateSelectAll = (): void => {
    if (selectedShares.length === shares.length) {
      setSelectedShares([]);
    } else {
      const allIds = shares.map((share) => share.id);
      setSelectedShares(allIds);
    }
  };

  const selectSort = (sortBy: string): void => {
    const sort_by = sortBy.split(' ')[0];
    const order = sortBy.split(' ')[1];
    dispatch({ order: order as Order, sort_by: sort_by as SortBy, type: 'sort' });
  };

  const deleteShareLinks = useFetch({
    fetchOnMount: false,
    url: `/api/v4/brandfolders/${BFG.brandfolder_key}/share_manifests/bulk_destroy`,
    method: 'POST',
    body: {
      data: {
        attributes: { share_manifest_keys: selectedShares },
      }
    }
  });

  const deleteSelectedShareLinks = (): void => {
    setDeleteSelectedSharesConfirmationOpen(false);
    deleteShareLinks.fetch();
  };

  const onCancel = (): void => {
    setDeleteSelectedSharesConfirmationOpen(false);
    setSelectedShares([]);
  };

  useEffect(() => {
    if (deleteShareLinks.response) {
      setSelectedShares([]);
      sharesFetch.fetch();
      setTimeout(() => {
        Notify.create({
          body: t`Share links deleted.`,
          title: t`Brandfolder Updated!`,
          type: 'success'
        });
      }, 250);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteShareLinks.response]);

  useEffect(() => {
    if (sharesFetch.loading || deleteShareLinks.loading) {
      setLoading(true);
    }
  }, [sharesFetch.loading, deleteShareLinks.loading]);

  useEffect(() => {
    if (sharesFetch.response) {
      setLoading(false);
    }
  }, [sharesFetch.response]);

  const resourceName = BFG.resource.name;

  return (
    <>
      {!!shares ? (
        <div id='share-link-bulk-management'>
          <h2 className='share-link-bulk-management-header'><Trans>Share links for {resourceName}</Trans></h2>
          <div className='share-link-filters'>
            <SearchTextfield
              className='share-link-search'
              id='share-link-search'
              label={<Trans>Search</Trans>}
              onChange={(event: InputChangeEvent): void => updateSearch(event)}
              placeholder={plural(shares.length, { one: 'Search # share link', other: 'Search # share links' })}
            />
          <ShareLinksSortCombobox selectSort={selectSort}/>
          </div>
          <div className='bulk-delete-button-wrapper'>
            <StandardButton
              children='Delete'
              className='bulk-delete-button'
              disabled={!selectedShares.length}
              look={ButtonLooks.Warning}
              onClick={():void => setDeleteSelectedSharesConfirmationOpen(!deleteSelectedSharesConfirmationOpen)}
              startIcon={FontIcons.Trash}
              type='button'
            />
          </div>
          <ShareLinksTable
            loading={loading}
            resourceName={BFG.resource.name}
            selectedShares={selectedShares}
            setDeleteSelectedSharesConfirmationOpen={setDeleteSelectedSharesConfirmationOpen}
            setSelectedShares={setSelectedShares}
            shares={shares}
            shiftHeld={shiftHeld}
            updateMultipleSelectedShares={updateMultipleSelectedShares}
            updateSelectAll={updateSelectAll}
            updateSelectedShares={updateSelectedShares}
          />
        </div>
      ) : (
      <div className='manage-shares'>
        <Trans>{resourceName} does not have any share links.</Trans>
      </div>
      )}
      {deleteSelectedSharesConfirmationOpen &&
      <SharelinksDeleteConfirmationDialog
        confirmationMessage={isGettyClient()
          ? t`This will permanently delete the selected share links from this Library.`
          : t`This will permanently delete the selected share links from this Brandfolder.`
        }
        confirmationOpen={deleteSelectedSharesConfirmationOpen}
        onCancel={onCancel}
        onConfirm={deleteSelectedShareLinks}
        title={plural(selectedShares.length, { one: 'Delete Share Link', other: 'Delete # Share Links' })}
      />}
    </>
  );
};
