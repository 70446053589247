/* eslint-disable react/require-default-props */
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DownloadButton from '@components/advanced_download/download_button';
import {
  categories,
  cleanExtension,
  defaultImage,
  defaultCroppedData,
  socialMediaOptions,
  advertisingMediaOptions
} from '@components/advanced_download/helper';
import CropDimensionsInputs from '@components/advanced_download/options/crop_dimensions_inputs';
import { CroppedCDNLink } from '@components/advanced_download/options/CroppedCDNLink';
import Filetypes from '@components/advanced_download/options/filetypes';
import SizeError from '@components/advanced_download/options/size_error';
import { ListDropdown } from '@components/library/dropdown';

import './styles/main.scss';

const Options = (props) => {
  const {
    aspectRatio,
    attachmentKey,
    availablePresets,
    croppedData,
    cdnUrl,
    embedAssetSetting = false,
    filetypes,
    fixedDimensions,
    handlePrintuiImageSubmission,
    image,
    imageToThumbnailRatio,
    printuiAssetKey,
    published,
    restrictOptionsType,
    selectedFiletype,
    selectedPreset,
    setAspectRatio,
    setFixedDimensions,
    setSelectedFiletype,
    setSelectedPreset,
    setSetter,
    zoomRatio,
  } = props;

  const categoryOptions = restrictOptionsType
    ? categories().filter((category) => (restrictOptionsType.includes(category.value)))
    : categories();
  const [selectedCategory, setSelectedCategory] = useState(categoryOptions[0]);
  const [selectedSocialType, setSelectedSocialType] = useState(null);
  const [selectedAdvertisingType, setSelectedAdvertisingType] = useState(null);
  const [presetOptions, setPresetOptions] = useState(null);
  const [noCustomPresets, setNoCustomPresets] = useState(false);
  const [sizeError, setSizeError] = useState(null);

  const buildPresetOptions = (selectedSocialTypeArgument) => {
    const socialMediaOptionsString = socialMediaOptions.map((option) => option.value).join();
    const advertisingMediaOptionsString = advertisingMediaOptions.map((option) => option.value).join();
    const displayedPresets = availablePresets.filter((preset) => {
      if (selectedSocialTypeArgument.value === 'custom') {
        return !socialMediaOptionsString.includes(preset.id.split("_")[0]) && !advertisingMediaOptionsString.includes(preset.id.split("_")[0]);
      }

      return preset.id.includes(selectedSocialTypeArgument.value);
    });

    if (displayedPresets.length) {
      return displayedPresets.map((filteredPreset) => (
        { label: filteredPreset.name, value: filteredPreset }
      ));
    }

    return null;
  };

  const onSelectPreset = (selection) => {
    let newAspectRatio = null;
    let newFixedDimensions = false;
    let newFiletype = image?.extension || 'PNG';

    if (selection) {
      const { aspect_ratio, format, height, width } = selection.value;
      newFixedDimensions = Boolean(height && width);
      newFiletype = format || 'PNG';

      if (aspect_ratio || newFixedDimensions) {
        newAspectRatio = aspect_ratio || (width / height);
      }
    }

    // batch updates together to avoid rerender
    setSelectedPreset(selection);
    setAspectRatio(newAspectRatio);
    setFixedDimensions(newFixedDimensions);
    setSelectedFiletype(cleanExtension(newFiletype));
  };

  const onSocialTypeChange = (selection) => {
    const newOptions = buildPresetOptions(selection);
    onSelectPreset(newOptions[0]);
    setPresetOptions(newOptions);
    setSelectedSocialType(selection);
  };

  const onAdvertisingTypeChange = (selection) => {
    const newOptions = buildPresetOptions(selection);
    onSelectPreset(newOptions[0]);
    setPresetOptions(newOptions);
    setSelectedAdvertisingType(selection);
  };

  const onSelectedCategoryChange = (selection) => {
    onSelectPreset(null);
    setSelectedCategory(selection);
    setSelectedSocialType(null);
    setPresetOptions(buildPresetOptions(selection));
  };

  const shouldShowCroppedCDNLink =
    embedAssetSetting &&
    BFG.hasFeature('embed_asset') &&
    published &&
    !!cdnUrl &&
    (croppedData.width && croppedData.height && image.width && image.height);

  useEffect(() => {
    if (restrictOptionsType?.length) {
      onSelectedCategoryChange(categories().find((category) => (category.value === 'custom')));
    }
  }, [restrictOptionsType]);

  useEffect(() => {
    if (selectedCategory.value === 'social_media') {
      onSocialTypeChange(socialMediaOptions[0]);
    }

    if (selectedCategory.value === 'advertising_media') {
      onAdvertisingTypeChange(advertisingMediaOptions[0]);
    }

    if (presetOptions) {
      onSelectPreset(presetOptions[0]);
    }

    setNoCustomPresets(selectedCategory.value === 'custom' && !presetOptions);
  }, [selectedCategory]);

  return (
    <section className="options">
      <div className="options__content">
        <h4 className="title-copy options__content--container">
          <Trans>Export Options</Trans>
        </h4>
        <div className="selections-container options__content--container">
          <label className="brandfolder-label">
            <Trans>Crop Type</Trans>
          </label>
          <ListDropdown
            className="options-dropdown"
            name="select-preset"
            onChange={onSelectedCategoryChange}
            options={categoryOptions}
            placeholder="Select preset category"
            value={selectedCategory}
            virtualizeOptions={false}
          />
          {selectedCategory?.value === 'social_media' && (
            <>
              <label className="brandfolder-label">{selectedCategory.label}</label>
              <ListDropdown
                className="options-dropdown"
                name="select-social-type"
                onChange={onSocialTypeChange}
                options={socialMediaOptions}
                placeholder="Select preset type"
                value={selectedSocialType}
                virtualizeOptions={false}
              />
            </>
          )}
          {selectedCategory?.value === 'advertising_media' && (
            <>
              <label className="brandfolder-label">{selectedCategory.label}</label>
              <ListDropdown
                className="options-dropdown"
                name="select-advertising-type"
                onChange={onAdvertisingTypeChange}
                options={advertisingMediaOptions}
                placeholder={t`Select preset name`}
                value={selectedAdvertisingType}
                virtualizeOptions={false}
              />
            </>
          )}
          {presetOptions && (
            <>
              <label className="brandfolder-label">
                <Trans>Image Size</Trans>
              </label>
              <ListDropdown
                className="options-dropdown"
                name="select-custom-preset"
                onChange={onSelectPreset}
                options={presetOptions}
                placeholder={t`Select preset name`}
                value={selectedPreset}
                virtualizeOptions={false}
              />
            </>
          )}
        </div>
        {noCustomPresets && (
          <div className="no-custom-presets options__content--container">
            <h4>
              <Trans>No presets found.</Trans>
            </h4>
            <p>
              <Trans>
                With custom presets, admins in your org can specify pre-determined crop sizes and aspect ratios. After presets have been created, they will appear here.
              </Trans>
            </p>
          </div>
        )}
        {(selectedPreset || selectedCategory.value === 'freeform') && (
          <div className="dimensions-inputs-container options__content--container">
            <CropDimensionsInputs
              aspectRatio={aspectRatio}
              croppedData={croppedData}
              fixedDimensions={fixedDimensions}
              imageToThumbnailRatio={imageToThumbnailRatio}
              selectedPreset={selectedPreset}
              setAspectRatio={setAspectRatio}
              setSetter={setSetter}
            />
          </div>
        )}
        <Filetypes
          filetypes={filetypes}
          selectedCategory={selectedCategory}
          selectedFiletype={selectedFiletype}
          selectedPreset={selectedPreset}
          setSelectedFiletype={setSelectedFiletype}
        />
        {shouldShowCroppedCDNLink && (
          <CroppedCDNLink
            cdnUrl={cdnUrl}
            croppedData={croppedData}
            fixedDimensions={fixedDimensions}
            image={image}
            imageToThumbnailRatio={imageToThumbnailRatio}
            selectedFiletype={selectedFiletype}
            selectedPreset={selectedPreset}
            sizeError={sizeError}
          />
        )}
        {selectedPreset && (
          <SizeError
            croppedData={croppedData}
            fixedDimensions={fixedDimensions}
            image={image}
            imageToThumbnailRatio={imageToThumbnailRatio}
            selectedPreset={selectedPreset}
            setSizeError={setSizeError}
            sizeError={sizeError}
            zoomRatio={zoomRatio}
          />
        )}
      </div>
      <div className="options__buttons">
        <DownloadButton
          attachmentKey={attachmentKey}
          croppedData={croppedData}
          fixedDimensions={fixedDimensions}
          handlePrintuiImageSubmission={handlePrintuiImageSubmission}
          image={image}
          imageToThumbnailRatio={imageToThumbnailRatio}
          printuiAssetKey={printuiAssetKey}
          selectedFiletype={selectedFiletype}
          selectedPreset={selectedPreset}
          zoomRatio={zoomRatio}
        />
      </div>
    </section>
  );
};

Options.propTypes = {
  aspectRatio: PropTypes.number,
  attachmentKey: PropTypes.string,
  availablePresets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    aspect_ratio: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    format: PropTypes.string,
    pad: PropTypes.bool,
    crop_gravity: PropTypes.string,
  })),
  cdnUrl: PropTypes.string,
  croppedData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  embedAssetSetting: PropTypes.bool,
  filetypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixedDimensions: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    extension: PropTypes.string,
  }),
  imageToThumbnailRatio: PropTypes.number.isRequired,
  printuiAssetKey: PropTypes.string,
  published: PropTypes.bool.isRequired,
  selectedFiletype: PropTypes.string.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  setAspectRatio: PropTypes.func.isRequired,
  setFixedDimensions: PropTypes.func.isRequired,
  setSetter: PropTypes.func.isRequired,
  setSelectedFiletype: PropTypes.func.isRequired,
  setSelectedPreset: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
  restrictOptionsType: PropTypes.arrayOf(PropTypes.string),
  handlePrintuiImageSubmission: PropTypes.func,
};

Options.defaultProps = {
  aspectRatio: null,
  attachmentKey: null,
  availablePresets: [{
    id: '',
    name: '',
    aspect_ratio: null,
    height: null,
    width: null,
    format: '',
    pad: false,
    crop_gravity: '',
  }],
  croppedData: defaultCroppedData,
  handlePrintuiImageSubmission: PropTypes.func,
  image: defaultImage,
  printuiAssetKey: null,
  restrictOptionsType: null,
  selectedPreset: null,
};

export default Options;
