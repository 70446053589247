import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import MessageUsersStage1 from './message_users_stage_1';
import MessageUsersStage2 from './message_users_stage_2';
import MessageUsersStage3 from './message_users_stage_3';

import './styles/message_users_styles.scss';

export default class MessageUsersModal extends Component {
  state = {
    message: '',
    expandedBrandfolders: {},
    initialResourceKey: this.props.currentResourceKey,
    initialResourceType: this.props.currentResourceType,
    resources: {},
    recipients: [],
    selectedResources: {},
    stage: 1,
    submitted: false
  }

  componentDidMount() {
    this.retrieveResources();
    this.updateStylingClass('add');
  }

  componentWillUnmount() {
    this.updateStylingClass('remove');
  }

  updateStylingClass(updateType) {
    const dialog = document.getElementById('dialog');

    // 'user-permissions-modal' class used to flag React component for unmounting when closed
    if (updateType === 'add') {
      dialog.classList.add('user-permissions-modal');
    } else {
      dialog.classList.remove('user-permissions-modal');
    }
  }

  updateMessageState = (obj) => {
    Object.keys(obj).forEach((key) => this.setState({ [key]: obj[key] }));
  }

  retrieveResources() {
    const { currentOrganizationKey, currentUser } = this.props;

    fetch(`/api/v3/users/${currentUser.user_key}/permissible_resources?permission_level=admin&organization_key=${currentOrganizationKey}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${BF_Token}` },
    })
      .then((response) => response.json())
      .then((response) => this.processResourceData(response));
  }

  processResourceData(resources) {
    const { currentResourceKey, currentOrganizationKey } = this.props;
    const { brandfolders, collections } = resources.data;
    const resourceTreeData = {};

    if (!brandfolders.length && !collections.length) {
      return;
    }

    brandfolders.forEach((brandfolder) => {
      resourceTreeData[brandfolder.key] = { type: 'brandfolder', name: brandfolder.name, permitted: true };
    });

    collections.forEach((collection) => {
      if (resourceTreeData[collection.parent_key] && resourceTreeData[collection.parent_key].collections) {
        Object.assign(resourceTreeData[collection.parent_key].collections, { [collection.key]: this.buildResourceObject('collection', collection.name, true) });
      } else if (resourceTreeData[collection.parent_key]) {
        resourceTreeData[collection.parent_key].collections = { [collection.key]: this.buildResourceObject('collection', collection.name, true) };
      } else {
        // user has access to collection but not parent brandfolder
        resourceTreeData[collection.parent_key] = this.buildResourceObject('brandfolder', collection.parent_name, false);
        resourceTreeData[collection.parent_key].collections = { [collection.key]: this.buildResourceObject('collection', collection.name, true) };
      }
    });

    this.setState({ resources: resourceTreeData }, () => {
      if (currentResourceKey !== currentOrganizationKey) { // skip if user is on org page
        this.toggleResource(currentResourceKey);
        this.toggleTreeExpand(currentResourceKey);
      }
    });
  }

  buildResourceObject(type, name, permitted) {
    return { type, name, permitted };
  }

  buildSelectedResourceObject(type, name, parent_key = null) {
    return { type, name, parent_key };
  }

  cancelMessage = () => {
    const { initialResourceKey, initialResourceType } = this.state;
    const resourceInfo = { initialResourceKey, initialResourceType };

    ReactRailsUJS.unmountComponents('#dialogBody');
    BF.dialog.setup();
    BF.UserPermission.show(null, resourceInfo);
  }

  toggleResource = (clickedKey) => {
    const { resources } = this.state;
    let { selectedResources } = this.state;

    selectedResources = Object.assign(selectedResources, {});

    // remove or add clicked resource to selectedResources
    if (selectedResources[clickedKey]) {
      delete selectedResources[clickedKey]; // deselect resource key
    } else {
      if (resources[clickedKey] && resources[clickedKey].type === 'brandfolder') {
        this.toggleBrandfolder(clickedKey, resources[clickedKey]);
      } else {
        this.toggleCollection(clickedKey, resources);
      }
    }

    this.setState({ selectedResources });
  }

  toggleBrandfolder(clickedKey, brandfolder) {
    const collections = brandfolder.collections;
    const { selectedResources } = this.state;

    if (collections) {
      this.toggleAllCollections(collections, clickedKey, 'deselectAll');
    }
    // add brandfolder key
    selectedResources[clickedKey] = this.buildSelectedResourceObject('brandfolder', brandfolder.name);
    this.setState({ selectedResources });
  }

  toggleCollection(clickedKey, resources) {
    const { selectedResources } = this.state;

    Object.keys(resources).forEach((brandfolderKey) => {
      if (resources[brandfolderKey].collections) {
        const collections = resources[brandfolderKey].collections;

        Object.keys(collections).forEach((collectionKey) => {
          if (clickedKey === collectionKey) {
            // add collection key
            selectedResources[clickedKey] = this.buildSelectedResourceObject('collection', collections[clickedKey].name, brandfolderKey);
          }
        });
      }
    });

    this.setState({ selectedResources });
  }

  toggleAllCollections = (collections, parentKey, toggleType) => {
    const { selectedResources } = this.state;

    Object.keys(collections).forEach((collectionKey) => {
      delete selectedResources[collectionKey];
      if (toggleType === 'selectAll') {
        selectedResources[collectionKey] = this.buildSelectedResourceObject('collection', collections[collectionKey].name, parentKey);
      }
    });

    this.setState({ selectedResources });
  }

  toggleTreeExpand = (clickedResource) => {
    const { resources, selectedResources } = this.state;
    let { expandedBrandfolders } = this.state;
    expandedBrandfolders = Object.assign(expandedBrandfolders, {});

    if (!resources[clickedResource]) { // expand collection's parent brandfolder
      clickedResource = selectedResources[clickedResource] && selectedResources[clickedResource].parent_key;
    }

    if (!clickedResource) {
      return;
    }

    if (expandedBrandfolders[clickedResource]) {
      delete expandedBrandfolders[clickedResource];
    } else {
      expandedBrandfolders[clickedResource] = true;
    }

    this.setState({ expandedBrandfolders });
  }

  navClass(navSection) {
    return navSection === this.state.stage ? 'active' : '';
  }

  render() {
    const { message, expandedBrandfolders, resources, recipients, selectedResources, stage, submitted } = this.state;
    const { currentUser, libraryName } = this.props;

    return (
      <I18nProviderWrapper>
        <div className='message-users-component'>
          <div className='message-users-modal'>
            <nav className='nav'>
              <div  className={`nav-resources nav-section ${this.navClass(1)}`}
                    onClick={() => this.updateMessageState({stage: 1})}>
                <h5 className='nav-text'>
                  <Trans>
                    <span>01</span>Select Recipients
                  </Trans>
                </h5>
              </div>
              <div  className={`nav-users nav-section ${this.navClass(2)}`}
                    onClick={() => this.updateMessageState({stage: 2})}>
                <h5 className='nav-text'>
                  <Trans>
                    <span>02</span>Compose Message
                  </Trans>
                </h5>
              </div>
              <div  className={`nav-review nav-section ${this.navClass(3)}`}
                    onClick={() => this.updateMessageState({stage: 3})}>
                <h5 className='nav-text'>
                  <Trans>
                    <span>03</span>Review
                  </Trans>
                </h5>
              </div>
            </nav>
            <MessageUsersStage1
              cancelMessage={this.cancelMessage}
              expandedBrandfolders={expandedBrandfolders}
              libraryName={libraryName}
              message={message}
              recipients={recipients}
              resources={resources}
              selectedResources={selectedResources}
              stage={stage}
              submitted={submitted}
              toggleAllCollections={this.toggleAllCollections}
              toggleResource={this.toggleResource}
              toggleTreeExpand={this.toggleTreeExpand}
              updateMessageState={this.updateMessageState}
            />
            <MessageUsersStage2
              cancelMessage={this.cancelMessage}
              libraryName={libraryName}
              message={message}
              stage={stage}
              submitted={submitted}
              updateMessageState={this.updateMessageState}
            />
            <MessageUsersStage3
              libraryName={libraryName}
              message={message}
              recipients={recipients}
              resources={resources}
              selectedResources={selectedResources}
              senderKey={currentUser.user_key}
              stage={stage}
              submitted={submitted}
              updateMessageState={this.updateMessageState}
            />
          </div>
        </div>
      </I18nProviderWrapper>
    );
  }
}

MessageUsersModal.defaultProps = {
  libraryName: "Brandfolder"
};

MessageUsersModal.propTypes = {
  currentOrganizationKey: PropTypes.string.isRequired,
  currentResourceKey: PropTypes.string.isRequired,
  currentResourceType: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  libraryName: PropTypes.string
};
