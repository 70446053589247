import { t } from '@lingui/macro';
import getVideoId from 'get-video-id';
import React, { useEffect, useState, FunctionComponent, ReactElement } from 'react';

import fetchJSON from '@api/api_helper';
import { Asset, ExternalMediumData } from '@api/v4/assets/assetTypes';
import { EmbedlyResponse } from '@components/asset/modal/AssetModalTypes';
import { BFLoader } from '@components/common/loader/main';

// node_modules/get-video-id/index.d.ts
type VideoType = 'microsoftstream' | 'videopress' | 'vimeo' | 'vine' | 'youtube' | 'tiktok' | 'dailymotion';

interface ParsedVideo {
  service: VideoType;
  id: string;
}

enum Views {
  IFrame = 'iframe',
  Fallback = 'fallback',
  Loading = 'loading',
  Video = 'video',
}

const iframeUrl = ({ service, id }: ParsedVideo): string => {
  const urlMap = {
    youtube: `//www.youtube.com/embed/${id}`,
    videopress: `//videopress.com/embed/${id}`,
    vimeo: `//player.vimeo.com/video/${id}`,
    vine: `//vine.co/v/${id}/embed/simple`,
  };

  return urlMap[service];
};

const videoView = (assetName: string, parsedVideo: ParsedVideo): ReactElement => (
  <iframe
    allowFullScreen
    className="external-medium-preview"
    data-private
    data-testid="external-medium-preview--iframe"
    frameBorder="0"
    src={iframeUrl(parsedVideo)}
    title={assetName}
  />
);

const fallbackView = (assetName: string, thumbnail: string, url: string): ReactElement => {
  try {
    // note that docs.google.com is the host for google docs, slides, and sheets
    // not just google docs
    const { host } = new URL(url);
    if (host === 'docs.google.com') {
      return (
        <iframe
          className="external-medium-preview"
          data-private
          data-testid="external-medium-preview--iframe"
          frameBorder="0"
          src={url}
          title={assetName}
        />
      );
    }
  } catch (e) {
    console.error(e.message);
  }

  if (!!thumbnail && !!url) {
    return (
      <a
        className="responsive-height-child"
        data-testid="external-medium-preview--link"
        href={url}
      >
        <img
          alt={assetName}
          data-testid="external-medium-preview--image"
          src={thumbnail}
        />
      </a>
    );
  }

  return (
    <img
      alt={thumbnail ? assetName : t`No image available`}
      className="responsive-height-child image-preview"
      data-testid="external-medium-preview--image"
      src={thumbnail || `${BFG.icon_service_url}/assets/types/empty.svg`}
    />
  );
};

const iFrameView = (assetName: string, embedlyResponse: EmbedlyResponse, url: string): ReactElement => {
  try {
    const { host, pathname } = new URL(url);
    // we need to check the urlHost here because embedly returns incorrect html
    // for google docs but does return the correct html for google slides
    if (embedlyResponse.html && (host !== 'docs.google.com' || pathname.includes('presentation'))) {
      // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
      return <div dangerouslySetInnerHTML={{ __html: embedlyResponse.html }} className="embedly-container" />;
    }
  } catch (e) {
    console.error(e.message);
  }

  return fallbackView(assetName, embedlyResponse.thumbnail_url, url);
};

export const ExternalMediaPreview: FunctionComponent<Asset> = (asset) => {
  const [view, setView] = useState<Views>(Views.Loading);
  const [embedlyResponse, setEmbedlyResponse] = useState<EmbedlyResponse>();
  const [parsedVideo, setParsedVideo] = useState<ParsedVideo>();
  const [fetchControllers, setFetchControllers] = useState<AbortController[]>([]);

  const { thumbnail, url } = (asset.data as ExternalMediumData);

  const updateFetchControllers = (controller: AbortController): void => {
    setFetchControllers((prevState) => [...prevState, controller]);
  };

  const abortFetchControllers = (): void => {
    fetchControllers.forEach((controller) => {
      if (!controller.signal.aborted) {
        controller.abort();
      }
    });
  };

  const getEmbedlyInfo = async (): Promise<void> => {
    const embedlyUrl = `https://api.embed.ly/1/oembed?key=${BFG.EMBEDLY_API_KEY}&maxheight=385&frame=true&secure=true&url=${encodeURIComponent(url)}`;
    try {
      const response: EmbedlyResponse = await fetchJSON(embedlyUrl, {}, updateFetchControllers);
      if (response.html || response.thumbnail_url) {
        setEmbedlyResponse(response);
      } else {
        setView(Views.Fallback);
      }
    } catch {
      setView(Views.Fallback);
    }
  };

  useEffect(() => {
    const videoInfo: ParsedVideo = getVideoId(url);

    if (videoInfo?.id) {
      setParsedVideo(videoInfo);
    } else {
      getEmbedlyInfo();
    }

    return (): void => { abortFetchControllers(); };
  }, []);

  useEffect(() => {
    if (embedlyResponse) setView(Views.IFrame);
    if (parsedVideo) setView(Views.Video);
  }, [embedlyResponse, parsedVideo]);

  switch (view) {
    case Views.Loading:
      return <div className="external-medium-loader"><BFLoader /></div>;
    case Views.Video:
      return videoView(asset.name, parsedVideo);
    case Views.IFrame:
      return iFrameView(asset.name, embedlyResponse, url);
    case Views.Fallback:
    default:
      return fallbackView(asset.name, thumbnail, url);
  }
};
