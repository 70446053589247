import { ApiDataResponse } from '@api/v4/ApiResponseTypes';

/* eslint-disable @typescript-eslint/naming-convention */
export interface AttachmentResponseObject {
  attributes: Attachment;
  id: string;
  type: string;
}

export enum PreviewType {
  Audio = 'audio',
  Image = 'image',
  OfficeDoc = 'office',
  PDF = 'pdf',
  VideoMux = 'mux',
  VideoOther = 'video',
  VideoProcessing = 'processing',
}

interface BestMetadata {
  color_mode: string | null;
  color_space_data: string | null;
  create_date: string | null;
  creator: string | null;
  file_type: string | null;
  mime_type: string | null;
  page_count: string | null;
}

export interface Attachment {
  extension: string | null;
  filename: string;
  height: number;
  mimetype: string;
  position: number;
  size: number;
  thumbnail_url: string;
  url: string;
  width: number;
  best_metadata?: BestMetadata;
  cdn_url?: string;
  is_processing?: boolean;
  mux_hls_url?: string;
  preview_type?: PreviewType;
  printui_settings?: { [key: string]: string };
  view_thumbnail_retina?: string;
}

export type AttachmentWithViewOnly = Attachment & { view_only?: boolean };

export type AttachmentsListResponse = ApiDataResponse<AttachmentResponseObject, 'attachments'>;

export const isVideoType = (previewType: PreviewType): boolean => (
  [
    PreviewType.VideoMux,
    PreviewType.VideoOther,
    PreviewType.VideoProcessing
  ].includes(previewType)
);
