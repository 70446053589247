import { Trans } from '@lingui/macro';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isGettyClient } from '@helpers/getty-strings';

export default class MessageUsersStage3 extends Component {
  state = {
    isSubmittingMessage: false,
    submitButtonEnabled: this.submitEnabled,
  }

  submitEnabled() {
    !this.props.submitted &&
      (Object.keys(this.props.selectedResources).length > 1) &&
        this.props.message.length > 0 &&
          this.props.recipients.length > 0
  }

  handleSubmitMessages() {
    const successOptions = {
      type: 'success',
      title: 'Message Submitted',
      text: 'Your message will be sent as a notification to all selected users.',
      showConfirmButton: true,
      allowOutsideClick: true
    }

    const errorOptions = {
      type: 'error',
      title: 'Submission Failed',
      text: 'An error occured while submitting this message. Please try again or contact support.',
      showConfirmButton: true,
      allowOutsideClick: true
    }

    this.setState({isSubmittingMessage: true, submitButtonEnabled: false}, () => {
      this.submitNotification()
        .then((response) => {
          if(response.ok) {
            setTimeout(() => {
              this.props.updateMessageState({submitted: true});
              this.setState({isSubmittingMessage: false});
              ReactRailsUJS.unmountComponents('#dialogBody');
              BF.dialog.dismiss();
              swal(successOptions, (isConfirm) => {
              });
            }, 1500)
          } else {
            BF.dialog.dismiss();
            swal(errorOptions);
          }
        })
        .catch((error) => {
          BF.dialog.dismiss();
          swal(errorOptions);
        })
    });
  }

  submitNotification() {
    return fetch('/api/v4/notifications', {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${BF_Token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(this.buildSubmitData())
    })
  }

  buildSubmitData () {
    const {message, recipients, senderKey, selectedResources} = this.props;
    const resources = Object.keys(selectedResources).map((resourceKey) => {
      return {
        resource_type: selectedResources[resourceKey].type,
        resource_key: resourceKey
      }
    })

    return {
      data: {
        attributes: {
          notification_type: 'message',
          context: {
            message,
            sender_key: senderKey,
            recipients,
            resources }
        }
      }
    }
  }

  getResourceList() {
    if (Object.keys(this.props.selectedResources).length < 1) {
      return (
        <React.Fragment>
          <h4 className="missing-input-warning-bold">
            {isGettyClient()
              ? <Trans>You haven't selected any Libraries or Collections.</Trans>
              : <Trans>You haven't selected any Brandfolders or Collections.</Trans>
            }
          </h4>
          <h4 className="missing-input-warning">
            {isGettyClient()
              ? <Trans>Return to step 01 to select Libraries and Collections.</Trans>
              : <Trans>Return to step 01 to select Brandfolders and Collections.</Trans>
            }
          </h4>
        </React.Fragment>
      )
    }

    const { libraryName, resources, selectedResources } = this.props;
    const resourcesToSubmit = [];

    Object.keys(resources).forEach((brandfolderKey) => {
      if (selectedResources[brandfolderKey]) {
        resourcesToSubmit.push(
          <div key={brandfolderKey} className='brandfolder-submit-container submit-container'>
            <div className='brandfolder-submit-row submit-row'>
              <h4>{selectedResources[brandfolderKey].name}</h4>
            </div>
          </div>
        )
      } else {
        let parentKeyIndicator = false;

        Object.keys(selectedResources).forEach((resourceKey) => {
          selectedResources[resourceKey].parent_key === brandfolderKey ? parentKeyIndicator = true : null;
        })

        if (parentKeyIndicator) {
          resourcesToSubmit.push(
            <div key={brandfolderKey} className='brandfolder-submit-container submit-container'>
              <div className='brandfolder-submit-row submit-row not-submitted'>
                <h4>{resources[brandfolderKey].name}</h4>
              </div>
              <div className='collection-submit-container submit-container'>
                {this.buildCollectionsToSubmit(brandfolderKey)}
              </div>
            </div>
          )
        }
      }
    })

    if (resourcesToSubmit.length) {
      resourcesToSubmit.splice(0, 0, <h6 key={0} className='resource-title'>{pluralize(libraryName.toUpperCase())}</h6>);
    }

    return resourcesToSubmit;
  }

  buildCollectionsToSubmit(brandfolderKey) {
    const {selectedResources} = this.props;
    const collectionsToSubmit = [];

    Object.keys(selectedResources).forEach((resourceKey) => {
      const selectedResource = selectedResources[resourceKey];

      if (selectedResource.parent_key === brandfolderKey) {
        collectionsToSubmit.push(
          <div key={resourceKey} className='collection-submit-row submit-row'><h4>{selectedResource.name}</h4></div>
        )
      }
    })

    if (collectionsToSubmit.length) {
      collectionsToSubmit.splice(0, 0, <h6 key={0} className='resource-title'>COLLECTIONS</h6>);
    }

    return collectionsToSubmit
  }

  renderRecipientText() {
    const { recipients } = this.props;
    const lowerCaseRecipients = recipients.map((r) => r.toLowerCase());
    if (lowerCaseRecipients.includes('admin') && lowerCaseRecipients.includes('collaborator') && lowerCaseRecipients.includes('guest')) {
      return <Trans>Review your message to Admins, Collaborators, and Guests</Trans>;
    }

    if (lowerCaseRecipients.includes('admin') && lowerCaseRecipients.includes('collaborator')) {
      return <Trans>Review your message to Admins and Collaborators</Trans>;
    }

    if (lowerCaseRecipients.includes('admin') && lowerCaseRecipients.includes('guest')) {
      return <Trans>Review your message to Admins and Guests</Trans>;
    }

    if (lowerCaseRecipients.includes('collaborator') && lowerCaseRecipients.includes('guest')) {
      return <Trans>Review your message to Collaborators and Guests</Trans>;
    }

    if (lowerCaseRecipients.includes('admin')) {
      return <Trans>Review your message to Admins</Trans>;
    }

    if (lowerCaseRecipients.includes('collaborator')) {
      return <Trans>Review your message to Collaborators</Trans>;
    }

    if (lowerCaseRecipients.includes('guest')) {
      return <Trans>Review your message to Guests</Trans>;
    }

    return <Trans>Review your message</Trans>;
  }

  submitFeedback() {
    const {selectedResources, submitted, message, recipients} = this.props;
    const {submitButtonEnabled, isSubmittingMessage} = this.state;

    if (!submitButtonEnabled && isSubmittingMessage) {
      return (<h3 className='section-title center'><Trans>Submitting...</Trans></h3>)
    } else if (submitted) {
      return (<h3 className='section-title center'><Trans>Submitted!</Trans></h3>)
    } else if (!submitButtonEnabled) {
      return (<h3 className='section-title'><Trans>Review your selections:</Trans></h3>)
    }

    let   brandfoldersToSubmit = 0;
    let   collectionsToSubmit = 0;

    Object.keys(selectedResources).forEach((key) => {
      if (selectedResources[key].type === 'brandfolder') {
        brandfoldersToSubmit += 1;
      } else {
        collectionsToSubmit += 1;
      }
    })

    return (
      <h3 className='section-title'>
        {this.renderRecipientText()}
        <div className='message-container'>
          <textarea
            className="textareas"
            cols={150}
            readOnly={true}
            rows={5}
            value={message}
          />
        </div>
      </h3>
    )
  }

  render () {
    if (this.props.stage !== 3) { return null }

    const { submitted, updateMessageState } = this.props;
    const { submitButtonEnabled } = this.state;

    return (
      <React.Fragment>
        {this.state.isSubmittingMessage
          ? <video className='brandfolder-loader' height='90' poster='https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif' width='90'>Browser Doesn't Support HTML5 Video Tag</video>
          : <div className='section-body body-review-message'>
          <div className='review-list-container'>
            <div className='section-title-container'>
              {this.submitFeedback()}
            </div>
            <h3 className='section-title'>
              {isGettyClient()
                ? <Trans>Selected Libraries or Collections</Trans>
                : <Trans>Selected Brandfolders or Collections</Trans>
              }
            </h3>
            <div className='resource-list'>
              {this.getResourceList()}
            </div>
          </div>

        </div>}
        <div className='button-container'>
          <button
            className='button tertiary sm'
            onClick={() => updateMessageState({stage: 2})}
            type="button"
          >
            <Trans>Back</Trans>
          </button>
          <button
            className="button primary lg"
            disabled={!submitButtonEnabled || submitted}
            onClick={() => this.handleSubmitMessages()}
            type="button"
          >
            <Trans>Send</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

MessageUsersStage3.defaultProps = {
  libraryName: "Brandfolder"
};

MessageUsersStage3.propTypes = {
  libraryName: PropTypes.string,
  message: PropTypes.string.isRequired,
  resources: PropTypes.objectOf(PropTypes.object).isRequired,
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  senderKey: PropTypes.string.isRequired,
  selectedResources: PropTypes.objectOf(PropTypes.object).isRequired,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateMessageState: PropTypes.func.isRequired
};
