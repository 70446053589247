/* eslint-disable @typescript-eslint/naming-convention */
import {
  StandardPagination,
  useDebounce,
  useMounted,
} from '@brandfolder/react';

import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';

import { FetchState, useFetch } from '@api/ApiHelper';
import { ShareManifestIndexResponse } from '@api/v4/share_manifests';

import { ShareLinksManagement } from '@components/bulk_management/share_links/ShareLinksManagement';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { getStandardPaginationLabels } from '@translations';

export interface ParamsState {
  order: Order;
  page: number;
  per: number;
  search: string;
  sort_by: string;
}

const initialParams: ParamsState = {
  order: 'desc',
  page: 1,
  per: 500,
  search: '',
  sort_by: 'created_at',
};

export type Order = 'asc' | 'desc';
export type SortBy = 'name' | 'created_at' | 'created_by';

export type ParamsAction =
  | { page: number; type: 'page' }
  | { search: string; type: 'search' }
  | { order: Order; sort_by: SortBy; type: 'sort' };

const reducer = (state: ParamsState, action: ParamsAction): ParamsState => {
  const { type } = action;
  switch (type) {
    case 'page':
      return { ...state, page: action.page };
    case 'search':
      return { ...state, page: initialParams.page, search: action.search };
    case 'sort':
      return { ...state, order: action.order, sort_by: action.sort_by };
    default:
      return { ...state };
  }
};

const debounce = 400;

const ShareLinksManagementContainer: FunctionComponent = () => {
  const isMounted = useMounted();
  const [shares, setShares] = useState(null);
  const [selectedShares, setSelectedShares] = useState([]);
  const [paramsState, dispatch] = useReducer(reducer, initialParams);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [shiftHeld, setShiftHeld] = useState(false);
  const searchDebounced = useDebounce(search, debounce);

  const sharesFetch: FetchState<ShareManifestIndexResponse> = useFetch({
    fetchOnMount: false,
    url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/share_manifests`,
    params: {
      ...paramsState,
    },
  });

  const keyPressHandler = (event: KeyboardEvent, isDown: boolean): void => {
    if (event.key === 'Shift') {
      setShiftHeld(isDown);
    }
  };

  useEffect(() => {
    if (sharesFetch.response) {
      const sharesData = sharesFetch.response.data;

      setShares(sharesData);
      setTotalPages(sharesFetch.response.meta.total_pages || 0);
    }
  }, [sharesFetch.response]);

  useEffect(() => {
    if (isMounted) {
      dispatch({ search: searchDebounced, type: 'search' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounced]);

  useEffect(() => {
    sharesFetch.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsState]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => keyPressHandler(e, true));
    window.addEventListener('keyup', (e) => keyPressHandler(e, false));
    return () => {
      window.removeEventListener('keydown', (e) => keyPressHandler(e, true));
      window.removeEventListener('keyup', (e) => keyPressHandler(e, false));
    };
  }, []);

  if (shiftHeld) document.getSelection().removeAllRanges();

  return (
    <I18nProviderWrapper>
      <section className="share-links-management-container">
        {shares && (
          <>
            <ShareLinksManagement
              dispatch={dispatch}
              selectedShares={selectedShares}
              setSearch={setSearch}
              setSelectedShares={setSelectedShares}
              shares={shares}
              sharesFetch={sharesFetch}
              shiftHeld={shiftHeld}
            />
            {totalPages > 1 && (
              <div className="share-links-table-pagination">
                <StandardPagination
                  disabled={sharesFetch.loading}
                  labels={getStandardPaginationLabels()}
                  onPageChange={(page): void => {
                    setSelectedShares([]);
                    dispatch({ page, type: 'page' });
                  }}
                  total={totalPages}
                />
              </div>
            )}
          </>
        )}
      </section>
    </I18nProviderWrapper>
  );
};

export default ShareLinksManagementContainer;
