import { StandardButton, StandardTextarea, StandardTextfield } from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, plural, Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { create as submitRequest } from '@api/v4/resources/download_requests';
import renderModal from '@components/show_page/modals/renderModal';

import './styles/request-download-access.scss';

const schema = () => object().shape({
  name: string().required(t`Name is required.`),
  email: string().required(t`Email is required.`).email(t`Email must be valid.`),
  company: string().required(t`Company is required.`),
  usageDetails: string().required(t`Usage details are required.`)
});

const RequestDownloadAccessModal = ({
  closeModal,
  selectedAssetKeys,
  selectedViewOnlyAssetKeys
}) => {
  const [submitting, setSubmitting] = useState(false);
  const defaultValues = {
    name: BFG.currentUser ? `${BFG.currentUser.firstName} ${BFG.currentUser.lastName}`.trim() : '',
    email: BFG.currentUser?.email || '',
    company: BFG.currentUser?.company || '',
    usageDetails: ''
  }
  const { formState, handleSubmit, register } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema())
  });
  const { errors } = formState;

  // close modal on unmount
  useEffect(() => (() => closeModal()), []);

  const assetQuantity = selectedAssetKeys.size;
  let resourceInfo;

  if (BFG.manifest) {
    resourceInfo = plural(assetQuantity, {
      one: `You have requested access to download # asset from the "${BFG.manifest.name}" Share Link`,
      other: `You have requested access to download # assets from the "${BFG.manifest.name}" Share Link`
    });
  } else if (BFG.resource.type === 'collection') {
    const collectionName = decode(BFG.resource.name);
    resourceInfo = plural(assetQuantity, {
      one: `You have requested access to download # asset from the "${collectionName}" Collection`,
      other: `You have requested access to download # assets from the "${collectionName}" Collection`
    });
  } else {
    const resourceName = decode(BFG.resource.name);
    resourceInfo = plural(assetQuantity, {
      one: `You have requested access to download # asset from "${resourceName}"`,
      other: `You have requested access to download # assets from "${resourceName}"`
    });
  }

  const successOptions = () => ({
    type: 'success',
    title: t`Success!`,
    text: resourceInfo,
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: t`Close`,
    allowOutsideClick: true,
    customClass: 'swal-request-download-confirmation',
  });

  const getAssetKeysToSubmit = () => {
    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

    // if a resource that's NOT view only, only assets marked as view only are request download
    if (viewOnlyAssetsEnabled && !BFG.viewOnly) {
      return Array.from(selectedViewOnlyAssetKeys);
    } else {
      // if resource that's view only, all assets are request download
      return Array.from(selectedAssetKeys);
    }
  }

  const submit = (values) => {
    setSubmitting(true);
    let key;
    let type;

    if (BFG.manifest) {
      ({ key, type } = BFG.manifest);
    } else {
      ({ key, type } = BFG.resource);
    }

    submitRequest({
      key,
      type,
      assetKeys: getAssetKeysToSubmit(),
      name: values.name,
      'email-address': values.email,
      company: values.company,
      'usage-details': values.usageDetails
    })
      .then(() => {
        window.swal(successOptions());
        setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);

        setSubmitting(false);

        Notify.create({
          title: t`Unable to send download request.`,
          type: 'error'
        });
      });
  };

  return (
    <div className="modal-content-wrapper request-download-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-request-download icon" />
        <h3 className="modal-title"><Trans>Request Download</Trans></h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <div>
          <h3 className="body-title">
            <Trans>Provide your contact information and intended usage for the selected assets.</Trans>
          </h3>
          <form id="request-download-access-form" onSubmit={handleSubmit(submit)}>
            <StandardTextfield
              {...register('name')}
              error={errors.name?.message || ''}
              label={t`Name`}
            />
            <StandardTextfield
              {...register('email')}
              error={errors.email?.message || ''}
              label={t`Email Address`}
            />
            <StandardTextfield
              {...register('company')}
              error={errors.company?.message || ''}
              label={t`Company`}
            />
            <StandardTextarea
              {...register('usageDetails')}
              error={errors.usageDetails?.message || ''}
              label={t`Usage Details`}
            />
            <div className="request-download-access__button-container">
              <StandardButton
                disabled={submitting}
                loading={submitting}
                type="submit"
              >
                <Trans>Submit</Trans>
              </StandardButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

RequestDownloadAccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedViewOnlyAssetKeys: PropTypes.instanceOf(Set).isRequired
};

const ModalComponent = renderModal(RequestDownloadAccessModal, 'request-download-access-modal');
export default ModalComponent;
