import { t } from '@lingui/macro';
import React, {
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  SyntheticEvent,
} from 'react';

import {
  DropdownKeys,
  DropdownVariants,
  ListDropdownTransitions,
  ListOpenDirections,
  ListOptionNullOmitted,
  ListOptionValueNoNull,
  MultiselectDropdownProps
} from '@components/library/dropdown';
import BaseDropdown from '@components/library/dropdown/base/BaseDropdown';
import { dropdownKeydownHelper } from '@components/library/dropdown/dropdown_helper';
import { MultiselectAnchorElement } from '@components/library/dropdown/multiselect/MultiselectAnchorElement';
import { MultiselectOptions } from '@components/library/dropdown/multiselect/MultiselectOptions';

import styleClassNames from './multiselect-dropdown.module.scss';

export const MultiselectDropdown: FunctionComponent<MultiselectDropdownProps> = (
  props
) => {
  const {
    anchorElement,
    anchorElementLabel,
    disabled,
    dropdownTransition = ListDropdownTransitions.FadeIn,
    dropdownTransitionTime = 300,
    error,
    onChange,
    onOpen,
    openDirection = ListOpenDirections.Down,
    options,
    placeholder = t`Select values...`,
    searchable = false,
    toggleDropdown: toggleDropdownProp,
    values: optionValues, // renaming for readability
    virtualizeOptions = true,
    ...baseDropdownProps
  } = props;
  const isMounted = useRef(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // tracks open/closed status of dropdown
  const [searchInput, setSearchInput] = useState('');
  const [arrowedItem, setArrowedItem] = useState<ListOptionValueNoNull | undefined>();
  const [selectedPillValue, setSelectedPillValue] = useState<ListOptionValueNoNull | null>(null);
  const [storedTimeouts, setStoredTimeouts] = useState<number[]>([]);

  const cancelStoredTimeouts = (): void => {
    storedTimeouts.forEach((storedTimeout) => clearTimeout(storedTimeout));
  };

  const cleanup = (): void => {
    cancelStoredTimeouts(); // cancel all outstanding timeouts when unmounting
    isMounted.current = false;
  };

  // build selectedOptions object from undefined || ListOption[] || ListOptionValueNoNull[]
  // allows consumer to pass in either an array of list options or list option values for values prop
  let selectedOptions: ListOptionNullOmitted[] = [];

  if (optionValues?.length) {
    if ((optionValues as ListOptionNullOmitted[])[0]?.label !== undefined) {
      selectedOptions = optionValues as ListOptionNullOmitted[];
    } else if (options) {
      // eslint-disable-next-line array-callback-return
      (optionValues as ListOptionValueNoNull[]).map((selectedOptionValue) => { // eslint-disable-line consistent-return
        const foundOption = options.find((option) => option.value === selectedOptionValue);
        if (foundOption) {
          selectedOptions.push(foundOption);
        }
      });
    }
  }

  const searchedOptions = options?.filter((option) => {
    const toSearch = option.searchValue || option.label;
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return toSearch?.toString().toLowerCase().includes(searchInput?.toLowerCase());
  });

  const handleOnChange = (selectedOptionValue: ListOptionValueNoNull | undefined, e?: SyntheticEvent): void => {
    e?.stopPropagation(); // eslint-disable-line no-unused-expressions
    e?.preventDefault(); // eslint-disable-line no-unused-expressions
    let isAdded = false;

    const updatedSelectedOptionValues = [...selectedOptions.map((selectedOption) => selectedOption.value)];
    const newOptionIndex = selectedOptions.findIndex((selectedOption) => selectedOption.value === selectedOptionValue);
    if (newOptionIndex > -1) {
      updatedSelectedOptionValues.splice(newOptionIndex, 1);
      if (searchable) setSearchInput('');
    } else if (selectedOptionValue !== undefined) {
      isAdded = true;
      updatedSelectedOptionValues.push(selectedOptionValue);
      if (searchable) setSearchInput('');
    }

    if (onChange) {
      onChange(updatedSelectedOptionValues, isAdded, selectedOptionValue);
    }
  };

  const handleArrowingUpAndDown = (key: DropdownKeys, e: KeyboardEvent): void => {
    e.stopPropagation();
    e.preventDefault();

    const targetedIndex = searchedOptions?.findIndex((option) => option.value === arrowedItem);
    const newIndex = dropdownKeydownHelper(key, searchedOptions?.length, targetedIndex);

    if (typeof newIndex === 'number') {
      // ternary here is important (instead of checking value to be trutyh)
      // to account for value === 0 and value === undefined (which are allowed)
      setArrowedItem(searchedOptions?.[newIndex] ? searchedOptions?.[newIndex].value : undefined);
    } else if (newIndex) {
      handleOnChange(arrowedItem);
    }
  };

  const handleArrowingLeftAndRight = (key: DropdownKeys): void => {
    const selectedPillIndex = selectedOptions.findIndex((option) => option.value === selectedPillValue);
    const newPillIndex = dropdownKeydownHelper(key, selectedOptions?.length, selectedPillIndex);

    if (typeof newPillIndex === 'number') {
      setSelectedPillValue(selectedOptions[newPillIndex].value);
    }
  };

  const handleKeydown = (key: DropdownKeys, e: KeyboardEvent): void => {
    switch (key) {
      case DropdownKeys.ArrowDown:
      case DropdownKeys.ArrowUp:
      case DropdownKeys.Enter: {
        handleArrowingUpAndDown(key, e);
        break;
      }
      case DropdownKeys.Spacebar:
      case DropdownKeys.Space: {
        // if searchable, we don't want to intercept the spacebar keydown here (avoid interfering with typing in the search input)
        if (!searchable) handleArrowingUpAndDown(key, e);
        break;
      }
      case DropdownKeys.ArrowLeft:
      case DropdownKeys.ArrowRight: {
        if (searchable && searchInput.length) break; // break and avoid arrowing the pills if user is typing in the text input
        if (!selectedOptions?.length) {
          // if there are no pills selected, arrowing left and right behave like up and down arrow
          handleArrowingUpAndDown(key, e);
        } else {
          handleArrowingLeftAndRight(key);
        }
        break;
      }
      case DropdownKeys.Backspace: {
        if (selectedPillValue) {
          const selectedPillIndex = selectedOptions?.findIndex((option) => option.value === selectedPillValue);
          const newSelectedPillValue = selectedPillIndex === 0
            ? selectedOptions?.[1]?.value || null // null if you're removing the only pill
            : selectedOptions[selectedPillIndex - 1].value;
          handleOnChange(selectedPillValue);
          setSelectedPillValue(newSelectedPillValue || null);
        } else if (searchable && !searchInput.length && selectedOptions.length) {
          // delete last pill if user hits 'backspace' when search input is empty
          const lastPill = selectedOptions[selectedOptions.length - 1];
          handleOnChange(lastPill.value);
        }
        break;
      }
      case DropdownKeys.End:
        e.stopPropagation();
        e.preventDefault();
        setArrowedItem(searchedOptions?.[searchedOptions.length - 1]
          ? searchedOptions?.[searchedOptions.length - 1].value
          : undefined);
        break;
      case DropdownKeys.Home:
        e.stopPropagation();
        e.preventDefault();
        setArrowedItem(searchedOptions?.[0] ? searchedOptions?.[0].value : undefined);
        break;
      default:
        break;
    }
  };

  const defaultAnchorElement = (
    <MultiselectAnchorElement
      anchorElementLabel={anchorElementLabel}
      disabled={disabled}
      error={error}
      isDropdownOpen={isDropdownOpen}
      onChange={handleOnChange}
      placeholder={placeholder}
      searchInput={searchInput}
      searchable={searchable}
      selectedOptions={selectedOptions}
      selectedPillValue={selectedPillValue}
      setSearchInput={setSearchInput}
      setSelectedPillValue={setSelectedPillValue}
    />
  );

  const getOptions = (
    <MultiselectOptions
      arrowedItem={arrowedItem}
      handleOnChange={handleOnChange}
      options={options}
      searchable={searchable}
      searchedOptions={searchedOptions}
      selectedOptions={selectedOptions}
      styleClassNames={styleClassNames}
      virtualizeOptions={virtualizeOptions}
    />
  );

  useEffect(() => {
    if (isDropdownOpen && onOpen) {
      onOpen();
    }

    if (!isDropdownOpen) {
      setArrowedItem(undefined);
      setSelectedPillValue(null);

      if (searchable) {
        const newTimeout = window.setTimeout(() => {
          if (isMounted.current) setSearchInput('');
        }, dropdownTransitionTime + 50); // setTimeout ensures search input isn't cleared until after dropdown closes (otherwise dropdown will jump back to unsearched state)
        setStoredTimeouts((timeouts) => ([...timeouts, newTimeout]));
      }
    }
  }, [isDropdownOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => cleanup, []); // eslint-disable-line react-hooks/exhaustive-deps

  const baseProps = {
    ...baseDropdownProps,
    anchorElement: anchorElement || defaultAnchorElement,
    arrowedItem,
    children: getOptions,
    disabled,
    dropdownTransition,
    dropdownTransitionTime,
    error,
    keydownCallback: handleKeydown,
    openDirection,
    setIsDropdownOpen,
    expandOnSpacebarKeypress: !searchable,
    styleClassNames,
    toggleDropdown: toggleDropdownProp,
    useUlContainer: true,
    variantName: DropdownVariants.Multiselect,
    virtualizeOptions,
  };

  return <BaseDropdown {...baseProps} />;
};
