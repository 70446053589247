import {
  AlertLooks,
  InputLabelPositions,
  MoreInfoTooltip,
  StandardAlert,
  StandardButton,
  StandardSwitch
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { array, boolean, mixed, object, SchemaOf, string } from 'yup';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { UserRoles } from '@api/v4/private/resources/users';
import {
  getBrandfolderCollectionKeys,
  getCollectionChildKeys,
  resourceDropdownHelperText,
  userPermissionOptions,
  userPermissionPluralOptions
} from '@components/bulk_management/user-settings/helpers';
import { EmailAddresses } from '@components/bulk_management/user-settings/invitations/EmailAddresses';
import {
  generateBrandfolderCollectionOptions,
  PermissionLevelTooltip
} from '@components/bulk_management/user-settings/invitations/form-helper-components';
import {
  currentTabErrorsIsInvite,
  currentTabErrorsIsMessage,
  currentTabTouchedIsInvite,
  currentTabTouchedIsMessage,
  currentTabValuesIsInvite,
  currentTabValuesIsMessage,
  emailsAndUserGroupsSchema,
  FormTabs,
  InvitationFormValues,
  MessageFormValues
} from '@components/bulk_management/user-settings/invitations/form-type-helpers';
import { Brandfolder, Portal, ServerResourceType } from '@components/bulk_management/user-settings/resource-sidebar';
import { ListDropdown, ListOptionValueNoNull, MultiselectDropdown } from '@components/library/dropdown';
import { StandardTextField } from '@components/library/text-field';
import { moreInfoLabel } from '@components/standard-messages';

import { validateEmail } from '@helpers/emails';

import '../styles/invitation-form.scss';

enum AlertStates {
  InvitationError,
  InvitationSuccess,
  MessageError,
  MessageSuccess,
  None
}

interface InvitationFormProps {
  brandfolders: Brandfolder[];
  currentTab: FormTabs;
  portals: Portal[];
  selectedResourceKey: string;
  selectedResourceType: 'organization' | 'brandfolder' | 'collection' | 'portal' | 'brandguide';
  setReloadPendingInvitations: SetStateDispatch<boolean>;
  setReloadPlanLimits: SetStateDispatch<boolean>;
  setReloadUserGroups: SetStateDispatch<boolean>;
  setReloadUsers: SetStateDispatch<boolean>;
}

const emailErrorMessage = (): string => t`At least one email address or user group is required.`;

const invitationValidationSchema = (): SchemaOf<InvitationFormValues> => object().shape({
  resources: array()
    .of(object().shape({
      type: mixed<ServerResourceType>(),
      key: string()
    })).min(1, t`Resource is required`),
  emailsAndUserGroups: array()
    .of(emailsAndUserGroupsSchema
      .test('emailsAndUserGroups', t`Remove invalid email address`, (option): boolean => {
        return option.type !== 'create' || validateEmail(option.value.toString(), true /* allowReusableInvite */);
      }))
    .min(1, emailErrorMessage()),
  permissionLevel: mixed<UserRoles>()
    .oneOf([UserRoles.Admin, UserRoles.Collaborator, UserRoles.Guest]),
  personalMessage: string(),
  preventEmail: boolean()
});

/**
 * this variable is coerced instead of explicitly typed
 * because enums and enum arrays are not well supported
 * in the yup types
 * see https://github.com/jquense/yup/issues/1143
 * https://github.com/jquense/yup/issues/1230
 * https://github.com/jquense/yup/issues/1013
 */
const messageValidationSchema = (): SchemaOf<MessageFormValues> => object().shape({
  resources: array()
    .of(object().shape({
      type: mixed<ServerResourceType>(),
      key: string()
    })).min(1, t`Resource is required`),
  recipients: array(mixed<UserRoles>()
    .oneOf([UserRoles.Admin, UserRoles.Collaborator, UserRoles.Guest]))
    .min(1, t`Recipient is required`),
  personalMessage: string()
    .required(t`A message is required`)
}) as SchemaOf<MessageFormValues>;

const getFirstError = (errs: string[] | FormikErrors<any>[]): string | FormikErrors<any> | undefined => {
  const errorIndex = errs.findIndex((err) => err !== undefined);
  if (errorIndex === -1) {
    return undefined;
  }

  return errs[errorIndex];
};

export const InvitationForm: FunctionComponent<InvitationFormProps> = ({
  brandfolders,
  currentTab,
  portals,
  selectedResourceKey,
  selectedResourceType,
  setReloadPendingInvitations,
  setReloadPlanLimits,
  setReloadUserGroups,
  setReloadUsers
}) => {
  const [clearTags, setClearTags] = useState(false);
  const [submittedMessageValues, setSubmittedMessageValues] = useState<MessageFormValues | undefined>();
  const [submittedInvitationValues, setSubmittedInvitationValues] = useState<InvitationFormValues | undefined>();
  // fake loading because it displays while we wait to trigger reload of other components
  // rather than the actual loading of waiting for fetch to return
  const [fakeLoading, setFakeLoading] = useState(false);
  const [showAlert, setShowAlert] = useState<AlertStates>(AlertStates.None);

  const submittedResources = submittedInvitationValues?.resources.map((resource) => ({
    resource_type: resource.type,
    resource_key: resource.key
  }));

  const emailAddresses = submittedInvitationValues?.emailsAndUserGroups.filter((option) => option.type === 'user' || option.type === 'create');
  const userGroupKeys = submittedInvitationValues?.emailsAndUserGroups.filter((option) => option.type === 'user-group');

  const bulkCreateInvitationEmailFetch = useFetch({
    url: '/api/v4/invitations',
    fetchOnMount: false,
    method: 'POST',
    /* eslint-disable @typescript-eslint/naming-convention */
    body: {
      data: {
        resources: submittedResources,
        attributes: {
          emails: [...new Set(emailAddresses?.map((option) => option.value))],
          permission_level: submittedInvitationValues?.permissionLevel,
          personal_message: submittedInvitationValues?.personalMessage || undefined,
          prevent_email: submittedInvitationValues?.preventEmail
        }
      }
    }
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  const userGroupFetchOptions = (userGroupKey = ''): UseFetchOptions => ({
    url: `api/v4/user_groups/${userGroupKey}/user_group_permissions`,
    fetchOnMount: false,
    method: 'POST',
    /* eslint-disable @typescript-eslint/naming-convention */
    body: {
      data: {
        attributes: {
          permission_level: submittedInvitationValues?.permissionLevel,
          resources: submittedResources,
        }
      }
    }
  });
  const bulkCreateInvitationUserGroupFetch = useFetch(userGroupFetchOptions());

  const messageFetch = useFetch({
    url: '/api/v4/notifications',
    fetchOnMount: false,
    method: 'POST',
    body: {
      data: {
        attributes: {
          notification_type: 'message',
          context: {
            resources: submittedMessageValues?.resources.map((resource) => ({
              resource_type: resource.type,
              resource_key: resource.key
            })),
            message: submittedMessageValues?.personalMessage,
            recipients: submittedMessageValues?.recipients,
            sender_key: BFG.currentUser?.user_id
          }
        }
      }
    }
  });

  useEffect(() => {
    if (submittedInvitationValues) {
      if (!!emailAddresses.length) {
        bulkCreateInvitationEmailFetch.fetch();
      }

      if (!!userGroupKeys.length) {
        userGroupKeys.forEach((userGroup) => {
          bulkCreateInvitationUserGroupFetch.fetch(userGroupFetchOptions(userGroup.key as string));
        });
      }
      setFakeLoading(true);
    }
  }, [submittedInvitationValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (submittedMessageValues) {
      messageFetch.fetch();
    }
  }, [submittedMessageValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bulkCreateInvitationEmailFetch.response || bulkCreateInvitationUserGroupFetch.response) {
      // give the bulk invitation job 1 second to reasonably complete
      setTimeout(() => {
        setReloadPendingInvitations(true);
        setReloadPlanLimits(true);
        setReloadUserGroups(true);
        setReloadUsers(true);
        setShowAlert(AlertStates.InvitationSuccess);
        setFakeLoading(false);
        setClearTags(true);
      }, 1000);
    }
  }, [bulkCreateInvitationEmailFetch.response, bulkCreateInvitationUserGroupFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bulkCreateInvitationEmailFetch.error || bulkCreateInvitationUserGroupFetch.error) {
      setFakeLoading(false);
      setShowAlert(AlertStates.InvitationError);
    }
  }, [bulkCreateInvitationEmailFetch.error, bulkCreateInvitationUserGroupFetch.error]);

  useEffect(() => {
    if (messageFetch.response) {
      setShowAlert(AlertStates.MessageSuccess);
    }
  }, [messageFetch.response]);

  useEffect(() => {
    if (messageFetch.error) {
      setShowAlert(AlertStates.MessageError);
    }
  }, [messageFetch.error]);

  useEffect(() => {
    if (clearTags) {
      setClearTags(false);
    }
  }, [clearTags]);

  const invitationAlerts = (
    <>
      {showAlert === AlertStates.InvitationSuccess && (
        <StandardAlert
          className="invitation-form__submission-alert"
          look={AlertLooks.Success}
        >
          <Trans>We're working on creating invitations. You'll receive a notification when the invitations have been sent.</Trans>
        </StandardAlert>
      )}
      {showAlert === AlertStates.InvitationError && (
        <StandardAlert
          className="invitation-form__submission-alert"
          look={AlertLooks.Error}
        >
          <Trans>Something went wrong while creating invitations. Try again or contact customer support.</Trans>
        </StandardAlert>
      )}
    </>
  );

  const messageAlerts = (
    <>
      {showAlert === AlertStates.MessageSuccess && (
        <StandardAlert
          className="invitation-form__submission-alert"
          look={AlertLooks.Success}
        >
          <Trans>Your message will be sent as a notification to all selected users.</Trans>
        </StandardAlert>
      )}
      {showAlert === AlertStates.MessageError && (
        <StandardAlert
          className="invitation-form__submission-alert"
          look={AlertLooks.Error}
        >
          <Trans>Something went wrong while submitting your message. Try again or contact customer support.</Trans>
        </StandardAlert>
      )}
    </>
  );

  const invitationInitialValues: InvitationFormValues = {
    resources: [{
      type: selectedResourceType as ServerResourceType,
      key: selectedResourceKey
    }],
    emailsAndUserGroups: [],
    permissionLevel: UserRoles.Guest,
    personalMessage: '',
    preventEmail: false
  };

  const messageInitialValues: MessageFormValues = {
    resources: [{
      type: selectedResourceType as ServerResourceType,
      key: selectedResourceKey
    }],
    recipients: [],
    personalMessage: ''
  };

  const emailAddressesErrors = (
    errors: FormikErrors<InvitationFormValues>
  ): string | null => {
    if (Array.isArray(errors.emailsAndUserGroups)) {
      return getFirstError(errors.emailsAndUserGroups) as string;
    } else {
      return errors.emailsAndUserGroups;
    }
  };

  const emailAddressesLabelText = t`Email addresses or groups`;

  const emailAddressesPlaceholderText = t`Search users, user groups, or enter email address`;

  const tabContent = (
    <Formik
      enableReinitialize
      initialValues={currentTab === 'invite' ? invitationInitialValues : messageInitialValues}
      onReset={(): void => {
        setClearTags(true);
      }}
      onSubmit={(values, { resetForm }): void => {
        if (currentTabValuesIsInvite(currentTab, values)) {
          setSubmittedInvitationValues(values);
        } else {
          setSubmittedMessageValues(values);
        }
        resetForm();
      }}
      validationSchema={currentTab === 'invite' ? invitationValidationSchema() : messageValidationSchema()}
    >
      {(formikBag): JSX.Element => {
        const {
          errors,
          setFieldValue,
          touched,
          values
        } = formikBag;

        const resourceOptions = generateBrandfolderCollectionOptions(brandfolders, portals, values.resources);

        const handleMultiselectChange = (
          selectedOptions: ListOptionValueNoNull[],
          isAdded: boolean,
          updatedValue: ListOptionValueNoNull
        ): void => {
          let updatedResources = [];
          selectedOptions.forEach((option) => {
            const [type, key] = (option as string).split('|');
            updatedResources.push({ type, key });
          });

          const [type, key] = (updatedValue as string).split('|');
          if (isAdded && type === 'brandfolder') {
            const childResourceKeys = getBrandfolderCollectionKeys(key, brandfolders);
            updatedResources = updatedResources.filter((resource) => (
              !childResourceKeys.includes(resource.key)
            ));
          } else if (isAdded && type === 'collection') {
            const childResourceKeys = getCollectionChildKeys(key, brandfolders);
            updatedResources = updatedResources.filter((resource) => (
              !childResourceKeys.includes(resource.key)
            ));
          }
          setFieldValue('resources', [...new Set(updatedResources)]);
        };
        return (
          <Form id="invite-users-form">
            {currentTabValuesIsInvite(currentTab, values)
            && currentTabTouchedIsInvite(currentTab, touched)
            && currentTabErrorsIsInvite(currentTab, errors)
            && (
              <EmailAddresses
                allowUserGroups
                clearTags={clearTags}
                error={emailAddressesErrors(errors)}
                label={emailAddressesLabelText}
                placeholder={emailAddressesPlaceholderText}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                setFieldValue={setFieldValue}
              />
            )}
            <div className="invitation-form--dropdown-row">
              <MultiselectDropdown
                className={classnames(
                  'invitation-form__resource-multiselect',
                  { 'resources-error': touched.resources && errors.resources },
                  { 'has-recipients-error': (touched as FormikTouched<MessageFormValues>).recipients
                    && (errors as FormikErrors<MessageFormValues>).recipients },
                  { 'message-tab': currentTab === 'message' }
                )}
                error={touched.resources && errors.resources}
                id="resource-multiselect"
                label={
                  <div className="invitation-form__resource-multiselect__label">
                    <Trans>Resource</Trans>
                    <MoreInfoTooltip
                      iconLabel={moreInfoLabel()}
                      id="invitation-form__resource-multiselect__more-info"
                      tooltip={resourceDropdownHelperText()}
                    />
                  </div>
                }
                onChange={handleMultiselectChange}
                options={resourceOptions}
                searchable
                values={values.resources.map(({ key, type }) => `${type}|${key}`)}
                virtualizeOptions={resourceOptions.length > 10}
              />
              {currentTabValuesIsInvite(currentTab, values) && (
                <ListDropdown
                  className="invitation-form__permission-level"
                  label={
                    <div className="invitation-form__resource-multiselect__label">
                      <Trans>Permission level</Trans>
                      <PermissionLevelTooltip />
                    </div>
                  }
                  onChange={(selectedOption): void => {
                    setFieldValue('permissionLevel', selectedOption.value);
                  }}
                  options={userPermissionOptions()}
                  value={values.permissionLevel}
                  virtualizeOptions={false}
                />
              )}
              {currentTabValuesIsMessage(currentTab, values)
              && currentTabErrorsIsMessage(currentTab, errors)
              && currentTabTouchedIsMessage(currentTab, touched)
              && (
                <MultiselectDropdown
                  className={classnames(
                    'invitation-form__permission-level',
                    { 'has-resources-error': touched.resources && errors.resources },
                    { 'recipients-error': touched.recipients && errors.recipients },
                    { 'message-tab': currentTab === 'message' }
                  )}
                  error={touched.recipients && errors.recipients}
                  id="recipient-multiselect"
                  label={<Trans>Recipients</Trans>}
                  onChange={(selectedOptions): void => {
                    setFieldValue('recipients', [...selectedOptions]);
                  }}
                  options={userPermissionPluralOptions}
                  values={values.recipients}
                  virtualizeOptions={false}
                />
              )}
            </div>
            <div className="invitation-form--message-row">
              <StandardTextField
                disabled={currentTabValuesIsInvite(currentTab, values) && values.preventEmail}
                error={touched.personalMessage && errors.personalMessage}
                id="invite-users-form__personal-message"
                label={
                  <div>
                    {currentTab === 'invite'
                      ? (
                        <Trans>
                          Personal message&nbsp;
                          <span className="invitation-form__label--light">
                            <Trans>(optional)</Trans>
                          </span>
                        </Trans>
                      ) : <Trans>Message</Trans>}
                  </div>
                }
                multiLine
                name="invite-users-form__personal-message"
                onChange={(e): void => {
                  setFieldValue('personalMessage', e.target.value);
                }}
                placeholder=""
                value={values.personalMessage}
              />
            </div>
            <div className={
              classnames(
                'invitation-form--submission-row', {
                  'invitation-form--submission-row--right-aligned': currentTab === 'message'
                })
            }>
              {currentTabValuesIsInvite(currentTab, values) && (
                <StandardSwitch
                  className="invitation-form__prevent-emails"
                  isChecked={!values.preventEmail}
                  labelPosition={InputLabelPositions.Right}
                  onChange={(): void => {
                    setFieldValue('preventEmail', !values.preventEmail);
                  }}
                >
                  <Trans>Send invitations via email</Trans>
                </StandardSwitch>
              )}
              <StandardButton
                loading={fakeLoading}
                type='submit'
              >
                {currentTab === 'invite' ? <Trans>Send invitations</Trans> : <Trans>Send message</Trans>}
              </StandardButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <div className="invitation-form">
      {currentTab === 'message' && (
        <>
          <h3 className="invitation-form__heading"><Trans>Message Users</Trans></h3>
          {messageAlerts}
        </>
      )}
      {currentTab === 'invite' && (
        <>
          <h3 className="invitation-form__heading"><Trans>Invite Users</Trans></h3>
          {invitationAlerts}
        </>
      )}
      {tabContent}
    </div>
  );
};
