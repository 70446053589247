import { t } from '@lingui/macro';
import React, { FunctionComponent, useContext } from 'react';

import fetchJSON from '@api/api_helper';
import { ExternalMediumData } from '@api/v4/assets/assetTypes';
import { EmbedlyResponse } from '@components/asset/modal/AssetModalTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import {
  ActionTypes,
  AssetDetailsDispatch,
  ReactTag,
  ValidationErrors,
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { initializeTrumbowyg } from '@components/asset/modal/tabs/edit/main_pane/detailsSection';
import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';
import { StandardTextField } from '@components/library/text-field';

interface ExternalMediumSideBarPropsScopedContext {
  state: {
    editState: {
      assetDescription: string;
      assetName: string;
      externalMediumData: ExternalMediumData;
      tags: ReactTag[];
    };
    errorState: ValidationErrors[];
  };
  dispatch: AssetDetailsDispatch;
}

const emptyExternalMediumData: ExternalMediumData = {
  url: ''
};

const ExternalMediumSideBar: FunctionComponent<{ updateFetchControllers: () => void }> = ({ updateFetchControllers }) => {
  const { state, dispatch }: ExternalMediumSideBarPropsScopedContext = useContext(assetModalContext);
  const { assetName, assetDescription, tags } = state.editState;
  const externalMediumData = state.editState.externalMediumData || emptyExternalMediumData;
  const hasInputError = state.errorState.includes(ValidationErrors.MissingMediaUrl);

  const getEmbedlyInfoAsync = async (): Promise<void> => {
    // This runs on every onBlur event if one of the things it could fill in
    // (asset name, asset description, or tags) is empty.
    // This runs on both the new asset form and the update form
    if ((!assetName || !assetDescription || tags.length === 0) && externalMediumData.url?.trim()) {
      try {
        const embedlyUrl = `https://api.embed.ly/1/oembed?key=${BFG.EMBEDLY_API_KEY}&maxheight=385&frame=true&secure=true&url=${encodeURIComponent(externalMediumData.url)}`;
        const embedlyResponse: EmbedlyResponse = await fetchJSON(embedlyUrl, {}, updateFetchControllers);
        dispatch({
          type: ActionTypes.UpdateAsset,
          payload: {
            assetName: assetName || embedlyResponse.title || '',
            assetDescription: assetDescription || embedlyResponse.description,
            tags: tags.length === 0
              ? [
                { name: embedlyResponse.type, id: embedlyResponse.type, auto_generated: false }, // eslint-disable-line @typescript-eslint/naming-convention
                { name: embedlyResponse.provider_name, id: embedlyResponse.provider_name, auto_generated: false } // eslint-disable-line @typescript-eslint/naming-convention
              ]
              : tags
          }
        });
        // re-initialize wysiwyg editor with new asset description
        initializeTrumbowyg(assetDescription || embedlyResponse.description, dispatch);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getEmbedlyInfo = (): void => {
    getEmbedlyInfoAsync();
  };

  return (
    <div className="external-medium-sidebar edit-tab-sidebar one-third bf-scroll-element">
      <AssetNameInput assetNameCopy={t`Name`} />
      <StandardTextField
        className="external-medium-media-url"
        error={hasInputError ? t`Media URL required` : ''}
        id="media_url"
        label={t`Media URL`}
        name="media_url"
        onBlur={(): void => getEmbedlyInfo()}
        onChange={(e: InputChangeEvent): void => dispatch({
          type: ActionTypes.UpdateAsset,
          payload: { externalMediumData: { url: e.target.value } }
        })}
        placeholder=""
        value={externalMediumData.url}
      />
    </div>
  );
};

export default ExternalMediumSideBar;
