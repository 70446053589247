import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog, } from '@components/library/dialog';
import { ListDropdown } from '@components/library/dropdown';
import { FontIcon, FontIcons } from '@components/library/icon';
import Label from '@components/library/labels/PrimaryLabel';
import { camelizeObjectKeys } from '@components/library/utils';
import {
  connectionSuccessfulNotify,
  connectionUnsuccessfulNotify,
  errorNotify,
  getLinkOptions,
  popupCenter,
  postOptions,
  setSheetOptions,
} from '@components/workspace/helpers';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import {
  isConnectWorksheetsData,
  isConnectUrlData,
  ApiResponseObjectConnectClient,
  ApiResponseObjectConnectServer,
  ConnectResponseAttributesClient,
  ConnectResponseAttributesServer,
  ConnectResponseServer,
  GetConnectResponse,
  WorksheetDatum,
  WorkspaceUpdatedEvent
} from '@components/workspace/WorkspaceTypes';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { getCurrentUserIsAdminPlus } from '@helpers/user';

import classes from './styles/workspace-dashbar.module.scss';

const successCopy = bfTranslate('Smartsheet is connected to your Brandfolder Workspace');
const successCopyAuthorized = bfTranslate('Authorization is complete');
const noWorkspacesAvailableCopy = (): string => bfTranslate('No Smartsheet Workspaces available to connect to. Navigate to your Smartsheet account to join or create a Smartsheet Workspace and then try connecting again.');

interface WorkspaceSmartsheetConnectProps {
  orgApiKey?: string;
}

export const WorkspaceSmartsheetConnect: FunctionComponent<WorkspaceSmartsheetConnectProps> = ({
  orgApiKey,
}) => {
  const [renderDialogHTML, setRenderDialogHTML] = useState(false);
  const [isSelectionDialogOpen, setIsSelectionDialogOpen] = useState(false);
  const [isCtaDialogOpen, setIsCtaDialogOpen] = useState(false);
  const [selectedWorksheet, setSelectedWorksheet] = useState<WorksheetDatum>(null);

  const getSmartsheetUrlResponse = useFetch<GetConnectResponse>(getLinkOptions(orgApiKey));
  const smartsheetConnectResponse = useFetch<ConnectResponseServer>(postOptions(orgApiKey));
  const setWorksheetResponse = useFetch<ConnectResponseServer>(setSheetOptions(orgApiKey, selectedWorksheet?.id));

  // Parse the fetch responses
  const { data } = { ...getSmartsheetUrlResponse.response };
  const {
    oauthUrl: oauthUrlConnectResponse
  } = { ...camelizeObjectKeys<ApiResponseObjectConnectServer, ApiResponseObjectConnectClient>(smartsheetConnectResponse?.response?.data) };
  const {
    hasAuthenticated: hasAuthenticatedSetWorksheet,
    smarSheetPermalink: smarSheetPermalinkSetWorksheet,
  } = { ...camelizeObjectKeys<ConnectResponseAttributesServer, ConnectResponseAttributesClient>(setWorksheetResponse.response?.data?.attributes) };

  // Assign variables if values exist
  let hasAuthenticated: boolean | undefined = hasAuthenticatedSetWorksheet;
  let oauthUrl: string | undefined = oauthUrlConnectResponse;
  let smarSheetPermalink: string | undefined = smarSheetPermalinkSetWorksheet;
  let dataSmartsheetWorksheets: WorksheetDatum[] | undefined;

  let oauthWindow: Window;
  let smarWorksheetWindow: Window;

  // Check data type (type predicates), the data can be in 1 of 2 forms/structures
  if (data && isConnectUrlData(data)) {
    // "data" has the traditional properties (including "atttributes")
    const dataGetSmartsheetUrl = camelizeObjectKeys<ApiResponseObjectConnectServer, ApiResponseObjectConnectClient>(data);

    hasAuthenticated = dataGetSmartsheetUrl.attributes.hasAuthenticated;
    oauthUrl = dataGetSmartsheetUrl.oauthUrl || oauthUrlConnectResponse;
    smarSheetPermalink = dataGetSmartsheetUrl.attributes.smarSheetPermalink;
  }

  if (data && isConnectWorksheetsData(data)) {
    // "data" is an array of available smartsheet worksheets
    dataSmartsheetWorksheets = data;
  }

  const handleReceiveOauthMessage = (e: MessageEvent): void => {
    if (!e.data || !Object.keys(e.data).includes('authorized')) return;

    // close oauth window and remove event listener
    if (oauthWindow && !oauthWindow.closed) oauthWindow.close();
    window.removeEventListener('message', handleReceiveOauthMessage);

    if (e?.data?.authorized) {
      if (!smarSheetPermalink) {
        // Authorization complete, but no Smar workspace selected
        connectionSuccessfulNotify(successCopyAuthorized);
        setIsSelectionDialogOpen(true);
      } else {
        // Authorization complete and Smar workspace is already selected, so connection is complete
        connectionSuccessfulNotify(successCopy);
      }
      getSmartsheetUrlResponse.fetch();
      sendAction(TrackedAction.WorkspaceSmartsheetConnectOAuthSuccess);
    } else {
      connectionUnsuccessfulNotify();
      setIsCtaDialogOpen(true);
    }
  };

  const handleResetConnectionMessage = (e: WorkspaceUpdatedEvent): void => {
    if (e.detail?.connectionReset) {
      setSelectedWorksheet(null);
      getSmartsheetUrlResponse.fetch();
    }
  };

  const handleClick = (): void => {
    // ContentSync environments have not been setup for this BF workspace
    if (!oauthUrl && !dataSmartsheetWorksheets) {
      smartsheetConnectResponse.fetch(); // <-- trigger setting up ContentSync environments
      return;
    }

    // OAuth needs to be completed
    if (!!oauthUrl && !hasAuthenticated) {
      if (oauthWindow && !oauthWindow.closed) {
        oauthWindow.focus(); // bring window into view if it's hidden
      } else {
        const popupWidth = 740;
        const popupHeight = 530;
        oauthWindow = popupCenter({ url: oauthUrl, title: '_blank', w: popupWidth, h: popupHeight });

        // add event after a window object has been assigned to oauthWindow variable
        // (so that the window can be closed from the handleReceiveOauthMessage method)
        window.addEventListener('message', handleReceiveOauthMessage);
      }
    }

    // available smartsheetWorkspaces are loaded but user has not selected one yet
    if (!smarSheetPermalink && dataSmartsheetWorksheets && !selectedWorksheet) {
      setIsSelectionDialogOpen(true);
      return;
    }

    // everthing is setup, send user to the connected Smartsheet Worksheet!
    if (hasAuthenticated && !!smarSheetPermalink) {
      if (smarWorksheetWindow && !smarWorksheetWindow.closed) {
        smarWorksheetWindow.focus(); // bring window into view if it's hidden
      } else {
        smarWorksheetWindow = window.open(smarSheetPermalink, '_blank');
      }
      sendAction(TrackedAction.WorkspaceSmartsheetConnectClickThroughToConnectedSheet);
    }
  };

  /* *** POST - setup ContentSync environments *** */
  useEffect(() => {
    if (smartsheetConnectResponse.response && !!oauthUrl) {
      // trigger oauth popup
      handleClick();
    }
  }, [smartsheetConnectResponse.response, oauthUrl]);

  /* *** POST - set Smartsheet Workspace *** */
  useEffect(() => {
    if (setWorksheetResponse.response) {
      if (smarSheetPermalink) {
        connectionSuccessfulNotify(successCopy);
      } else {
        // error, no permalink came back
        errorNotify();
      }
    }
  }, [setWorksheetResponse.response]);

  useEffect(() => {
    // error catch all
    if (getSmartsheetUrlResponse.error || smartsheetConnectResponse.error || setWorksheetResponse.error) {
      console.log(getSmartsheetUrlResponse.error, smartsheetConnectResponse.error, setWorksheetResponse.error);
      sendAction(TrackedAction.WorkspaceSmartsheetConnectError);
    }
  }, [getSmartsheetUrlResponse.error, smartsheetConnectResponse.error, setWorksheetResponse.error]);

  useEffect(() => {
    window.addEventListener(WorkspaceEvents.WorkspaceConnectionReset, handleResetConnectionMessage);

    return (): void => {
      window.removeEventListener('message', handleReceiveOauthMessage);
      window.removeEventListener(WorkspaceEvents.WorkspaceConnectionReset, handleResetConnectionMessage);
    };
  }, []);

  const worksheetOptions = dataSmartsheetWorksheets
    ? dataSmartsheetWorksheets.map(({ id, name }) => ({ label: name, value: id }))
    : null;

  const isAnyFetchLoading = getSmartsheetUrlResponse.loading || smartsheetConnectResponse.loading || setWorksheetResponse.loading;
  const isConnected = hasAuthenticated && !!smarSheetPermalink;

  return (!smarSheetPermalink && !getCurrentUserIsAdminPlus(BFG.currentUser)) ? null : (
    <>
      <PrimaryButton
        className={classes['smartsheet-button']}
        icon={FontIcons.Smartsheet}
        isLoading={isAnyFetchLoading}
        onClick={(): void => {
          handleClick();
          if (!renderDialogHTML) setRenderDialogHTML(true); // render html only when it's needed
        }}
      >
        {!smarSheetPermalink || !hasAuthenticated ? bfTranslate('Connect to Smartsheet') : bfTranslate('Open in Smartsheet')}
      </PrimaryButton>
      {renderDialogHTML && (
        <>
          <StandardDialog
            dialogBodyClassName="connect-smartsheet-dialog"
            height={450}
            id="connect-smartsheet-dialog"
            onClose={(): void => setSelectedWorksheet(null)}
            open={isSelectionDialogOpen}
            setOpen={setIsSelectionDialogOpen}
            showFooter={false}
            title={bfTranslate('Select a Workspace in Smartsheet to connect to Brandfolder')}
            titleIcon="bff-smartsheet"
            width={750}
          >
            <div className="connect-smartsheet-dialog__body">
              <div className="connect-smartsheet-dialog__options">
                {dataSmartsheetWorksheets?.length === 0 && (
                  <div className="connect-smartsheet-dialog__no-options">
                    <FontIcon aria-hidden icon={FontIcons.Warning} iconSize={28} />
                    <p>{noWorkspacesAvailableCopy()}</p>
                  </div>
                )}
                <Label>{bfTranslate('Select Smartsheet Workspace')}</Label>
                <ListDropdown
                  className="connect-smartsheet-dialog__options--dropdown"
                  id="workspace-options"
                  onChange={({ value }): void => {
                    setSelectedWorksheet(dataSmartsheetWorksheets?.find((sheet) => (sheet.id === value)));
                  }}
                  options={worksheetOptions}
                  value={selectedWorksheet?.id}
                />
              </div>
              <PrimaryButton
                className="connect-smartsheet-dialog__submit"
                disabled={dataSmartsheetWorksheets?.length < 1 || isConnected}
                icon={isConnected ? FontIcons.CheckMark : undefined}
                isLoading={setWorksheetResponse.loading}
                loadingCopy={bfTranslate('Connecting')}
                loadingIcon={FontIcons.RefreshSpinning}
                onClick={(): void => { setWorksheetResponse.fetch(); }}
                size="small"
                type="submit"
              >
                {isConnected ? bfTranslate('Connected') : bfTranslate('Connect')}
              </PrimaryButton>
            </div>
          </StandardDialog>
          <StandardDialog
            dialogBodyClassName="smartsheet-cta-dialog"
            footer={(
              <div className="smartsheet-cta-dialog__buttons">
                <TertiaryButton
                  className="smartsheet-cta-dialog__explore"
                  onClick={(): void => {
                    window.open('https://brandfolder.com/product/smartsheet-and-brandfolder/', '_blank');
                    setIsCtaDialogOpen(false);
                  }}
                >
                  {bfTranslate('Explore Smartsheet')}
                </TertiaryButton>
                <PrimaryButton
                  className="smartsheet-cta-dialog__connect"
                  loadingCopy={bfTranslate('Connecting')}
                  onClick={(): void => {
                    handleClick();
                    setIsCtaDialogOpen(false);
                  }}
                >
                  {bfTranslate('Connect Smartsheet')}
                </PrimaryButton>
              </div>
            )}
            id="smartsheet-cta-dialog"
            onClose={(): void => setIsCtaDialogOpen(false)}
            open={isCtaDialogOpen}
            setOpen={setIsCtaDialogOpen}
            title={bfTranslate('You chose not to connect to Smartsheet')}
            width={500}
          >
            <div className="smartsheet-cta-dialog__body">
              <p>
                {bfTranslate('Get the most out of Brandfolder Workspaces by connecting Smartsheet and bringing marketing, creative, and content together in one place.')}
              </p>
            </div>
          </StandardDialog>
        </>
      )}
    </>
  );
};
