import { InputErrorPositions, StandardDatePicker } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { useContext, FunctionComponent } from 'react';

import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes, ValidationErrors } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { TaskDetailsSectionScopedContext } from '@components/asset/modal/tabs/edit/main_pane/WorkspaceTypes';
import { WorkspaceUserSection } from '@components/asset/modal/tabs/edit/main_pane/WorkspaceUserSection';
import Label from '@components/library/labels/PrimaryLabel';
import { StandardTextField } from '@components/library/text-field';
import { WorkspaceTaskPriorityDropdown } from '@components/workspace/WorkspaceTaskPriorityDropdown';
import { WorkspaceTaskStatusDropdown } from '@components/workspace/WorkspaceTaskStatusDropdown';
import { getStandardDatePickerLabels } from '@translations';

import './styles/_workspace-details.scss';

export const errorMessages = {
  statusRequired: bfTranslate('A status is required'),
  descriptionTooLong: bfTranslate('Description is too long (maximum is 5000 characters)')
};

export const WorkspaceDetailsSection: FunctionComponent = () => {
  const { state, dispatch }: TaskDetailsSectionScopedContext = useContext(assetModalContext);

  const { task } = state.editState;
  const { dimensions, dueDate, filetype, taskDescription, taskPriority, taskStatus } = task;

  return (
    <div className="workspace-dialog">
      <div className="workspace-dialog__details">
        <div className="workspace-dialog__details--row">
          <StandardDatePicker
            allowPastDates={false}
            className='workspace-dialog__details--due-date'
            defaultSelectedDate={dueDate}
            error={state.errorState.includes(ValidationErrors.InvalidDueDate) ? t`Invalid date!` : undefined}
            errorPosition={InputErrorPositions.Top}
            id="create-workspace-due-date"
            labels={getStandardDatePickerLabels()}
            locale={determineUGTLocale()}
            onSelection={(date): void => {
              dispatch({
                type: ActionTypes.UpdateTask,
                payload: {
                  task: {
                    ...task,
                    dueDate: date.toISOString()
                  }
                }
              });
            }}
            showLabel
          />
          <div
            className="workspace-dialog__details--priority"
          >
            <Label
              attributes={{
                className: 'workspace-dialog__details--priority-label',
                htmlFor: 'create-workspace-priority'
              }}
            >
              <Trans>Priority</Trans>
            </Label>
            <WorkspaceTaskPriorityDropdown
              id="create-workspace-priority-dropdown"
              onChange={(option): void => {
                dispatch({
                  type: ActionTypes.UpdateTask,
                  payload: {
                    task: {
                      ...task,
                      taskPriority: option.value as TaskPriorities
                    }
                  }
                });
              }}
              value={taskPriority || null}
            />
          </div>
        </div>
        <div className="workspace-dialog__details--row">
          <StandardTextField
            className="workspace-dialog__details--filetype"
            id="create-workspace-filetype"
            label={t`File Type`}
            onChange={(e: InputChangeEvent): void => {
              dispatch({
                type: ActionTypes.UpdateTask,
                payload: {
                  task: {
                    ...task,
                    filetype: e.target.value
                  }
                }
              });
            }}
            placeholder={''}
            value={filetype || ''}
          />
          <StandardTextField
            className="workspace-dialog__details--dimensions"
            id="create-workspace-dimensions"
            label={t`Size or Dimensions`}
            onChange={(e: InputChangeEvent): void => {
              dispatch({
                type: ActionTypes.UpdateTask,
                payload: {
                  task: {
                    ...task,
                    dimensions: e.target.value
                  }
                }
              });
            }}
            placeholder=""
            value={dimensions || ''}
          />
        </div>
        <div className="workspace-dialog__details--row">
          <div className="workspace-dialog__details--status">
            <Label
              attributes={{
                htmlFor: 'create-workspace-status'
              }}
            >
              <Trans>Status</Trans>
            </Label>
            <WorkspaceTaskStatusDropdown
              className="workspace-dialog__details--status"
              id="create-workspace-status-dropdown"
              onChange={(option): void => {
                dispatch({
                  type: ActionTypes.UpdateTask,
                  payload: {
                    task: {
                      ...task,
                      taskStatus: option.value as TaskStatuses
                    }
                  }
                });
              }}
              value={taskStatus || null}
            />
          </div>
        </div>
        <div className="workspace-dialog__details--row">
          <StandardTextField
            className="workspace-dialog__details--description"
            error={
              state.errorState.includes(ValidationErrors.DescriptionMaxLengthExceeded)
                ? t`5000 character limit exceeded`
                : undefined
            }
            id="create-workspace-description"
            label={t`Description`}
            multiLine
            onChange={(e: InputChangeEvent): void => {
              dispatch({
                type: ActionTypes.UpdateTask,
                payload: {
                  task: {
                    ...task,
                    taskDescription: e.target.value
                  }
                }
              });
            }}
            placeholder={t`Add a detailed description`}
            value={taskDescription || ''}
          />
        </div>
      </div>
      <WorkspaceUserSection />
    </div>
  );
};
