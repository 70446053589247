import { StandardTable, StandardTableColumn } from '@brandfolder/react';
import { Result } from '@uppy/transloadit';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { getPollingDownloadMessage, fetchWithPolling, FetchWithPollingResponse } from '@api/ApiHelper';
import { CsvActionableTypesEnum, CsvContextsEnum } from '@api/v4/private/resources/csv';
import { CsvDownloadGetResponse, getCsvDownload, postCsvDownload } from '@api/v4/private/resources/csv/downloads';
import { CsvUploadFailureClient, getCsvUploadFailures } from '@api/v4/private/resources/csv/failures';
import { createCsvUpload, CsvUploadClient, getCsvUploads } from '@api/v4/private/resources/csv/uploads';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import { Locale, findCurrentLanguageInMap } from '@components/common/language_menu/languagesMap';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { PrimaryButton, TextButton } from '@components/library/button';
import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { delay } from '@components/library/utils';
import { localizeNumber } from '@helpers/locale';
import { localizeDateTime } from '@helpers/localize';

import { defaultPagination, mapMetaToPagination, Pagination } from '../shared';

import {
  getFirstAvailableWhitelistedLocale,
  mapCsvUploadFailureServerToClient,
  mapCsvUploadServerToClient
} from './helpers';

import classes from './styles/translations.module.scss';

interface CsvUploadListOptions extends ListOption {
  actionableType: CsvActionableTypesEnum;
  context: CsvContextsEnum;
}

const translationOptions = (): CsvUploadListOptions[] => [
  {
    actionableType: CsvActionableTypesEnum.Section,
    context: CsvContextsEnum.Translations,
    label: bfTranslate('Sections'),
    value: CsvActionableTypesEnum.Section
  },
  {
    actionableType: CsvActionableTypesEnum.Label,
    context: CsvContextsEnum.Translations,
    label: bfTranslate('Labels'),
    value: CsvActionableTypesEnum.Label
  }
];

const statuses = {
  completed: bfTranslate('Completed'),
  failed: bfTranslate('Failed'),
  null: bfTranslate('Unknown'),
  processing: bfTranslate('Processing'),
  success: bfTranslate('Success')
};

const csvUploadsColumns = (): StandardTableColumn[] => [
  {
    children: bfTranslate('Upload Date'),
    rowKey: 'createdAt'
  },
  {
    children: bfTranslate('Uploaded By'),
    ellipsis: true,
    maxWidth: 300,
    rowKey: 'creatorEmail'
  },
  {
    ellipsis: true,
    children: bfTranslate('Filename'),
    maxWidth: 300,
    rowKey: 'filename'
  },
  {
    centered: true,
    children: bfTranslate('Succeeded'),
    headingTitle: bfTranslate('The number of successfully processed rows'),
    rowKey: 'validationSuccessCount'
  },
  {
    cellTitle: bfTranslate('Click to view failure details'),
    centered: true,
    children: bfTranslate('Invalid'),
    headingTitle: bfTranslate('The number of validation failures'),
    rowKey: 'validationFailureCount'
  },
  {
    centered: true,
    children: bfTranslate('Errors'),
    headingTitle: bfTranslate('The number of job errors'),
    rowKey: 'failedCount'
  },
  {
    centered: true,
    children: bfTranslate('Total'),
    headingTitle: bfTranslate('The total number of CSV rows processed'),
    rowKey: 'totalCount'
  },
  {
    children: bfTranslate('Status'),
    rowKey: 'status'
  }
];

const csvUploadFailuresColumns: StandardTableColumn[] = [
  {
    children: bfTranslate('Key'),
    rowKey: 'actionableKey',
    width: '25%'
  },
  {
    children: bfTranslate('Notes'),
    rowKey: 'failureNotes',
    width: '75%'
  }
];

export const UserGeneratedTranslations: FunctionComponent = () => {
  const defaultCsvDownloadText: string = bfTranslate('Download Translations CSV');
  const ugtLocaleDefault = BFG.locales.ugtLocaleDefault as Locale;

  const [csvDownloadAttempt, setCsvDownloadAttempt] = useState(-1);
  const [csvDownloadUrl, setCsvDownloadUrl] = useState('');
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);
  const [csvDownloadText, setCsvDownloadText] = useState(defaultCsvDownloadText);
  const [csvUploadFailures, setCsvUploadFailures] = useState<CsvUploadFailureClient[]>([]);
  const [csvUploadFailuresKey, setCsvUploadFailuresKey] = useState('');
  const [csvUploadFailuresLoading, setCsvUploadFailuresLoading] = useState(false);
  const [csvUploadFailuresPagination, setCsvUploadFailuresPagination] = useState(defaultPagination);
  const [csvUploads, setCsvUploads] = useState<CsvUploadClient[]>([]);
  const [csvUploadsLoading, setCsvUploadsLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [locale, setLocale] = useState<Locale>(ugtLocaleDefault !== Locale.English ? ugtLocaleDefault : getFirstAvailableWhitelistedLocale() || Locale.English);
  const [showCsvUploader, setShowCsvUploader] = useState(true);
  const [translationSelection, setTranslationSelection] = useState<CsvUploadListOptions>(translationOptions()[0]);

  const fetchCsvUploads = async (): Promise<void> => {
    try {
      setCsvUploadsLoading(true);
      const response = await getCsvUploads({
        actionableType: translationSelection.actionableType,
        context: translationSelection.context,
        locale,
        resourceType: BFG.resource.type as 'brandfolder' | 'collection'
      });
      const mapped = response.data.map((csvUpload) => mapCsvUploadServerToClient(csvUpload.attributes, csvUpload.id));
      setCsvUploads(mapped);
    } catch (err) {
      Notify.create({
        title: bfTranslate('Error loading translation CSV uploads. Please refresh the page to try again.'),
        type: 'error'
      });
    } finally {
      setCsvUploadsLoading(false);
    }
  };

  useEffect(() => {
    fetchCsvUploads();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationSelection]);

  const postCsvUpload = async (): Promise<void> => {
    try {
      await createCsvUpload({
        actionableType: translationSelection.actionableType,
        context: translationSelection.context,
        locale,
        uploadUrl: csvUrl
      });

      Notify.create({
        title: bfTranslate('CSV Uploaded'),
        body: bfTranslate('It may take a few moments for your changes to be reflected.'),
        type: 'success'
      });

      // give the job a couple of seconds before refreshing the list
      await delay(2000);

      fetchCsvUploads();
    } catch (err) {
      Notify.create({
        title: bfTranslate('Error uploading your CSV. Please try again.'),
        type: 'error'
      });
    } finally {
      setCsvUrl('');
      setShowCsvUploader(false);
    }
  };

  useEffect(() => {
    if (csvUrl) {
      postCsvUpload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvUrl]);

  const fetchCsvUploadFailures = async (): Promise<void> => {
    try {
      setCsvUploadFailuresLoading(true);

      const response = await getCsvUploadFailures({
        csvUploadKey: csvUploadFailuresKey,
        page: csvUploadFailuresPagination.currentPage,
        per: csvUploadFailuresPagination.per,
        resourceType: BFG.resource.type as 'brandfolder' | 'collection',
      });

      setCsvUploadFailuresPagination({
        ...csvUploadFailuresPagination,
        ...mapMetaToPagination(response.meta)
      });

      const mapped = response.data.map((csvUploadFailure) => mapCsvUploadFailureServerToClient(csvUploadFailure.attributes, csvUploadFailure.id));
      setCsvUploadFailures(mapped);
    } catch (err) {
      Notify.create({
        title: bfTranslate('Error loading CSV upload failures. Please try again.'),
        type: 'error'
      });
    } finally {
      setCsvUploadFailuresLoading(false);
    }
  };

  useEffect(() => {
    if (csvUploadFailuresKey) {
      fetchCsvUploadFailures();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvUploadFailuresKey, csvUploadFailuresPagination.currentPage, csvUploadFailuresPagination.per]);

  const renderCsvFailuresDialog = (validationFailureCount: number, id: string | undefined): ReactNode => {
    if (validationFailureCount > 0 && id) {
      const rows = csvUploadFailures.map((csvUploadFailure) => {
        const { actionableKey, failureNotes } = csvUploadFailure;
        return {
          actionableKey: actionableKey || bfTranslate('No Key'),
          failureNotes: failureNotes.join(', ')
        };
      });
      return (
        <>
          <TextButton onClick={(): void => setCsvUploadFailuresKey(id)}>
            {localizeNumber(validationFailureCount)}
          </TextButton>
          <StandardDialog
            id="csv-upload-failures-dialog"
            onClose={(): void => setCsvUploadFailuresPagination(defaultPagination)}
            open={csvUploadFailuresKey === id}
            setOpen={(): void => setCsvUploadFailuresKey('')}
            showFooter={false}
            size={DialogSizes.Small}
            title={bfTranslate('CSV Upload Failures')}
          >
            <StandardTable
              caption={bfTranslate('A list of CSV uploads failures.')}
              columns={csvUploadFailuresColumns}
              emptyContent={bfTranslate('No CSV upload failures found')}
              footer
              footerContent={(
                <Pagination
                  {...csvUploadFailuresPagination}
                  onPageChange={(currentPage): void => setCsvUploadFailuresPagination({ ...csvUploadFailuresPagination, currentPage })}
                  onPerChange={(per): void => setCsvUploadFailuresPagination({ ...csvUploadFailuresPagination, per })}
                />
              )}
              id="csv-upload-failures-list"
              loaderLabel={bfTranslate('Loading')}
              loading={csvUploadFailuresLoading}
              rows={rows}
              scrollX={false}
            />
          </StandardDialog>
        </>
      );
    }
    return <span className={classes.p}>{localizeNumber(validationFailureCount)}</span>;
  };

  const validateCsvUploadUrlResponse = (response: CsvDownloadGetResponse): boolean => !!response?.data?.attributes?.path;

  const downloadFile = (href: string): void => {
    const element = document.createElement('a');
    element.setAttribute('download', 'true');
    element.setAttribute('href', href);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    if (csvDownloadUrl) {
      downloadFile(csvDownloadUrl);
      setCsvDownloadUrl('');
    }
  }, [csvDownloadUrl]);

  // this sets the download button text while polling is happening
  useEffect(() => {
    if (csvDownloadAttempt === -1) {
      // back to the default "Download Translations CSV" text
      setCsvDownloadText(defaultCsvDownloadText);
    } else {
      // else update the download button text dynamically
      setCsvDownloadText(getPollingDownloadMessage(csvDownloadAttempt));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvDownloadAttempt]);

  useEffect(() => {
    if (!showCsvUploader) {
      // this is needed to reinitalize the <UppyUploader> component below
      // after a successful postCsvUpload
      setShowCsvUploader(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCsvUploader]);

  const currentLocale = findCurrentLanguageInMap(locale);
  // the base translation is english, so we can translate english to english
  const hasEnglishSelected = locale === Locale.English;
  const uppyId = 'uppy-upload-trigger';

  const rows = csvUploads.map((csvUpload) => {
    const {
      createdAt,
      creatorEmail,
      failedCount,
      filename,
      id,
      status,
      totalCount,
      validationFailureCount,
      validationSuccessCount
    } = csvUpload;

    return {
      createdAt: localizeDateTime(createdAt),
      creatorEmail,
      failedCount: localizeNumber(failedCount),
      filename,
      status: statuses[status],
      totalCount: localizeNumber(totalCount),
      validationFailureCount: renderCsvFailuresDialog(validationFailureCount, id),
      validationSuccessCount: localizeNumber(validationSuccessCount)
    };
  });

  return (
    <I18nProviderWrapper>
      <div className={classes.translations}>
        <h2>{bfTranslate('Translations')}</h2>
        <hr />
        <section>
          <p className={classes.step}><strong>{bfTranslate('Step 1')}:</strong> {bfTranslate('Download a CSV template of the current translations for the chosen language.')}</p>
          <div className={classes.dropdownGroup}>
            <ListDropdown
              className={classes.listDropdown}
              onChange={(listOption: CsvUploadListOptions): void => setTranslationSelection(listOption)}
              openOnClick
              openOnHover={false}
              options={translationOptions()}
              value={translationSelection}
            />
            <LanguageMenuDropdown
              copy={bfTranslate('Select a locale below to manage translations.')}
              defaultLocale={ugtLocaleDefault}
              onLocaleChange={(selectedLocale): boolean => {
                if (selectedLocale) {
                  setLocale(selectedLocale);
                  return true;
                }
                return false;
              }}
              openOnHover={false}
              selectedLocale={locale}
              showSelectedLocaleName
              wrapperClassnames={classes.languageMenuDropdown}
            />
            <PrimaryButton
              className={classes.primaryButton}
              disabled={csvDownloadLoading || hasEnglishSelected}
              id="download-translations-csv"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async (): Promise<void> => {
                try {
                  setCsvDownloadLoading(true);

                  const resourceType = BFG.resource.type as 'brandfolder' | 'collection';
                  // downloading a csv is a 2 step process
                  // 1. POST to kick off the job to generate the csv download
                  const job = await postCsvDownload({
                    actionableType: translationSelection.actionableType,
                    context: translationSelection.context,
                    locale,
                    resourceType
                  });
                  // 2. GET via polling to see if the csv download url is ready
                  const response = await fetchWithPolling<CsvDownloadGetResponse>(
                    () => getCsvDownload({
                      downloadKey: job.data.id,
                      resourceType
                    }),
                    validateCsvUploadUrlResponse,
                    5 * 1000, // poll every 5 seconds
                    60 * 1000, // poll for 60 seconds
                    (attempt) => setCsvDownloadAttempt(attempt)
                  );

                  setCsvDownloadUrl(response.polledData?.data?.attributes?.path || '');
                } catch (err) {
                  const error = err as FetchWithPollingResponse<CsvDownloadGetResponse>;
                  if (error.timeout) {
                    Notify.create({
                      title: bfTranslate('Your download is taking a long time to process. Your download will appear in Notifications when it is ready.'),
                      type: 'success'
                    });
                  } else {
                    Notify.create({
                      title: bfTranslate('Error downloading translation CSV. Please try again.'),
                      type: 'error'
                    });
                  }
                } finally {
                  setCsvDownloadAttempt(-1);
                  setCsvDownloadLoading(false);
                }
              }}
            >
              {csvDownloadText}
            </PrimaryButton>
          </div>
        </section>
        <section>
          <p className={classes.step}><strong>{bfTranslate('Step 2')}:</strong> {bfTranslate('Add your translations to the template and upload the CSV file.')}</p>
          {showCsvUploader && (
            <>
              <div className={classes.upload} id={uppyId}>
                <span className="bff-plus" />
                <p>{bfTranslate('Upload Translations CSV')}: {currentLocale?.language} ({currentLocale?.ISOCode}) {translationSelection.label}</p>
              </div>
              <UppyUploader
                button
                closeOnComplete
                handleUpload={(files: Result[]): void => {
                  if (files && files[0]) {
                    setCsvUrl(files[0].url);
                  } else {
                    Notify.create({
                      title: bfTranslate('Error uploading your CSV. Please try again.'),
                      type: 'error'
                    });
                  }
                }}
                restrictions={{
                  // 100mb: file size bumped up for getty
                  maxFileSize: 100000000,
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['.csv']
                }}
                template="file_ingest"
                trigger={`#${uppyId}`}
                uniqIdentifier={`translations-uppy-${BFG.resource.key}`}
              />
            </>
          )}
        </section>
        <hr />
        <section>
          <StandardTable
            caption={bfTranslate('A list of CSV uploads for the locale and translation type selected.')}
            columns={csvUploadsColumns()}
            emptyContent={bfTranslate('No CSV uploads found')}
            id="csv-uploads-list"
            loaderLabel={bfTranslate('Loading')}
            loading={csvUploadsLoading}
            rows={rows}
          />
        </section>
      </div>
    </I18nProviderWrapper>
  );
};
