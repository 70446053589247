import classnames from 'classnames';
import React, { FunctionComponent, ImgHTMLAttributes, useEffect, useState } from 'react';

import { BFLoader } from '@components/common/loader/main';
import { useFilestackCredentials } from '@components/common/custom_hooks/useFilestackCredentials';
import { getIsFilestackUrl, getIsFilestackUrlAndSigned } from '@helpers/filestack';

import classes from './styles/filestack-preview-image.module.scss';
import { FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';

export interface FilestackPreviewImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  /** Intentionally required */
  src: string;
  credentialsOptions?: FilestackCredentialsPostOptions
  loaderClassName?: string;
  resourceKey?: string;
  resourceType?: string;
  uploadLinkKey?: string;
}

/**
 * Render an image with a loading animation while /fs_creds is fetched for unsigned Filestack URL's
 * @returns ReactElement
 */
export const FilestackPreviewImage: FunctionComponent<FilestackPreviewImageProps> = (props) => {
  const { loaderClassName, resourceKey, resourceType, src, uploadLinkKey, credentialsOptions,...otherProps } = props;

  const { error, fetch, filestackCredentials } = useFilestackCredentials({
    credentialsOptions,
    resourceKey,
    resourceType,
    uploadLinkKey
  });

  const [source, setSource] = useState(
    (!getIsFilestackUrl(src) || getIsFilestackUrlAndSigned(src))
      ? src
      : ''
  );

  const loading = source === '';

  useEffect(() => {
    if (!source) {
      fetch();
    }
  }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      // we tried, they get a broken image
      setSource(src);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filestackCredentials) {
      setSource(`${src}?policy=${filestackCredentials.policy}&signature=${filestackCredentials.signature}`);
    }
  }, [filestackCredentials]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <BFLoader
        className={classnames({
          [classes['filestack-preview-image__loader']]: true,
          [loaderClassName]: !!loaderClassName
        })}
      />
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img {...otherProps} src={source} />
  );
};
