/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { withBreakpoints } from 'react-breakpoints';

import ListItem from '@components/common/search_dropdown/list_item';

import './styles/search_dropdown.scss';

/* props
  searchIndex: Data we are searching against
  searchPlaceholder: placeholder text for search input
  searchFunc: What method we are using to search searchIndex, defaults to simple substring match. Must take 2 args, 1st = collection we are searching aginst, 2nd = search term
  maxResults: max number of results to be displayed
*/

const SearchDropdown = ({ rootDomain, searchIndex, searchPlaceholder, searchFunc, maxResults = 6, showAllText }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(searchIndex);
  const input = useRef(null);

  const updateSearch = (inputValue) => {
    setSearchTerm(inputValue);
    setSearchResults(searchFunc(searchIndex, inputValue.trim()));
  };

  useEffect(() => {
    input.current.focus();
  }, []);

  useEffect(() => {
    setSearchResults(searchFunc(searchIndex, searchTerm.trim()));
  }, [searchIndex]);

  return (
    <div className="search-dropdown" data-testid="search-dropdown">
      <menu>
        <div className="search-dropdown__search" data-testid="search-dropdown-input-container">
          <span className="bff-search" />
          <input
            ref={input}
            data-testid="search-dropdown-input"
            onChange={(event) => updateSearch(event.target.value)}
            placeholder={searchPlaceholder}
            value={searchTerm}
          />
        </div>
        {
          searchResults.length > 0
            ? (
              <>
                <ul className="bf-scroll-element">
                  {searchResults.slice(0, maxResults).map((item) => {
                    return (
                      <ListItem
                        key={`${item.id || item.slug}-${item.organization}`}
                        cname={item.cname}
                        name={item.name}
                        publicPrivacy={item.public}
                        rootDomain={rootDomain}
                        slug={item.slug}
                        stealthPrivacy={item.stealth}
                        type={item.type}
                        v2={item.v2}
                      />
                    )
                  })}
                </ul>
                {searchTerm.trim().length === 0 && (
                  <div className="search-dropdown__item search-dropdown__item--view-all" data-testid="search-dropdown-view-all">
                    <a href="/organizations">{showAllText}</a>
                  </div>
                )}
              </>
            ) : (
              <div className="search-dropdown__no-results" data-testid="search-dropdown-no-results">
                <h5><Trans>No Results</Trans></h5>
              </div>
            )
        }
      </menu>
    </div>
  );
};

SearchDropdown.propTypes = {
  rootDomain: PropTypes.string.isRequired,
  searchIndex: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchFunc: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  showAllText: PropTypes.string.isRequired,
  maxResults: PropTypes.number
};

SearchDropdown.defaultProps = {
  maxResults: 6
};

export default withBreakpoints(SearchDropdown);
