/* eslint-disable react/require-default-props */
import CropperJSWrapper from 'cropperjs-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import CropperJSWrapper from '@components/common/cropper/wrapper'; // local version

import { fetchJson, useFetch } from '@api/ApiHelper';
import { get as getAsset } from '@api/v4/assets';
import { get as getAttachment } from '@api/v4/attachments';
import { getConvertedAttachment } from "@api/v4/private/convertedAttachments";
import { DEFAULT_FILESTACK_POST_OPTIONS } from '@api/v4/private/resources/fs_creds';
import { get as getPresets } from '@api/v4/resources/conversion_presets';
import CropDimensions from '@components/advanced_download/crop_dimensions';
import CropperFooter from '@components/advanced_download/cropper_footer';
import Header from '@components/advanced_download/header';
import Options from '@components/advanced_download/options/main';
import renderModal from '@components/show_page/modals/renderModal';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { getFilestackCredentials } from '@helpers/filestack';

import {
  defaultPresets,
  defaultCroppedData,
  processExtensions,
  processImageDimensions,
  templateCroppingPreset,
} from './helper';

import '@components/advanced_download/styles/main.scss';

const AdvancedDownloadModal = ({
  attachmentKey,
  closeModal,
  editable,
  goBackToAsset,
  handlePrintuiImageSubmission,
  imageDetails,
  lockedPreset,
  printuiAssetKey,
  resetCropper,
  resetShowModal,
  resource,
}) => {
  const [asset, setAsset] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [convertedAttachment, setConvertedAttachment] = useState(null);
  const [image, setImage] = useState();

  // default of 1 is the case where the displayed and cropped image are the same (vs. displaying a thumbnail and cropping a larger image)
  const [imageToThumbnailRatio, setImageToThumbnailRatio] = useState(1);

  const [availablePresets, setAvailablePresets] = useState([]);
  const [canvasData, setCanvasData] = useState(null);
  const [containerData, setContainerData] = useState(null);
  const [croppedData, setCroppedData] = useState(defaultCroppedData);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [zoomRatio, setZoomRatio] = useState(1);

  const [getter, setGetter] = useState({ action: '', func: () => {} });
  const [setter, setSetter] = useState({ action: '', data: {} });
  const [aspectRatio, setAspectRatio] = useState(null);
  const [fixedDimensions, setFixedDimensions] = useState(false);
  const [filetypes, setFiletypes] = useState(['']);
  const [selectedFiletype, setSelectedFiletype] = useState('');
  const [restrictOptionsType, setRestrictOptionsType] = useState(null);

  // NOTE: This list should be in sync with ConversionService::EAGER_RASTERIZATION_EXTENSIONS
  const EAGERLY_RASTERIZE_EXTENSIONS = ['webp', 'pdf', 'ai', 'eps', 'svg', 'psd', 'dng', 'crw', 'cr2', 'cr3', 'raf', 'nrw', 'orf', 'raw', 'rw2', 'arw', 'srf', 'sr2', 'srw', 'tiff', 'tif', 'bmp'];

  const { response: brandfolderResponse } = useFetch({
    url: `/api/v4/brandfolders/${BFG.brandfolder_key}`,
    fields: 'embed_asset_setting'
  });
  const { response: collectionResponse } = useFetch({
    url: `/api/v4/collections/${BFG.resource.key}`,
    fetchOnMount: BFG.resource.type === 'collection',
    fields: 'embed_asset_setting'
  });

  const setupLockedPreset = () => {
    if (lockedPreset?.name === "Template Cropping") {
      const templatePreset = templateCroppingPreset(lockedPreset);
      setAvailablePresets([templatePreset]);
      setSelectedPreset({ label: templatePreset.name, value: templatePreset });
      setRestrictOptionsType(['custom']);
      setAspectRatio(templatePreset.aspectRatio);
    }
  };

  const setupNonConvertedImageURL = (view_thumbnail_retina, url) => {
    const imageUrl = view_thumbnail_retina || url || '';
    setImage({ url: imageUrl });
  }

  const usingConvertedAttachment = async (attachmentKey) => {
    const response = await getConvertedAttachment({attachmentKey});
    const { bits_per_sample, url } = response.attributes;

    if (bits_per_sample?.substring(0, bits_per_sample.indexOf(' ')) === '16') {
      setConvertedAttachment(response);
      const imageUrl = url || '';
      setImage({ url: imageUrl });
    } else {
      const imageUrl = view_thumbnail_retina || url || '';
      setImage({ url: imageUrl });
    }
  }

  const setupImageUrl = async () => {
    if (attachmentKey) {
      const attachmentOptions = { params: { fields: 'view_thumbnail_retina,updated_at,extension,conversion_options,cropping_image_height,cropping_image_width,cdn_url' } };
      const response = await getAttachment(attachmentKey, attachmentOptions);

      setAttachment(response);
      const { url, view_thumbnail_retina, extension } = response.attributes;

      if (EAGERLY_RASTERIZE_EXTENSIONS.includes(extension.toLowerCase())) {
        try { await usingConvertedAttachment(attachmentKey); }
        catch { setupNonConvertedImageURL(view_thumbnail_retina, url); }
      } else {
        setupNonConvertedImageURL(view_thumbnail_retina, url);
      }
    }

    if (imageDetails?.url) {
      const creds = getFilestackCredentials();
      const extension = processExtensions(imageDetails.mimetype)[0];

      if (creds) {
        setImage({
          extension,
          url: `${imageDetails.url}?policy=${creds.policy}&signature=${creds.signature}`
        });
      } else {
        const filestackCredentials = await fetchJson({
          body: {
            ...DEFAULT_FILESTACK_POST_OPTIONS,
            ...printuiAssetKey && { printui_asset_key: printuiAssetKey }
          },
          method: 'POST',
          url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/fs_creds`
        });
        setImage({
          extension,
          url: `${imageDetails.url}?policy=${filestackCredentials.data.policy}&signature=${filestackCredentials.data.signature}`
        });
      }
    }
  };

  const fetchAsset = () => {
    if (goBackToAsset) {
      const assetOptions = { params: { include: 'section', fields: 'availability' } };
      getAsset(goBackToAsset, assetOptions).then(setAsset);
    }
  };

  const fetchPresets = () => {
    if (resource) {
      const { type: resourceType, key: resourceKey } = resource;
      getPresets({ resourceType, resourceKey })
        .then((response) => {
          if (response?.length) {
            setAvailablePresets([...response, ...defaultPresets()]);
          } else {
            setAvailablePresets([...defaultPresets()]);
          }
        })
        .catch((err) => {
          console.log(err);
          setAvailablePresets([...defaultPresets()]);
        });
    }
  };

  const setupImage = () => {
    if (attachment) {
      const { fullSizeHeight, fullSizeWidth } = processImageDimensions(convertedAttachment ? convertedAttachment : attachment, canvasData);
      const {
        conversion_options,
        extension,
        filename,
        size,
        updated_at
      } = attachment.attributes;

      setImage((prevState) => ({
        ...prevState,
        extension,
        height: fullSizeHeight,
        filename,
        size,
        updated_at,
        width: fullSizeWidth,
      }));

      // update imageToThumbnailRatio if a thumbnail is displayed instead of full-sized image
      if (fullSizeWidth !== canvasData.naturalWidth) {
        setImageToThumbnailRatio(fullSizeWidth / canvasData.naturalWidth);
      }

      const uniqueConversionOptions = processExtensions(extension, conversion_options);
      // Vector file types cannot be used as an output option when cropping.
      const nonVectorUniqueConverisonOptions = uniqueConversionOptions.filter((option) => !BFG.vectorFileTypes.includes(option.toLowerCase()));

      setFiletypes(nonVectorUniqueConverisonOptions);
      setSelectedFiletype(nonVectorUniqueConverisonOptions[nonVectorUniqueConverisonOptions.length - 1].toUpperCase()); // original filetype is last in uniqueConversionOptions
    }

    if (imageDetails && !attachment) { // <-- prioritize attachment
      const extension = processExtensions(imageDetails.mimetype);
      // Vector file types cannot be used as an output option when cropping.
      let fileType = [...extension].filter((option) => !BFG.vectorFileTypes.includes(option.toLowerCase()));
      fileType = fileType.length === 0 ? ['PNG'] : fileType;

      setImage((prevState) => ({
        ...imageDetails,
        url: prevState.url || imageDetails.url,
        extension: extension[0],
        height: canvasData.height,
        width: canvasData.width,
      }));
      setFiletypes(fileType);
      setSelectedFiletype(fileType[0]);
    }
  };

  useEffect(() => {
    sendAction(TrackedAction.Cropping);
    setupLockedPreset();
    setupImageUrl();
    fetchAsset(); // get assets if 'goBackToAsset' prop is present, allows for "Back" button vs. just "Close"
    fetchPresets(); // gets presets is 'resource' prop is present

    return () => {
      resetCropper && resetCropper();
    }
  }, []);

  useEffect(() => {
    if (canvasData) {
      setZoomRatio(canvasData.width / canvasData.naturalWidth);
    }

    // update image only once, after canvasData has loaded
    if (canvasData && !image.height) {
      setupImage();
    }
  }, [canvasData]);

  useEffect(() => {
    setSetter({ action: 'setAspectRatio', data: { aspectRatio } });
  }, [aspectRatio]);

  const goBack = () => {
    if (goBackToAsset) {
      const sectionKey = asset.relationships.section.data.id;
      const urlBaseString = `${BFG.brandfolder_slug}/sections/${sectionKey}/ui/${asset.type}/${asset.id}`;
      let urlParamsString = collectionResponse?.data.attributes.slug
        ? `?collection_slug=${collectionResponse.data.attributes.slug}`
        : '';
      if (editable) {
        const editString = 'edit=true';
        urlParamsString += urlParamsString.length > 0 ? `&${editString}` : `?${editString}`;
      }
      const url = urlBaseString + urlParamsString;

      resetShowModal();
      BF.fullModal = true;
      BF.dialog.render(encodeURIComponent(asset.attributes.name), url, BF.handlerGroups.showAsset);
    } else if (lockedPreset?.name === "Template Cropping") {
      closeModal();
    } else {
      closeModal();
    }
  };

  const updateCanvasData = (newCanvasData) => {
    // only update if canvas moves
    if (newCanvasData?.left !== canvasData?.left || newCanvasData?.top !== canvasData?.top) {
      setCanvasData(newCanvasData);
    }
  };

  const initializeCropper = () => {
    setGetter({ action: 'getCanvasData', func: setCanvasData });
    setGetter({ action: 'getContainerData', func: setContainerData });
    setGetter({ action: 'getData', func: setCroppedData });
  };

  const onZoom = () => {
    setGetter({ action: 'getCanvasData', func: setCanvasData });
  };

  const onCrop = (cropEvent) => {
    setGetter({ action: 'getCanvasData', func: updateCanvasData });
    setCroppedData(cropEvent.detail);
  };

  return (
    <section className="advanced-download-component" id="advanced-download-component">
      <Header
        closeModal={closeModal}
        goBack={goBack}
        goBackToAsset={goBackToAsset}
        image={image}
      />
      <div className="cropper-container">
        <CropperJSWrapper
          aspectRatio={lockedPreset?.aspectRatio ? lockedPreset.aspectRatio : NaN}
          checkCrossOrigin={false}
          getter={getter}
          imageUrl={image?.url || ''}
          onCrop={onCrop}
          onReady={initializeCropper}
          onZoom={onZoom}
          setter={setter}
          viewMode={0}
        />
        <CropDimensions
          canvasData={canvasData}
          containerData={containerData}
          croppedData={croppedData}
          fixedDimensions={fixedDimensions}
          imageToThumbnailRatio={imageToThumbnailRatio}
          selectedPreset={selectedPreset}
          zoomRatio={zoomRatio}
        />
        <CropperFooter
          imageToThumbnailRatio={imageToThumbnailRatio}
          setSetter={setSetter}
          zoomRatio={zoomRatio}
        />
      </div>
      <Options
        aspectRatio={aspectRatio}
        attachmentKey={attachmentKey}
        availablePresets={availablePresets}
        canvasData={canvasData}
        cdnUrl={attachment?.attributes?.cdn_url}
        croppedData={croppedData}
        embedAssetSetting={collectionResponse
          ? collectionResponse.data.attributes.embed_asset_setting
          : brandfolderResponse?.data.attributes.embed_asset_setting
        }
        filetypes={filetypes}
        fixedDimensions={fixedDimensions}
        handlePrintuiImageSubmission={handlePrintuiImageSubmission}
        image={image}
        imageToThumbnailRatio={imageToThumbnailRatio}
        printuiAssetKey={printuiAssetKey}
        published={asset?.attributes.availability === 'published'}
        restrictOptionsType={restrictOptionsType}
        selectedFiletype={selectedFiletype}
        selectedPreset={selectedPreset}
        setAspectRatio={setAspectRatio}
        setFixedDimensions={setFixedDimensions}
        setGetter={setGetter}
        setSelectedFiletype={setSelectedFiletype}
        setSelectedPreset={setSelectedPreset}
        setSetter={setSetter}
        zoomRatio={zoomRatio}
      />
    </section>
  );
};

AdvancedDownloadModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  goBackToAsset: PropTypes.string,
  resource: PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
  }),
  resetCropper: PropTypes.func,
  resetShowModal: PropTypes.func.isRequired,
  lockedPreset: PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    aspect_ratio: PropTypes.number
  }),
  handlePrintuiImageSubmission: PropTypes.func,
  printuiAssetKey: PropTypes.string,
  // imageDetails: {
  //   mimetype: PropTypes.string,
  //   size: PropTypes.number,
  //   url: PropTypes.string,
  // },

  // one of these props must be provided, if both are provided the attachmentKey is used
  attachmentKey: (props, propName, componentName) => {
    if (!props.attachmentKey && !props.imageDetails) {
      return new Error(`One of props 'attachmentKey' or 'imageDetails' was not specified in '${componentName}'.`);
    }

    if (!PropTypes.string) {
      return new Error('invalid attachmentKey prop');
    }

    return null;
  },
  imageDetails: (props, propName, componentName) => {
    if (!props.imageDetails && !props.attachmentKey) {
      return new Error(`One of props 'imageDetails' or 'attachmentKey' was not specified in '${componentName}'.`);
    }

    return null;
  }
};

AdvancedDownloadModal.defaultProps = {
  attachmentKey: null,
  editable: false,
  goBackToAsset: null,
  handlePrintuiImageSubmission: null,
  imageDetails: null,
  lockedPreset: null,
  printuiAssetKey: null,
  resetCropper: null,
  resource: null,
};

const ModalComponent = renderModal(AdvancedDownloadModal, 'advanced-download-modal');
export default ModalComponent;
