import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Asset, AssetTypes } from '@api/v4/assets/assetTypes';
import { Attachment } from '@api/v4/attachments/attachmentTypes';

interface PreviewImageProps {
  assetType: AssetTypes;
  isPreviewRenderError: boolean;
  resource: Attachment | Asset;
  setIsPreviewRenderError: SetStateDispatch<boolean>;
}

const extensionCopyMap = {
  [AssetTypes.People]: 'person',
  [AssetTypes.Press]: 'press',
  [AssetTypes.GenericFile]: 'empty',
};

const isAttachment = (resource: Attachment | Asset): resource is Attachment => !!(resource as Attachment).preview_type;

export const ImagePreview: FunctionComponent<PreviewImageProps> = ({
  assetType,
  resource,
  isPreviewRenderError,
  setIsPreviewRenderError,
}) => {
  let { extension } = resource;
  let srcUrl = '';
  let altCopy = '';
  const isMobile = BFG.currentUser.device === 'mobile-view';

  if (isAttachment(resource)) {
    altCopy = `${resource.filename}`;
    srcUrl = resource.view_thumbnail_retina?.trim() || resource.thumbnail_url?.trim();
    // use smaller image for mobile (assume lower bandwidth)
    srcUrl = isMobile ? resource.thumbnail_url?.trim() : srcUrl;
  } else {
    // Resource is an asset with no attachments
    altCopy = t`No image available`;
    extension = extensionCopyMap[assetType];
  }

  const fallbackUrl = `${BFG.icon_service_url}/assets/types/${extension}.svg`;
  const showFallbackUrl = isPreviewRenderError || !isAttachment(resource);

  return (
    <img
      alt={altCopy}
      className={classnames(
        'image-preview',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'image-preview-error': isPreviewRenderError }
      )}
      data-private
      data-testid="asset-preview--image"
      onError={(): void => setIsPreviewRenderError(true)}
      src={showFallbackUrl ? fallbackUrl : srcUrl}
    />
  );
};
