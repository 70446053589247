import React, {
  useEffect,
  useRef,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
} from 'react';
import classnames from 'classnames';
import { StandardPill } from '@brandfolder/react';

import { ListOption, ListOptionValue } from '@components/library/dropdown';

import styleClasses from '@components/library/dropdown/multiselect/multiselect-anchor-element.module.scss';

interface MultiselectAnchorElementProps {
  isDropdownOpen: boolean;
  onChange: (selectedOptionValue: ListOptionValue | undefined) => void;
  placeholder: string;
  searchable: boolean;
  searchInput: string;
  selectedOptions: ListOption[];
  selectedPillValue: ListOptionValue;
  setSearchInput: SetStateDispatch<string>;
  setSelectedPillValue: SetStateDispatch<ListOptionValue | undefined>;
  anchorElementLabel?: string;
  error?: ReactNode;
  disabled?: boolean;
}

export const MultiselectAnchorElement: FunctionComponent<MultiselectAnchorElementProps> = ({
  anchorElementLabel,
  disabled,
  error,
  isDropdownOpen,
  onChange,
  placeholder,
  searchable,
  searchInput,
  selectedOptions,
  selectedPillValue,
  setSearchInput,
  setSelectedPillValue,
}) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (value: ListOptionValue | undefined, e: SyntheticEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedPillValue((prevState) => (prevState === value ? undefined : value));
  };

  const selectedOptionsAsPills: ReactNode | boolean = selectedOptions.length > 0 && selectedOptions.map((selectedOption) => (
    <StandardPill
      key={`pill-${selectedOption.value}`}
      className={styleClasses['multiselect-dropdown-pill']}
      closeButtonAriaLabel={selectedOption.closeButtonAriaLabel}
      fontSize={14}
      isTabbable={false}
      onClick={isDropdownOpen ? handleClick : undefined}
      onClose={!selectedOption.isDisabled ? onChange : undefined}
      selected={selectedOption.value === selectedPillValue}
      value={selectedOption.value ?? undefined}
    >
      {selectedOption.label}
    </StandardPill>
  ));

  const isPlaceholderHidden: boolean = !!selectedOptionsAsPills || (searchable && isDropdownOpen);
  const isSearchContainerHidden: boolean = !searchable || (!isDropdownOpen && !!selectedOptionsAsPills);

  const renderSearchContainer = searchable && isDropdownOpen && (
    <div
      className={`search-container ${styleClasses['search-container']}`}
      hidden={isSearchContainerHidden}
    >
      {!selectedOptionsAsPills && (
        <span
          className={`bff-search search-icon ${styleClasses['search-icon']}`}
          data-testid="searchable-dropdown-icon"
        />
      )}
      <input
        ref={searchInputRef}
        className={`search-input ${styleClasses['search-input']}`}
        data-testid="searchable-dropdown-input"
        onChange={(e: InputChangeEvent): void => {
          setSearchInput(e.target.value);
          setSelectedPillValue(null);
        }}
        onClick={(e): void => {
          e.stopPropagation();
          setSelectedPillValue(null);
        }}
        value={searchInput}
      />
    </div>
  );

  useEffect(() => {
    if (isDropdownOpen && searchable && searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpen, selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classnames(
        'multiselect-anchor-element',
        styleClasses['multiselect-anchor-element'],
        {
          [styleClasses['placeholder-shown']]: !selectedOptions.length,
          [styleClasses.disabled]: disabled,
          [styleClasses.error]: !!error,
          [styleClasses.open]: isDropdownOpen,
          error: !!error
        },
      )}
    >
      {anchorElementLabel && (
        <span className={classnames(
          'anchor-element-label',
          styleClasses['anchor-element-label']
        )}
        >
          {anchorElementLabel}
        </span>
      )}
      <div
        className={classnames(
          'selected-options',
          styleClasses['selected-options'],
        )}
        hidden={!isPlaceholderHidden}
      >
        {selectedOptionsAsPills}
        {renderSearchContainer}
      </div>
      <span
        className={classnames(
          'placeholder-copy',
          styleClasses['placeholder-copy']
        )}
        hidden={isPlaceholderHidden}
      >
        {placeholder}
      </span>
      <span
        className={`bff-caret-down ${styleClasses['anchor-icon']}`}
        data-testid="dropdown-caret"
      />
    </div>
  );
};
