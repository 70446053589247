import React, { useEffect, useState, FunctionComponent, MutableRefObject } from 'react';

import useDebounce from '@components/common/custom_hooks/useDebounce';
import { StandardSearchField, searchStatus } from '@components/library/search-field';
import { LabelPosition, StandardSwitch } from '@components/library/switch';
import { userHasAdminPrivileges } from '@helpers/share-links';
import { SelectableTableColumn, SelectableTable } from '@components/library/table';
import { PaginationCounts } from '@components/bulk_management/shared/components/PaginationTypes';
import { defaultPagination, defaultPerOptions, mapMetaToPagination, Pagination } from '@components/bulk_management/shared';
import { useFetch } from '@api/ApiHelper';
import { ShareManifestIndexResponse } from '@api/v4/share_manifests/ShareManifestTypes';
import { mapShareManifestServerToClient } from '@components/share_manifests/helpers';
import { localizeDateTime } from '@helpers/localize';
import { TextButton } from '@components/library/button';
import { BFLoader } from '@components/common/loader/main';
import { ShareLinkRow, WindowDimensions } from '@components/show_page/bulk_actions/bulk-share-link-modal/BulkShareLinkModalTypes';

enum PreviewState {
  IsLoading,
  NoShareLinks,
  NoShareLinkSelected,
  ShareLinkSelected
}

const columns = (): SelectableTableColumn[] => [
  {
    heading: bfTranslate('Name'),
    rowKey: 'name',
    width: '45%'
  },
  {
    heading: bfTranslate('Created by'),
    rowKey: 'createdBy',
    maxWidth: '120px',
    width: '25%'
  },
  {
    centered: true,
    heading: bfTranslate('# Assets'),
    rowKey: 'assets',
    minWidth: '90px',
    width: '12%'
  },
  {
    centered: true,
    heading: bfTranslate('Created'),
    rowKey: 'createdDate',
    minWidth: '90px',
    width: '12%'
  },
  {
    heading: '',
    rowKey: 'linkIcon',
    minWidth: '30px',
    width: '6%'
  }
];

const deselectCopy = (): string => bfTranslate('Deselect share link');
const selectCopy = (): string => bfTranslate('Select share link');

interface BodyViewProps {
  open: boolean;
  selectedRow: ShareLinkRow;
  setSelectedRow: SetStateDispatch<ShareLinkRow>;
  windowDimensions: WindowDimensions;
  bodyContainerRef?: MutableRefObject<HTMLDivElement>;
}

export const BodyView: FunctionComponent<BodyViewProps> = ({
  bodyContainerRef,
  open,
  selectedRow,
  setSelectedRow,
  windowDimensions,
}) => {
  const [createdByMe, setCreatedByMe] = useState(false);
  const [disableDebounce, setDisableDebounce] = useState(false);
  const [rows, setRows] = useState<ShareLinkRow[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [shareLinksPagination, setShareLinksPagination] = useState<PaginationCounts>(defaultPagination);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const debouncedSearchedValue = useDebounce({
    delay: 600,
    disableDebounce: disableDebounce || process?.env?.NODE_ENV === 'circle',
    value: searchValue
  });
  const url = `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/share_manifests`;
  const params = {
    ...createdByMe && { 'filter[created_by]': 'me' }, // eslint-disable-line @typescript-eslint/naming-convention
    ...debouncedSearchedValue && { search: debouncedSearchedValue },
    order: 'desc',
    page: shareLinksPagination.currentPage,
    per: shareLinksPagination.per,
    sort_by: 'created_at', // eslint-disable-line @typescript-eslint/naming-convention
  };

  const { error, fetch, loading, response } = useFetch<ShareManifestIndexResponse>({
    fetchOnMount: false,
    params,
    url
  });

  const handleRowSelect = (row: ShareLinkRow): void => {
    const isSelected = row?.id === selectedRow?.id;
    if (isSelected) {
      setRows((prevState) => (prevState.map((r) => ({ ...r, selectableAriaLabel: selectCopy(), selected: false }))));
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  };

  const previewState = (): PreviewState => {
    if (!loading && response && response.data.length === 0) return PreviewState.NoShareLinks;
    if (!loading && response && response.data.length > 0 && !selectedRow) return PreviewState.NoShareLinkSelected;
    if (!loading && !!selectedRow) return PreviewState.ShareLinkSelected;
    return PreviewState.IsLoading;
  };

  const searchStatusOptions = {
    isLoading: loading,
    resultQuantity: rows.length,
    resultsShown: !loading && debouncedSearchedValue.length > 0,
  };

  useEffect(() => {
    let rowsCopy = [...rows];

    if (selectedRow) {
      rowsCopy = rows.map((row) => {
        if (row.id === selectedRow.id) {
          return {
            ...row,
            selectableAriaLabel: deselectCopy(),
            selected: true
          };
        }

        return {
          ...row,
          selectableAriaLabel: selectCopy(),
          selected: false
        };
      });
    }
    setRows(rowsCopy);
  }, [selectedRow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (response) {
      const shareLinkRows: ShareLinkRow[] = response.data.map((shareLinkResponse) => {
        const {
          createdBy,
          createdAt,
          link,
          name,
          numberOfAssets
        } = mapShareManifestServerToClient(shareLinkResponse.attributes);
        const createdAtFormatted = localizeDateTime(createdAt);
        const linkIcon = (
          <a href={link} rel="noopener noreferrer" target="_blank">
            <span aria-label={bfTranslate('Open the Share Link in a new tab')} className="bff-new-tab" role="img" />
          </a>
        );

        let selected = false;
        if (selectedRow) {
          selected = selectedRow.id === shareLinkResponse.id;
        }

        return {
          assets: numberOfAssets,
          createdBy,
          createdDate: createdAtFormatted,
          id: shareLinkResponse.id,
          link,
          linkIcon,
          name,
          selectableAriaLabel: selected ? deselectCopy() : selectCopy(),
          selected
        };
      });

      let newSelectedRow = shareLinkRows.find((shareLinkRow) => !!shareLinkRow.selected);
      if (!newSelectedRow && shareLinkRows.length > 0 && (shareLinkRows.length === 1 || !hasLoadedOnce)) {
        // auto select row if there's only 1 displaying OR this is the first time the modal is loading
        newSelectedRow = shareLinkRows[0];
        newSelectedRow.selected = true;
      }

      if (!hasLoadedOnce) setHasLoadedOnce(true);
      setRows(shareLinkRows);
      setSelectedRow(newSelectedRow || null);
      setShareLinksPagination((prevState) => (mapMetaToPagination(response.meta, prevState.per)));
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disableDebounce) {
      setDisableDebounce(false);
    }
  }, [disableDebounce]);

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [createdByMe, debouncedSearchedValue, open, shareLinksPagination.currentPage, shareLinksPagination.per]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="bulk-share-links__dialog--container-half-1">
        <StandardSearchField
          ariaSearchLabel={bfTranslate('share link search')}
          className="bulk-share-links__dialog--search"
          id="share-link-search"
          label={bfTranslate('Search Share Links')}
          onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
          placeholder={bfTranslate('Enter URL, Name, or Creator')}
          searchStatus={searchStatus(searchStatusOptions)}
          showLabel
          value={searchValue}
        />
        {userHasAdminPrivileges(BFG?.currentUser?.role, BFG?.currentUser?.su) && (
          <div className="bulk-share-links__dialog--toggle">
            <StandardSwitch
              isChecked={createdByMe}
              labelPosition={LabelPosition.Right}
              name="created-by-me-toggle"
              onChange={(): void => setCreatedByMe((prevState) => !prevState)}
            >
              {bfTranslate('Only display Share Links I created')}
            </StandardSwitch>
          </div>
        )}
        <SelectableTable
          caption={bfTranslate('List of Share Links')}
          columns={columns()}
          empty={rows.length === 0 && !error}
          emptyContent={bfTranslate('No Share Links found')}
          error={!!error}
          errorContent={(
            <div>
              <p>{bfTranslate('Error retrieving Share Links.')}</p>
              <TextButton onClick={(): Promise<void> => fetch()}>
                {bfTranslate('Try again')}
              </TextButton>
            </div>
          )}
          id="bulk-share-links__table"
          loading={loading}
          maxHeight="100%"
          onRowSelect={handleRowSelect}
          rows={rows}
          scrollY
          selectable
        />
        <Pagination
          {...shareLinksPagination}
          className="pagination-component"
          onPageChange={(currentPage): void => {
            setDisableDebounce(true);
            setShareLinksPagination({ ...shareLinksPagination, currentPage });
          }}
          onPerChange={(per): void => {
            setDisableDebounce(true);
            setShareLinksPagination({ ...shareLinksPagination, per });
          }}
          overflowParentRef={bodyContainerRef}
          perOptions={defaultPerOptions}
        />
      </div>
      {/** 1024px aligns with 'tablet-large-and-smaller' media query used in styles  */}
      {(windowDimensions?.innerWidth ?? window.innerWidth) > 1024 && (
        <div className="bulk-share-links__dialog--container-half-2">
          <span className="bulk-share-links__dialog--iframe--label">{bfTranslate('Share Link Preview')}</span>
          <div className="bulk-share-links__dialog--iframe-container">
            {{
              [PreviewState.IsLoading]: <BFLoader />,
              [PreviewState.NoShareLinks]: <span className="placeholder-container">{bfTranslate('No Share Links available to preview')}</span>,
              [PreviewState.NoShareLinkSelected]: <span className="placeholder-container">{bfTranslate('Select a Share Link to display its preview')}</span>,
              [PreviewState.ShareLinkSelected]: (
                <iframe
                  className="bulk-share-links__dialog--iframe"
                  height="100%"
                  role="document"
                  src={selectedRow && `${selectedRow.link}?preview=true`}
                  tabIndex={-1}
                  title={selectedRow && selectedRow.name}
                  width="100%"
                />
              ),
            }[previewState()]}
          </div>
        </div>
      )}
    </>
  );
};
