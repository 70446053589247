import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { Attachment } from '@api/v4/attachments/attachmentTypes';

export const AudioPreview: FunctionComponent<Attachment> = ({
  mimetype,
  url,
  view_thumbnail_retina: viewThumbnailRetina
}) => (
  <audio
    controls
    data-testid="asset-preview--audio"
    id="audio-attachment"
    style={{ backgroundImage: `url(${viewThumbnailRetina})` }}
  >
    <source src={url} type={mimetype} />
    <Trans>Your browser does not support the audio element.</Trans>
  </audio>
);
