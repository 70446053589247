import React, {
  useEffect,
  useRef,
  FunctionComponent,
  ReactNode
} from 'react';
import classnames from 'classnames';
import { ActionsOpenDirections, ListOpenDirections, ListOption } from '@components/library/dropdown';
import { FontIcon } from '@components/library/icon';
import { whichCaret } from '@components/library/dropdown/dropdown_helper';

interface ListAnchorElementProps {
  isDropdownOpen: boolean;
  placeholder: string;
  searchable: boolean;
  searchInput: string;
  setSearchInput: SetStateDispatch<string>;
  styleClassNames: { [key: string]: string };
  anchorElementLabel?: string;
  disabled?: boolean;
  error?: ReactNode;
  openDirection?: ActionsOpenDirections | ListOpenDirections;
  selectedOption?: ListOption;
}

export const ListAnchorElement: FunctionComponent<ListAnchorElementProps> = ({
  anchorElementLabel,
  disabled,
  error,
  isDropdownOpen,
  openDirection,
  placeholder,
  searchable,
  searchInput,
  selectedOption,
  setSearchInput,
  styleClassNames,
}) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isDropdownOpen && searchable && searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classnames(
        'anchor-element',
        styleClassNames['anchor-element'],
        {
          [styleClassNames['placeholder-shown']]: !selectedOption,
          [styleClassNames.disabled]: disabled,
          [styleClassNames.error]: !!error,
          [styleClassNames.open]: isDropdownOpen,
          error: !!error
        },
      )}
    >
      {anchorElementLabel && (
        <span className={classnames(
          'anchor-element-label',
          styleClassNames['anchor-element-label']
        )}
        >
          {anchorElementLabel}
        </span>
      )}
      <span
        className={classnames(
          'selected-option',
          styleClassNames['selected-option'],
        )}
        // needed to target with jquery (ick)
        data-value={selectedOption?.value}
        hidden={searchable && isDropdownOpen}
      >
        {selectedOption?.label || placeholder}
      </span>
      {searchable && isDropdownOpen && (
        <div className={`search-container ${styleClassNames['search-container']}`}>
          <span
            className={`bff-search search-icon ${styleClassNames['search-icon']}`}
            data-testid="searchable-dropdown-icon"
          />
          <input
            ref={searchInputRef}
            className={`search-input ${styleClassNames['search-input']}`}
            data-testid="searchable-dropdown-input"
            onChange={(e: InputChangeEvent): void => setSearchInput(e.target.value)}
            onClick={(e): void => e.stopPropagation()}
            value={searchInput}
          />
        </div>
      )}
      <FontIcon
        aria-hidden
        className={styleClassNames['anchor-icon']}
        data-testid="dropdown-caret"
        icon={whichCaret(openDirection)}
      />
    </div>
  );
};
