import { DatePickerPlacements, StandardDatePicker } from '@brandfolder/react';
import React, { useState, FunctionComponent, MutableRefObject, useEffect } from 'react';

import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { ListDropdown, ListOption } from '@components/library/dropdown/index';
import { LabelPosition, StandardSwitch } from '@components/library/switch';
import {
  DueDateFilterLabels,
  DueDateFilters,
  Filters,
  FilterType
} from '@components/show_page/sections/section_search/SearchTypes';
import { WorkspaceTaskPriorityDropdown } from '@components/workspace/WorkspaceTaskPriorityDropdown';
import { WorkspaceTaskStatusDropdown } from '@components/workspace/WorkspaceTaskStatusDropdown';
import {
  getCustomDateRangeArray,
  getCustomDateRangeString,
  getDueDateRangeString
} from '@helpers/filters';
import { getStandardDatePickerLabels } from '@translations';

const dueDateOptions = (): ListOption[] => [
  {
    label: bfTranslate(DueDateFilterLabels.All),
    value: DueDateFilters.All
  },
  {
    label: bfTranslate(DueDateFilterLabels.Within7Days),
    value: DueDateFilters.Within7Days
  },
  {
    label: bfTranslate(DueDateFilterLabels.Within14Days),
    value: DueDateFilters.Within14Days
  },
  {
    label: bfTranslate(DueDateFilterLabels.Within30Days),
    value: DueDateFilters.Within30Days
  },
  {
    label: bfTranslate(DueDateFilterLabels.Custom),
    value: DueDateFilters.Custom
  }
];

interface TaskFilterProps {
  filters: Filters;
  updateFilters: (filterType: FilterType, keyValue: boolean | string) => void;
  advancedFiltersContainerRef?: MutableRefObject<HTMLElement>;
}

export const TaskFilter: FunctionComponent<TaskFilterProps> = (props) => {
  const { advancedFiltersContainerRef, filters, updateFilters } = props;

  const [dueDate, setDueDate] = useState(DueDateFilters.All);

  useEffect(() => {
    // when the user clears filters, we need to reset the local dueDate state as well
    if (dueDate !== DueDateFilters.All && filters.task_due_date === DueDateFilters.All) {
      setDueDate(DueDateFilters.All);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNotAll = dueDate !== DueDateFilters.All && !!filters.task_due_date;
  const [dueDateFrom, dueDateTo] = isNotAll ? getCustomDateRangeArray(filters.task_due_date) : [undefined, undefined];

  return (
    <div className="filter-section tasks">
      <div className="flex-row">
        <StandardSwitch
          id="filter-tasks-assigned-to-me"
          isChecked={!!filters.task_assigned_to}
          labelPosition={LabelPosition.Right}
          onChange={(): void => {
            updateFilters(FilterType.TaskAssignedTo, filters.task_assigned_to ? '' : BFG.currentUser.user_id);
          }}
        >
          {bfTranslate('Only show tasks assigned to me')}
        </StandardSwitch>
      </div>
      <div className="flex-row">
        <p title={bfTranslate('Status')}>{bfTranslate('Status')}</p>
        <WorkspaceTaskStatusDropdown
          id="filter-status"
          onChange={(option): void => {
            if (filters.task_status !== option.value) {
              updateFilters(FilterType.TaskStatus, option.value as TaskStatuses);
            }
          }}
          overflowParentRef={advancedFiltersContainerRef}
          showAllOptions
          value={filters.task_status || ''}
        />
      </div>
      <div className="flex-row">
        <p title={bfTranslate('Priority')}>{bfTranslate('Priority')}</p>
        <WorkspaceTaskPriorityDropdown
          id="filter-priority"
          onChange={(option): void => {
            if (filters.task_priority !== option.value) {
              updateFilters(FilterType.TaskPriority, option.value as TaskPriorities);
            }
          }}
          overflowParentRef={advancedFiltersContainerRef}
          showAllOptions
          value={filters.task_priority || ''}
        />
      </div>
      <div className="flex-row">
        <p title={bfTranslate('Due Date')}>{bfTranslate('Due Date')}</p>
        <ListDropdown
          id="filter-due-date"
          onChange={(option): void => {
            if (dueDate !== option.value) {
              setDueDate(option.value as DueDateFilters);

              if (option.value === DueDateFilters.All) {
                updateFilters(FilterType.TaskDueDate, DueDateFilters.All);
              } else if (option.value === DueDateFilters.Custom) {
                updateFilters(FilterType.TaskDueDate, getDueDateRangeString());
              } else {
                updateFilters(FilterType.TaskDueDate, getDueDateRangeString(option.value as number));
              }
            }
          }}
          options={dueDateOptions()}
          overflowParentRef={advancedFiltersContainerRef}
          value={dueDate}
        />
      </div>
      {dueDate === DueDateFilters.Custom && dueDateFrom && dueDateTo && (
        <div className="flex-row due-date">
          <StandardDatePicker
            defaultSelectedDate={dueDateFrom}
            id="filter-due-date-from"
            labels={getStandardDatePickerLabels({
              label: bfTranslate('From:')
            })}
            locale={determineUGTLocale()}
            onSelection={(date): void => {
              const customDateRangeString = getCustomDateRangeString(date.toISOString(), dueDateTo);

              if (filters.task_due_date !== customDateRangeString) {
                updateFilters(FilterType.TaskDueDate, customDateRangeString);
              }
            }}
            showLabel
            useLayerOptions={{
              placement: DatePickerPlacements.BottomEnd
            }}
          />
          <StandardDatePicker
            defaultSelectedDate={dueDateTo}
            id="filter-due-date-to"
            labels={getStandardDatePickerLabels({
              label: bfTranslate('To:')
            })}
            locale={determineUGTLocale()}
            onSelection={(date): void => {
              const customDateRangeString = getCustomDateRangeString(dueDateFrom, date.toISOString());

              if (filters.task_due_date !== customDateRangeString) {
                updateFilters(FilterType.TaskDueDate, customDateRangeString);
              }
            }}
            showLabel
            useLayerOptions={{
              placement: DatePickerPlacements.BottomEnd
            }}
          />
        </div>
      )}
    </div>
  );
};
