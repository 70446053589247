import { localizeFileSize } from '@brandfolder/utilities';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import download from '@helpers/download';
import { getMomentLocale, localizeNumber } from '@helpers/locale';

const eventDisplayMap = () => ({
  checked_out: { copy: bfTranslate('Checked Out'), icon: 'bff-check-out' }, // status
  checked_in: { copy: bfTranslate('Checked In'), icon: 'bff-check-in' }, // status
  create: { copy: bfTranslate('Created'), icon: 'bff-upload' }, // event
  update: { copy: bfTranslate('Updated'), icon: 'bff-upload' }, // event
});

const TimelineEvent = (props) => {
  const {
    isCurrentVersion,
    isVersionUpdating,
    makeCurrentVersion,
    setActiveTab,
    setActiveVersion,
    users,
    version,
    versionUploading
  } = props;

  const {
    author,
    created_at,
    event,
    id,
    note,
    status,
    type,
    versionIndex,
  } = version;

  const {
    filename,
    height,
    metadata,
    size,
    small_thumbnail,
    url,
    width,
  } = version.version_details;

  const momentLocale = getMomentLocale(BFG.locales.staticSiteLocale);
  const updatedAtTimePassed = moment(created_at).locale(momentLocale).fromNow();
  const checkedOutClass = status === 'checked_out' ? 'checked-out' : '';
  const commentsAnnotationsFeatureEnabled = BFG.context.hasFeature('comments_and_annotations');

  const handleVersionClick = () => {
    setActiveVersion({ type: 'version', id });
    setActiveTab('annotations');
  };

  const versionJSX = (
    <span>
      Version {(`000${versionIndex}`).substr(-3)}
      <span className="details-pipe">|</span>
    </span>
  );

  const dimensionsJSX = (
    <span>
      <span className="details-pipe">|</span>
      {`${localizeNumber(width)} x ${localizeNumber(height)}px`}
    </span>
  );

  const memoryJSX = (
    <span>
      <span className="details-pipe">|</span>
      {localizeFileSize({ locale: determineUGTLocale(), size })}
    </span>
  );

  let user = '';
  if (!author || !users[author]) {
    user = <span>{bfTranslate('unknown')}</span>;
  } else if (BFG.currentUser.user_id === author) {
    user = <span>{bfTranslate('you')}</span>;
  } else if (users[author]?.first_name || users[author]?.last_name) {
    user = <span>{users[author]?.first_name} {users[author]?.last_name}</span>;
  } else {
    user = <a href="mailto:INPUT-EMAIL">{users[author]?.email}</a>;
  }

  const renderEventActions = () => {
    if (event) {
      let versionAction;

      if (isCurrentVersion) {
        versionAction = <h4 className="italic-title">{bfTranslate('Current version')}</h4>;
      } else {
        versionAction = (
          <button
            className="button tertiary update-version-button"
            disabled={versionUploading || isVersionUpdating}
            onClick={() => makeCurrentVersion(id)}
            type="button"
          >
            {bfTranslate('Make current version')}
          </button>
        );
      }

      return (
        <div className="timeline-events__event-actions">
          {versionAction}
          <button
            className="button secondary download-button"
            onClick={() => {
              download(url, { filename, mimeType: metadata?.mime_type || 'image/png' });
            }}
            type="button"
          >
            <span className="bff-download" />
          </button>
        </div>
      );
    }

    // no actions for checkin/checkout
    return <div className="timeline-events__event-actions" />;
  };

  return (
    <div className="timeline-events__event">
      <button
        className={`timeline-events__event-image ${type}`}
        onClick={type === 'version' && commentsAnnotationsFeatureEnabled ? handleVersionClick : undefined}
        type="button"
      >
        <img alt="attachment version thumbnail" data-private src={small_thumbnail} />
      </button>
      <div className={`timeline-events__event-status ${checkedOutClass}`}>
        <span className={`icon ${eventDisplayMap()[event || status].icon}`} />
        <div className="vertical-line" />
      </div>
      <div className="timeline-events__event-details">
        <h4 className="details-summary">
          <span className="action">{eventDisplayMap()[event || status].copy}</span>
          <span className="action-by">{bfTranslate('by')}</span>
          <span className="action-user">{user}</span>
          <span className="time-passed">{updatedAtTimePassed}</span>
        </h4>
        {!!note && <h4 className="note">{`"${note}"`}</h4>}
        <h4 className="details-copy">
          {type === 'version' && versionJSX}
          <span className="details-filename">{filename}</span>
          <span className="details-datetime">
            <span className="details-pipe">|</span>
            {moment(created_at).locale(momentLocale).format('L, LT')}
          </span>
          {!!(width && height) && dimensionsJSX}
          {!!(size) && memoryJSX}
        </h4>
      </div>
      {renderEventActions()}
    </div>
  );
};

export default TimelineEvent;

TimelineEvent.propTypes = {
  isCurrentVersion: PropTypes.bool.isRequired,
  isVersionUpdating: PropTypes.bool.isRequired,
  makeCurrentVersion: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActiveVersion: PropTypes.func.isRequired,
  users: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({
    author: PropTypes.string,
    created_at: PropTypes.string,
    event: PropTypes.oneOf(['create', 'update']),
    id: PropTypes.string,
    note: PropTypes.string,
    status: PropTypes.oneOf(['checked_out', 'checked_in']),
    type: PropTypes.oneOf(['reservation', 'version']),
    version_details: PropTypes.shape({
      filename: PropTypes.string,
      height: PropTypes.number,
      metadata: PropTypes.shape({}),
      size: PropTypes.number,
      small_thumbnail: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number,
    }),
    versionIndex: PropTypes.number,
  }).isRequired,
  versionUploading: PropTypes.bool.isRequired
};
