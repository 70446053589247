import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ListDropdown } from '@components/library/dropdown';

import { CustomFieldValueCombobox } from './CustomFieldValueCombobox';

const CustomFieldsSelector = ({
  advancedFiltersContainerRef,
  customFieldKeys,
  selectedUGTLocale,
  updateCustomFields
}) => {
  const [selectedKey, setSelectedKey] = useState(null);

  const formatKeyOptions = () => (
    customFieldKeys.map((option) => (
      { label: Object.keys(option)[0], value: Object.values(option)[0] }
    ))
  );

  const updateKey = (selection) => {
    if (selectedKey && selectedKey.value === selection.value) { return undefined; }

    setSelectedKey(selection);

    return undefined;
  };

  const updateCustomFieldValues = (value) => {
    updateCustomFields(selectedKey.label, value);
  }

  return (
    <>
      <label className="bf-label bf-label--primary"><Trans>Key</Trans></label>
      <ListDropdown
        className="custom-field-dropdown keys"
        onChange={updateKey}
        options={formatKeyOptions()}
        overflowParentRef={advancedFiltersContainerRef}
        placeholder={t`Select Key`}
        searchable
        value={selectedKey}
      />
      {selectedKey && (
        <CustomFieldValueCombobox
          customFieldKeyKey={selectedKey.value}
          resetKey={() => { setSelectedKey(null); }}
          selectedUgtLocale={selectedUGTLocale}
          updateCustomFieldValues={updateCustomFieldValues}
        />
      )}
    </>
  );
};

CustomFieldsSelector.propTypes = {
  advancedFiltersContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  customFieldKeys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUGTLocale: PropTypes.string.isRequired,
  updateCustomFields: PropTypes.func.isRequired,
};

export default CustomFieldsSelector;
