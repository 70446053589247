/* eslint-disable react/require-default-props */
import { StandardSwitch } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { TextButton } from '@components/library/button';
import { ListDropdown } from '@components/library/dropdown/index';
import { PrioritizedCustomFieldFilter } from '@components/show_page/sections/advanced_filters/PrioritizedCustomFieldFilter';
import { TaskFilter } from '@components/show_page/sections/advanced_filters/TaskFilter';
import { assetStatusOptions } from '@components/show_page/sections/section_search/helper';
import { SearchFilterOperator } from '@components/show_page/sections/section_search/SearchTypes';

import SectionsContext from '../sectionsContext';

import CommentsFilter from './commentsFilter';
import CustomfieldsFilter from './customfieldsFilter';
import DateFilter from './dateFilter';
import FiletypesFilter from './filetypesFilter';
import OrientationFilter from './orientationFilter';
import SkuFilter from './skuFilter';
import './styles/main.scss';
import TagsFilter from './tagsFilter';

const AdvancedFiltersDrawer = ({
  filters,
  filtersDrawerOpen,
  loading,
  searchableThings,
  searchFilterOperators,
  selectedAssetKeys,
  selectedUGTLocale,
  toggleFiltersDrawer,
  updateFilters,
  updateSearchFilterOperator,
}) => {
  const advancedFiltersContainerRef = useRef(null);

  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

  return (
    <SectionsContext.Consumer>
      {({ editable }) => (filtersDrawerOpen === "advanced-filters" && (
        <>
          <div className="header-filter-section">
            <div className="flex-row">
              <h3 className="filter-title"><Trans>Advanced Filters</Trans></h3>
              <div className="header-buttons">
                <TextButton
                  className="clear-button"
                  onClick={() => updateFilters("reset")}
                >
                  <Trans>Clear</Trans>
                </TextButton>
                <TextButton
                  className="close-button"
                  onClick={() => toggleFiltersDrawer('advanced-filters')}
                >
                  <Trans>Hide Filters</Trans>
                </TextButton>
              </div>
            </div>
          </div>
          <div
            ref={advancedFiltersContainerRef}
            className={`container bf-scroll-element ${selectedAssetKeys.size ? 'select-bar-active' : ''}`}
          >
            <form className="search-filter-form">
              {isWorkspace && (
                <TaskFilter
                  advancedFiltersContainerRef={advancedFiltersContainerRef}
                  filters={filters}
                  updateFilters={updateFilters}
                />
              )}
              {BFG.hasFeature('custom_fields') && (
                <PrioritizedCustomFieldFilter
                  advancedFiltersContainerRef={advancedFiltersContainerRef}
                  filteredCustomFieldsStringified={JSON.stringify(filters.custom_fields)} // stringify so that react can detect updates to prop
                  loading={loading}
                  removeFilters={(key, value) => updateFilters("remove_custom_field", key, value)}
                  searchFilterOperators={searchFilterOperators}
                  selectedUGTLocale={selectedUGTLocale}
                  updateFilters={(key, value) => updateFilters("custom_fields", key, value)}
                  updateSearchFilterOperator={updateSearchFilterOperator}
                />
              )}
              {editable && (
                <div className={`${viewOnlyAssetsEnabled ? 'status-with-view-only' : 'status'} filter-section cx-filters-status`}>
                  <div className={`${viewOnlyAssetsEnabled ? 'title-with-view' : 'title'} flex-row`}>
                    <p className="asset-status-title"><Trans>Asset Status</Trans></p>
                  </div>
                  <div className="status__container">
                    <ListDropdown
                      className={`${viewOnlyAssetsEnabled ? 'status-with-view-only__dropdown' : 'status__dropdown'}`}
                      onChange={(option) => updateFilters('availability', option?.value || null)}
                      options={assetStatusOptions()}
                      overflowParentRef={advancedFiltersContainerRef}
                      placeholder={t`Select status`}
                      value={filters.availability}
                    />
                  </div>
                  { viewOnlyAssetsEnabled && (
                    <div>
                      <StandardSwitch
                        isChecked={filters.view_only}
                        isDisabled={loading}
                        labelPosition='right'
                        onChange={() => updateFilters('view_only', !filters.view_only)}
                      >
                        <Trans>Only show view only assets</Trans>
                      </StandardSwitch>
                    </div>
                  )}
                </div>
              )}
              {BFG.hasFeature('custom_fields') && (
                <CustomfieldsFilter
                  advancedFiltersContainerRef={advancedFiltersContainerRef}
                  customFields={filters.custom_fields}
                  removeFilters={(key, value) => updateFilters("remove_custom_field", key, value)}
                  searchableThings={searchableThings}
                  selectedUGTLocale={selectedUGTLocale}
                  updateFilters={(key, value) => updateFilters("custom_fields", key, value)}
                />
              )}
              <FiletypesFilter
                filetypes={filters.filetypes}
                searchableThings={searchableThings}
                updateFilters={(value) => updateFilters("filetypes", value)}
              />
              <TagsFilter
                searchFilterOperators={searchFilterOperators}
                searchableThings={searchableThings}
                tags={filters.tags}
                updateFilters={(value) => updateFilters("tags", value)}
                updateSearchFilterOperator={updateSearchFilterOperator}
              />
              <DateFilter
                createdDate={filters.created_at}
                updateFilters={(value) => updateFilters("created_at", value)}
              />
              <OrientationFilter
                orientation={filters.orientation}
                updateFilters={(value) => updateFilters("orientation", value)}
              />
              {BFG.context.hasFeature("comments") && editable && (
                <CommentsFilter
                  checked={Boolean(filters.comments)}
                  updateFilters={(value) => updateFilters("comments", value)}
                />
              )}
              {/*
                NOTE: Temporarily removed from prod, but about 10 orgs complained
                Orgs: MOTIS Brands, Moe's Home Collection, Getty, etc
              */}
              <SkuFilter updateFilters={(value) => updateFilters("sku", value)} />
            </form>
          </div>
        </>
      ))}
    </SectionsContext.Consumer>
  );
};

AdvancedFiltersDrawer.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  filtersDrawerOpen: PropTypes.oneOf(['labels', 'advanced-filters', 'none']).isRequired,
  loading: PropTypes.bool.isRequired,
  searchableThings: PropTypes.shape({}).isRequired,
  searchFilterOperators: PropTypes.shape({
    custom_fields: PropTypes.shape({ all: PropTypes.oneOf(Object.values(SearchFilterOperator)) }),
    tags: PropTypes.oneOf(Object.values(SearchFilterOperator)).isRequired,
  }).isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedUGTLocale: PropTypes.string,
  toggleFiltersDrawer: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateSearchFilterOperator: PropTypes.func.isRequired,
};

AdvancedFiltersDrawer.defaultProps = {
  selectedUGTLocale: undefined,
};

export default AdvancedFiltersDrawer;
