/* eslint-disable @typescript-eslint/naming-convention */
import { AlertLooks, StandardAlert, CircleLoader } from '@brandfolder/react';
import { keyPressHelper, localizeFileSize } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, SyntheticEvent } from 'react';

import { FetchState } from '@api/ApiHelper';
import { Attachment, isVideoType, PreviewType } from '@api/v4/attachments/attachmentTypes';
import { IssuesResponse } from '@api/v4/private/assets/issues';
// eslint-disable-next-line max-len
import AttachmentConversionOptionsDropdown from '@components/asset/modal/tabs/asset_details/asset_attachments/AttachmentConversionOptions';
import { getFilenameSansExt, handleThumbnailError } from '@components/asset/modal/tabs/edit/helpers';
import EmbedAttachmentButton from '@components/asset/modal/tabs/shared/EmbedAttachmentButton';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button/index';
import { ViewOnlyIcon } from '@components/view-only/ViewOnlyIcon';

interface AdditionalAttachmentFields {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  attachment_key: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at: string;
  id: number;
  thumbnailed: boolean;
}

export type FullAttachment = Attachment & AdditionalAttachmentFields;

interface AssetAttachmentProps {
  assetKey: string;
  attachment: FullAttachment;
  attachmentConversionOptions: string[];
  colorSpace: string;
  displayAsProcessed: boolean;
  downloadRequestId: number | null;
  isConvertable: boolean;
  isDownloadable: boolean;
  issues: FetchState<IssuesResponse>,
  presetSizes: number[];
  previewType: PreviewType;
  processed: boolean;
  restrictedDimensions: {
    width: { min: number; max: number };
    height: { min: number; max: number };
  };
  selectedAttachmentKey: string;
  setSelectedAttachmentKey: SetStateDispatch<string>;
  viewOnly: boolean | null;
}

const ThumbnailImage = (props: { extension: string; filename: string; url: string }): JSX.Element => (
  <div className="attachment-item--thumbnail">
    <img
      alt={props.filename}
      className="attachment-item--thumbnail__img"
      data-private
      onError={(event: SyntheticEvent<HTMLImageElement, Event>): void => {
        handleThumbnailError(
          event,
          props.extension,
          getFilenameSansExt(props.filename, props.extension)
        );
      }}
      src={props.url}
    />
  </div>
);

const AssetAttachment: FunctionComponent<AssetAttachmentProps> = ({
  assetKey,
  attachment,
  attachmentConversionOptions,
  colorSpace,
  displayAsProcessed,
  downloadRequestId,
  isConvertable,
  isDownloadable,
  issues,
  presetSizes,
  previewType,
  processed,
  restrictedDimensions,
  selectedAttachmentKey,
  setSelectedAttachmentKey,
  viewOnly
}) => {
  const {
    attachment_key: attachmentKey,
    created_at: createdAt,
    extension,
    filename,
    height,
    size,
    thumbnail_url: thumbnailUrl,
    url,
    view_thumbnail_retina: viewThumbnailRetina,
    width,
  } = attachment;

  const attachmentIssue = issues?.response?.data?.find((issue) => (
    issue.attachment_key === attachmentKey
  ));

  const downloadLink = (): string => {
    if (!BFG.resource) return '';
    const digestParam = BFG.manifest ? `&manifest_digest=${BFG.manifest.digest}` : '';
    // eslint-disable-next-line max-len
    return `/${BFG.brandfolder_slug}/attachments/${attachmentKey}?dl=true&resource_key=${BFG.resource.key}&resource_type=${BFG.resource.type}${digestParam}`;
  };

  const trackAttachmentDownload = (): void => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const options = { resource_type: BFG.resource?.type, resource_key: BFG.resource?.key };
    Insight.createEvent('downloaded', assetKey, 'asset', options);
    if (BFG.manifest?.type === 'share_manifest') {
      // ensures that download event is tracked on share manifest
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Insight.createEvent('downloaded', assetKey, 'asset', { resource_type: 'share_manifest', resource_key: BFG.manifest.key }); // eslint-disable-line max-len
    }
  };

  const handleDownload = (): void => {
    trackAttachmentDownload();
    window.location.href = downloadLink();
  };

  const renderEmbedAttachmentButton = (downloadAttachment = false): JSX.Element => {
    const displayUrl = isVideoType(previewType)
      ? url
      : (viewThumbnailRetina || thumbnailUrl);

    return (
      <EmbedAttachmentButton
        attachmentKey={attachmentKey}
        displayUrl={displayUrl}
        downloadAttachment={downloadAttachment}
        name={filename}
        url={url}
      />
    );
  };

  const visitAdminLink = (): void => {
    const adminUrl = new URL(window.origin);
    if (BFG.getty_client === 'true') {
      adminUrl.host = 'dam.gettyimages.com';
    } else if (BFG.environment === 'production') {
      adminUrl.host = 'brandfolder.com';
    } else if (BFG.environment === 'staging' || BFG.environment === 'stage') {
      adminUrl.host = 'bf-stage.com';
    }
    adminUrl.hostname = `admin.${adminUrl.hostname}`;
    window.open(`${adminUrl.origin}/admin/attachments/${attachmentKey}`, '_blank');
  };

  const createdAtString = new Intl.DateTimeFormat(BFG.locales.staticSiteLocale as string).format(new Date(createdAt));
  const isEmbeddedContext = window.location.host.includes('embed');
  const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

  const renderDownloadButtons = (): JSX.Element => (isEmbeddedContext
    ? (
      <div className="asset-attachment__top-container--embed-buttons">
        {renderEmbedAttachmentButton()}
        {renderEmbedAttachmentButton(true)}
      </div>
    ) : (
      <div className="asset-attachment__top-container--buttons">
      {viewOnlyAssetsEnabled && viewOnly && !downloadRequestId
        ? null
        : <>
          {displayAsProcessed && (
            <AttachmentConversionOptionsDropdown
              assetKey={assetKey}
              attachmentKey={attachmentKey}
              colorSpace={colorSpace}
              conversionOptions={attachmentConversionOptions}
              dimensions={{ height, width }}
              extension={extension}
              filename={filename}
              isConvertable={isConvertable}
              presetSizes={presetSizes}
              previewType={previewType}
              processed={processed}
              restrictedDimensions={restrictedDimensions}
              trackAttachmentDownload={trackAttachmentDownload}
            />
          )}
          <PrimaryButton
            className="attachment-download-button"
            data-testid="asset-attachment__download-button"
            icon="bff-download"
            onClick={(): void => {
              if (BFG.downloadAlertEnabled) {
                BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
                  downloadCallback: handleDownload
                });
              } else {
                handleDownload();
              }
            }}
          />
        </>}
      </div>
    )
  );

  return (
    <I18nProviderWrapper>
      <div
        className={classnames(
          'asset-attachment',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'attachment-is-selected': attachmentKey === selectedAttachmentKey }
        )}
        data-testid={`asset-attachment--${attachmentKey}`}
        onClick={(): void => setSelectedAttachmentKey(attachmentKey)}
        onKeyPress={keyPressHelper((): void => setSelectedAttachmentKey(attachmentKey))}
        role="button"
        tabIndex={0}
      >
        <div className="asset-attachment__top-container">
          <ThumbnailImage
            extension={extension}
            filename={filename}
            url={thumbnailUrl || `${BFG.icon_service_url}/assets/types/${extension || 'file'}.svg`}
          />
          {isDownloadable && (extension !== 'bftemplate') && renderDownloadButtons()}
        </div>
        <div className="asset-attachment__bottom-container">
          <div>
            <p className="asset-attachment__filename">
              {filename}
            </p>
          </div>
          <div className='asset-attachment__bottom-container--asset-details'>
            <div className="asset-attachment__metadata-container">
              <span className="file-type-pill" data-testid="asset-attachment__file-type">
                {extension === 'bftemplate' ? <span className="bff-template-editor" /> : extension}
              </span>
              <span className="asset-attachment__metadata--item">
                {localizeFileSize({ locale: BFG.locales.staticSiteLocale, size })}
              </span>
              {width && height && (
                <span className="asset-attachment__metadata--item">
                  <span className="asset-attachment__metadata--dot">&middot;</span>{width} x {height} px
                </span>
              )}
              {colorSpace && (
                <span className="asset-attachment__metadata--item">
                  <span className="asset-attachment__metadata--dot">&middot;</span>{colorSpace.toUpperCase()}
                </span>
              )}
              <span className="asset-attachment__metadata--item">
                <span className="asset-attachment__metadata--dot">&middot;</span>{createdAtString}
              </span>
              {BFG.currentUser.su && (
                <span
                  className="asset-attachment__metadata--item"
                  data-testid="asset-attachment__admin-link"
                  onClick={visitAdminLink}
                  onKeyPress={keyPressHelper(visitAdminLink)}
                  role="button"
                  tabIndex={0}
                >
                  <span className="asset-attachment__metadata--dot">&middot;</span>
                      Admin Portal
                </span>
              )}
            </div>
              {viewOnlyAssetsEnabled && viewOnly && !downloadRequestId && (
                <div className='asset-attachment__view-only'>
                  <ViewOnlyIcon />
                  <span
                    aria-hidden
                    className='asset-attachment__view-only--mobile-text'
                  >
                    <Trans>View only</Trans>
                  </span>
                </div>
              )}
          </div>
          <div className='asset-attachment__bottom-container--issue-details'>
            { issues.loading && <CircleLoader label={t`Loading issues`} /> }
            { attachmentIssue?.message?.length > 0 && (
                <StandardAlert
                    className="asset-attachment__issue-message"
                    data-testid="asset-attachment__issue-message"
                    heading={attachmentIssue.title}
                    look={attachmentIssue.type.toLowerCase() === 'error' ? AlertLooks.Error : AlertLooks.Warning }
                    showHeading
                    showIcon
                >
                  {attachmentIssue.message}
                </StandardAlert>
            )}
          </div>
        </div>
      </div>
    </I18nProviderWrapper>
  );
};

export default AssetAttachment;
