import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import '../styles/subscription_feature.scss';

export const FEATURE_COPY = {
  contactUs: bfTranslate('Get in Touch'),
  emailBody: 'I\'d like to inquire about adding the',
  enabled: bfTranslate('Enabled'),
  pending: bfTranslate('Pending'),
  request: bfTranslate('Request'),
};

const enabledFeatureTag = (
  <>
    <span className='bff-confirmation' />
    <span>{FEATURE_COPY.enabled}</span>
  </>
);

const pendingFeatureTag = (
  <span className='pending'>{FEATURE_COPY.pending}</span>
);

const getInTouchFeatureTag = (
  <a href={`mailto:${BFG.supportEmail}`}>{FEATURE_COPY.contactUs}</a>
);

export interface Feature {
  description: string;
  name: string;
  id: string;
  isEnabled?: boolean | null;
}

export interface SubscriptionFeatureProps {
  feature: Feature;
  organizationName: string;
}

const SubscriptionFeature: FunctionComponent<SubscriptionFeatureProps> = ({ feature, organizationName }) => {
  const statusClasses = classNames({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'subscription__feature-status': true,
    enabled: !!feature.isEnabled,
    disabled: !feature.isEnabled
  });

  const pendingConfiguration =
    (feature.name == 'SAML/SSO' ||
      feature.name == 'Templates' ||
      feature.name == 'Brandguide');

  const requestFeatureTag: JSX.Element =
    // eslint-disable-next-line max-len
    <a href={`mailto:${BFG.supportEmail}?subject=${feature.name} Feature Request&body=${FEATURE_COPY.emailBody} ${feature.name} feature to ${organizationName}.`}>
      {FEATURE_COPY.request}
    </a>;

  const renderFeatureTag = (): JSX.Element => {
    if (feature.isEnabled) return enabledFeatureTag;
    if (pendingConfiguration) return pendingFeatureTag;
    if (feature.name === 'Other') return getInTouchFeatureTag;
    return requestFeatureTag;
  };

  return (
    <div key={feature.name} className='subscription__feature'>
      <div>
        <h3>{feature.name}</h3>
        <p>{feature.description}</p>
      </div>
      <div className={statusClasses}>
        {renderFeatureTag()}
      </div>
    </div>
  );
};

export default SubscriptionFeature;
