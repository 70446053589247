import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import AdvancedDownloadModal from "@components/advanced_download/main";
import { FilestackUploaderTypes } from '@components/common/filestack_uploader/FilestackUploaderEnums';
import { filteredFiles } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';

import { structureFilestackFiles } from '@helpers/uploaders';

import BoulderIFrame from "./boulder_iframe";

class ImageSelector extends React.Component {
  state = { ...this.initialState }

  get initialState() {
    const { client, jobId, frame, imageFrameData } = this.props;
    const defaultImage = imageFrameData[frame.id] && imageFrameData[frame.id].displayUrl
      ? imageFrameData[frame.id].displayUrl
      : `https://ids.w2p-tools.com/d003/getimagethumbnail.php?c=${client}&id=${jobId}&imgout=png&f=${frame.value}&bbox=300`;

    return {
      imageSource: defaultImage,
      imageDetails: {
        mimetype: null,
        size: null,
        url: null,
      },
      filename: null,
      attachmentKey: null,
      renderIFrame: false,
      renderCropper: false,
    }
  }

  updateImageSelector = ({ key, filename, crop }) => {
    if (!key) {
      this.setState({ renderIFrame: false });
      return; // just close iframe
    }
    let readableFilename;
    let encodedFilename;
    try {
      const splitFilename = filename.split(".");
      const extension = splitFilename[splitFilename.length - 1] || "png";
      const preExtension = splitFilename.slice(0, -1) || `printui_image_embed-${key}-${new Date().getTime()}`;
      readableFilename = `${preExtension.join("-").replace(/\s+/g, '-')}.${extension}`;
      encodedFilename = encodeURIComponent(readableFilename);
    } catch (error) {
      readableFilename = "printui_image_embed.png";
      // specific to avoid CDN caching
      encodedFilename = `printui_image_embed-${key}-${new Date().getTime()}.png`;
    }

    const updatedState = {
      attachmentKey: key,
      filename: readableFilename,
      renderIFrame: false
    };

    if (crop) {
      updatedState.renderCropper = true;
    } else {
      const imageParams = ""; // `?height=${this.defineHeight()}&width=${this.defineWidth()}&crop=true`;
      const { embedLink, updateFrameData, frame } = this.props;
      const cdnLink = `${BFG.smartCDNv1}/${embedLink}/at/${key}/${encodedFilename}${imageParams}`;
      updateFrameData({ [frame.id]: { url: cdnLink, name: readableFilename } }, "image");
      updatedState.imageSource = cdnLink;
    }

    this.setState(updatedState);
  }

  onUploadDone = async (files) => {
    const filtered = filteredFiles(files.filesUploaded);
    const structured = structureFilestackFiles(filtered);

    const file = structured[0];
    // url is Filestack signed in app/javascript/components/advanced_download/main.jsx
    const { url, mimetype, filename } = file;
    const name = encodeURI(filename);

    this.setState({
      imageDetails: { url, mimetype, name },
      imageSource: url,
      renderCropper: true
    });
  }

  defineWidth = () => {
    const { points } = this.props.frame;
    const xCoordinates = points.map((point) => parseFloat(point[0]));
    const width = Math.abs(Math.max(...xCoordinates) - Math.min(...xCoordinates));
    return parseInt(width, 10);
  }

  defineHeight = () => {
    const { points } = this.props.frame;
    const yCoordinates = points.map((point) => parseFloat(point[1]));
    const height = Math.abs(Math.max(...yCoordinates) - Math.min(...yCoordinates));
    return parseInt(height, 10);
  }

  defineAspectRatio = () => (this.defineWidth() / this.defineHeight());

  showIframe = () => {
    const { frame, orgSlug, printuiInput } = this.props;
    return (
      <BoulderIFrame
        frame={frame}
        orgSlug={orgSlug}
        printuiInput={printuiInput}
        updateImageSelector={this.updateImageSelector}
      />
    );
  }

  handlePrintuiImageSubmission = (cdnParams, externalUrl = false) => {
    const { embedLink, updateFrameData, frame } = this.props;

    let frameData;
    let imageSource;

    if (externalUrl) { // Filestack upload
      imageSource = cdnParams.url;
      frameData = { url: cdnParams.url, name: cdnParams.name };
    } else { // BF CDN url
      const { attachmentKey, filename } = this.state;
      Object.assign(cdnParams, { quality: 101 }); // over 100 to prevent image optimization in Blitline conversion service
      const urlSearchParams = new URLSearchParams(cdnParams);
      const cdnLink = `${BFG.smartCDNv1}/${embedLink}/at/${attachmentKey}/${filename}?${urlSearchParams.toString()}`;
      imageSource = cdnLink;
      frameData = { url: cdnLink, name: filename };
    }
    updateFrameData({ [frame.id]: frameData }, "image");
    this.setState({ imageSource, renderCropper: false, renderIFrame: false });
  }

  showCropper = () => (
    <AdvancedDownloadModal
      attachmentKey={this.state.attachmentKey}
      handlePrintuiImageSubmission={this.handlePrintuiImageSubmission}
      imageDetails={this.state.imageDetails}
      lockedPreset={{
        aspectRatio: this.defineAspectRatio(),
        name: "Template Cropping",
      }}
      printuiAssetKey={this.props.printuiAssetKey}
      resetCropper={() => this.setState({ attachmentKey: null, imageDetails: {} })}
      resetShowModal={() => this.setState({ renderCropper: false })}
      shouldCloseOnOverlayClick={false}
    />
  );

  renderExistingImage = () => {
    const { validations, frame } = this.props;
    const { imageSource } = this.state;
    return (
      <div className="image-card">
        <div className="image-container">
          <img alt="bf-template" className="existing-image" src={imageSource} />
          <div className="dimensions">{`aspect ratio: ${this.defineAspectRatio().toFixed(2)}`}</div>
        </div>
        {validations.image_replacement !== "deny_replacement" && (
          <div className="image-swap">
            {validations.image_replacement !== "allow_only_upload" && (
              <span className="embed-printui-image">
                <button
                  className="button secondary"
                  onClick={() => this.setState({ renderIFrame: true })}
                  type="button"
                >
                  <Trans>Select from Brandfolder</Trans>
                </button>
              </span>
            )}
            {(validations.image_replacement !== "allow_only_embed") && (
              <div className="upload-printui-image">
                <UploadArea
                  id={`printui-upload-area-${frame.id}`}
                  onUploadDone={this.onUploadDone}
                  pickerOptions={{
                    // accept: ["image/*", "application/postscript", "application/pdf"], // could not get height/width that cropper requires, even with metadata call
                    accept: ["image/*"],
                    maxFiles: 1,
                    transformations: {
                      crop: false,
                      circle: false,
                    }
                  }}
                  printuiAssetKey={this.props.printuiAssetKey}
                  printuiDigestKey={this.props.printuiDigestKey}
                  text={t`Click to browse`}
                  type={FilestackUploaderTypes.Button}
                  uploadType={UploadAreaTypes.GenericUpload}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { renderIFrame, renderCropper } = this.state;
    return (
      <React.Fragment>
        { renderIFrame && this.showIframe()}
        { renderCropper && this.showCropper()}
        { (!renderIFrame && !renderCropper) ? this.renderExistingImage() : null }
      </React.Fragment>
    );
  }
}

ImageSelector.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  updateFrameData: PropTypes.func.isRequired,
  orgSlug: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  validations: PropTypes.shape({
    image_replacement: PropTypes.string,
    embed_settings: PropTypes.string
  }).isRequired,
  embedLink: PropTypes.string.isRequired,
  imageFrameData: PropTypes.shape({}).isRequired,
  printuiAssetKey: PropTypes.string,
  printuiDigestKey: PropTypes.string,
  printuiInput: PropTypes.shape({
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.object,
    validations: PropTypes.object
  }),
};

ImageSelector.defaultProps = {
  printuiAssetKey: null,
  printuiInput: null
};

export default ImageSelector;
