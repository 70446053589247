import { ShimmerLoader, StandardAlert } from '@brandfolder/react';
import React, { useEffect, FunctionComponent, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { PlanGetResponse } from '@api/v4/resources/plan';

import { DialogProvider } from '@components/common/providers/DialogContextProvider';
import {
  GetLimitsUsage,
  GetResourceUsage,
  LimitsApiDataResponse,
  LimitsDataResponseObject,
  PlanUsageInfo,
  ResourceApiDataResponse,
} from '@components/limits/customLimitsTypes';
import UsageInfoCard, {
  Usage,
} from '@components/limits/usage_info_card/UsageInfoCard';

import { ResourceDetails } from './resource-sidebar';
import './styles/plan-limits.scss';

const acceptedResourceTypes = [
  'brandfolder',
  'brandguide',
  'collection',
  'portal',
  'organization',
];

const PENDING_INVITATIONS = {
  singular: bfTranslate('pending invitation'),
  plural: bfTranslate('pending invitations'),
};

const createWarningFlag = (info: PlanUsageInfo): string =>
  info.limit - info.current === info.invites + 1 && bfTranslate('1 left');

const createDangerFlag = (info: PlanUsageInfo): string =>
  (info.current >= info.limit || info.invites >= info.limit - info.current) &&
  bfTranslate('0 left');

const checkPendingInvitationsText = (value: number): string => {
  return value === 1
    ? PENDING_INVITATIONS.singular
    : PENDING_INVITATIONS.plural;
};

const useGetLimitsUsage = (orgKey: string, resourceType = 'brandfolder'): GetLimitsUsage => {
  const { response: adminLimit, fetch: fetchAdminLimits } =
    useFetch<LimitsApiDataResponse>({
      url: `/api/v4/organizations/${orgKey}/limits/${resourceType}/admin?include=resource`,
      fetchOnMount: false,
    });
  const { response: collabLimit, fetch: fetchCollabLimits } =
    useFetch<LimitsApiDataResponse>({
      url: `/api/v4/organizations/${orgKey}/limits/${resourceType}/collaborator?include=resource`,
      fetchOnMount: false,
    });

  return { adminLimit, collabLimit, fetchAdminLimits, fetchCollabLimits };
};

const useGetResourceUsage = (
  orgKey: string,
  resourceType = 'brandfolder'
): GetResourceUsage => {
  const resourceUrl =
    resourceType === 'portal'
      ? `/api/v4/private/organizations/${orgKey}/portals?fields=current_usage`
      : `/api/v4/organizations/${orgKey}/${resourceType}s?fields=current_usage`;

  const { response: resourceUsage, fetch: fetchResourceUsage } = useFetch<ResourceApiDataResponse>({
    url: resourceUrl,
    fetchOnMount: false,
  });

  return { resourceUsage, fetchResourceUsage };
};

interface PlanLimitsProps {
  orgKey: string;
  organizationOwner: boolean;
  reload: boolean;
  resource: ResourceDetails;
  setReload: SetStateDispatch<boolean>;
}

export const PlanLimits: FunctionComponent<PlanLimitsProps> = ({
  orgKey = BFG.organization_key,
  organizationOwner,
  reload,
  resource,
  setReload,
}) => {
  const [selectedResourceAdmin, setSelectedResourceAdmin] = useState<Usage>();
  const [selectedResourceCollab, setSelectedResourceCollab] = useState<Usage>();

  const { adminLimit, collabLimit, fetchAdminLimits, fetchCollabLimits } = useGetLimitsUsage(orgKey, resource.type);
  const { resourceUsage, fetchResourceUsage } = useGetResourceUsage(orgKey, resource.type);

  const { response, fetch, error, loading } = useFetch<PlanGetResponse>({
    url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/plan`,
    fields: [
      'admin_invites',
      'admins',
      'collaborator_invites',
      'collaborators',
    ],
  });

  const limitsFeatureOn =
    BFG.hasFeature('resource_level_limit') && organizationOwner;

  const planInfoAdmin = limitsFeatureOn
    ? {
      current: selectedResourceAdmin?.current,
      limit: Number(selectedResourceAdmin?.limit),
    }
    : {
      current: response?.data.attributes.admins,
      limit: response?.data.attributes.limits.admins,
    };

  const planInfoCollaborators = limitsFeatureOn
    ? {
      current: selectedResourceCollab?.current,
      limit: Number(selectedResourceCollab?.limit),
    }
    : {
      current: response?.data.attributes.collaborators,
      limit: response?.data.attributes.limits.collaborators,
    };

  const admins: PlanUsageInfo = {
    current: planInfoAdmin.current || 0,
    invites: response?.data.attributes.admin_invites,
    limit: planInfoAdmin.limit || 0,
  };

  const collaborators: PlanUsageInfo = {
    current: planInfoCollaborators.current || 0,
    invites: response?.data.attributes.collaborator_invites,
    limit: planInfoCollaborators.limit || 0,
  };

  const loadLimitsContent = (): void => {
    fetchAdminLimits();
    fetchCollabLimits();
    fetchResourceUsage();
  };

  useEffect(() => {
    if (limitsFeatureOn) {
      loadLimitsContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitsFeatureOn, orgKey, resource.type]);

  useEffect(() => {
    if (reload) {
      setReload(false);
      fetch();
    }
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resource.type !== 'collection' && limitsFeatureOn) {
      const buildResourceUsage = (
        limitType: 'admin' | 'collaborator',
        limitData: LimitsDataResponseObject[] = [],
      ): Usage => ({
        current: resourceUsage?.data.find((res) => res.id === resource.key)
          ?.attributes.current_usage[limitType],
        limit:
          limitData?.find(
            (bf) => resource.key === bf.relationships.resource.data.id
          )?.attributes.value || 0,
      });

      const adminResource = buildResourceUsage('admin', adminLimit?.data);
      const collabResource = buildResourceUsage('collaborator', collabLimit?.data);

      setSelectedResourceAdmin({
        current: adminResource.current,
        limit: adminResource.limit,
      });
      setSelectedResourceCollab({
        current: collabResource.current,
        limit: collabResource.limit,
      });
    }
  }, [resource, adminLimit?.data, collabLimit?.data, resourceUsage?.data, limitsFeatureOn]);

  let planLimitsBody;

  if (error) {
    planLimitsBody = (
      <StandardAlert
        className="plan-limits__warning-message"
        showHeading={false}
      >
        {bfTranslate(
          "We were unable to retrieve information regarding your account's plan limits. Try again."
        )}
      </StandardAlert>
    );
  } else if (loading || response) {
    planLimitsBody = (
      <div className="plan-limits__user-limits-wrapper user-limit">
        {loading ? (
          <ShimmerLoader className="card-shimmer-loader" numLines={4} />
        ) : (
          <UsageInfoCard
            customClass="user-limit-card"
            dangerAlert={createDangerFlag(admins)}
            hasSetLimits={limitsFeatureOn}
            icon="Person"
            isEnabled={true}
            orgKey={BFG.organization_key}
            setLimitsAction={loadLimitsContent}
            subtitle={`${admins.invites} ${checkPendingInvitationsText(
              admins.invites
            )}`}
            title={bfTranslate('Administrators')}
            usage={{
              current: admins.current,
              limit: admins.limit,
            }}
            warningAlert={createWarningFlag(admins)}
          />
        )}
        {loading ? (
          <ShimmerLoader className="card-shimmer-loader" numLines={4} />
        ) : (
          <UsageInfoCard
            customClass="user-limit-card"
            dangerAlert={createDangerFlag(collaborators)}
            hasSetLimits={limitsFeatureOn}
            icon="Person"
            isEnabled={true}
            orgKey={BFG.organization_key}
            setLimitsAction={loadLimitsContent}
            subtitle={`${collaborators.invites} ${checkPendingInvitationsText(
              collaborators.invites
            )}`}
            title={bfTranslate('Collaborators')}
            usage={{
              current: collaborators.current,
              limit: collaborators.limit,
            }}
            warningAlert={createWarningFlag(collaborators)}
          />
        )}
      </div>
    );
  }

  return (
    <DialogProvider>
      {acceptedResourceTypes.includes(resource.type) &&
        <div className="plan-limits">
          {planLimitsBody}
        </div>}
    </DialogProvider>
  );
};
